import { useModelId } from './use-route-query';
import { useQuery } from 'react-query';
import { getPremiumRange } from '../utils/api';
import { GetPremiumRangeResponse } from '../types/getPremiumRange';

export default () => {
  const modelId = useModelId() || '';

  return useQuery<GetPremiumRangeResponse>(
    ['PremiumRange', modelId],
    async () => {
      const { data } = await getPremiumRange(modelId);
      return data;
    },
    {
      enabled: Boolean(modelId),
      retry: false,
    }
  );
};
