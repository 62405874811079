import { useQuery, UseQueryOptions } from 'react-query';
import { getPremiumRangesForCommunity } from '../utils/api';
import { GetPremiumRangesForCommunityResponse } from '../types/getPremiumRangesForCommunity';

const getPremiumRangesForCommunityKey = (parameters: Parameters<typeof getPremiumRangesForCommunity>[0]) => [
  'CommunityPremiumRanges',
  JSON.stringify(parameters),
];

export default (
  parameters: Parameters<typeof getPremiumRangesForCommunity>[0],
  options?:
    | Omit<
        UseQueryOptions<GetPremiumRangesForCommunityResponse, unknown, GetPremiumRangesForCommunityResponse, string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  return useQuery(
    getPremiumRangesForCommunityKey(parameters),
    async () => {
      const { data } = await getPremiumRangesForCommunity(parameters);
      return data;
    },
    {
      retry: false,
      ...options,
    }
  );
};
