import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

const PageContainer = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  ${color};
`;

PageContainer.defaultProps = {
  backgroundColor: 'gray50',
};

export default PageContainer;
