import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { emptyErrorObj } from 'helpers/errors/emptyErrorObj';

import { fetchReferralQuoteAsync } from '../../actions/referral';

import { ReferralQuoteState } from './types';

const quoteInitialState: ReferralQuoteState = { data: undefined };

export const referralQuoteReducer = reducerWithInitialState(quoteInitialState)
  .case(fetchReferralQuoteAsync.started, (state) => ({
    ...state,
    error: emptyErrorObj,
    isFetched: false,
    isFetching: true,
  }))
  .case(fetchReferralQuoteAsync.failed, (state, { error }) => {
    return {
      ...state,
      error,
      isFetching: false,
    };
  })
  .case(fetchReferralQuoteAsync.done, (state: ReferralQuoteState, { result }) => ({
    ...state,
    data: result,
    isFetched: true,
    isFetching: false,
  }));
