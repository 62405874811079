export const understandingCoveragesData = [
  {
    iconName: 'Money' as HippoComponents.IconType,
    slug: 'deductible',
    title: 'Deductible',
    contents: [
      {
        title: '',
        texts:
          'This is the amount of money you’II be required to pay for an eligible claim before your insurance plan starts to pay.',
      },
    ],
  },
  {
    iconName: 'House' as HippoComponents.IconType,
    slug: 'coverage-for-home',
    title: 'Coverage for home',
    contents: [
      {
        title: '',
        texts:
          'This is the insurance that specifically covers your home and any secondary structures on your property. It does not include the coverage for your belongings or personal liability.',
      },
      {
        title: 'HOME REBUILDING COVERAGE',
        texts:
          ' – This is the estimate of how much it would cost to rebuild your home and any secondary structures on your property in the event of complete damage. Typically, your Coverage for Home is determined by calculating your Rebuilding Cost. (Note: Rebuilding cost is often lower than the market value of your home, because it does not include the value of your land)',
      },
      {
        title: 'Separate structures',
        texts: ' – Covers repair or replacement for secondary structures on your property.',
      },
      {
        title: 'Loss of use',
        texts:
          ' – Covers additional living expenses (e.g., food, hotels, storage, etc.) while your home is being repaired.',
      },
    ],
  },
  {
    iconName: 'LaptopAlt' as HippoComponents.IconType,
    slug: 'coverage-for-belongings',
    title: 'Coverage for Belongings',
    contents: [
      {
        title: '',
        texts:
          'This is the insurance that covers some of your personal property both inside and, in certain instances, outside of your home. This covers your personal property at fair market value, meaning what it’s worth at the time of the loss (not when you bought it). If Replacement Cost Coverage is included, this covers your personal property for what it would cost to purchase similar new items at current retail prices.',
      },
    ],
  },
  {
    iconName: 'Liability' as HippoComponents.IconType,
    slug: 'coverage-for-liability',
    title: 'Coverage for Liability',
    contents: [
      {
        title: '',
        texts:
          'This is the insurance that covers certain instances of bodily injury, personal injury (emotional or other non-physical damages), medical expenses, and property damage sustained by others for which you or your family members are legally responsible.',
      },
    ],
  },
  {
    iconName: 'EquipmentBreakdown' as HippoComponents.IconType,
    slug: 'additional-coverages',
    title: 'ADDITIONAL COVERAGES, LIMITATIONS, AND EXCLUSIONS (not always included)',
    contents: [
      {
        title: 'Equipment Breakdown',
        texts:
          ' – Covers the cost of repair or replacement for pretty much everything in your home that uses electric power, in the event of electrical or mechanical breakdown. This includes your washer & dryer, HVAC equipment, kitchen appliances, water heaters, and even personal electronics like computers & TVs.',
      },
      {
        title: 'Water backup',
        texts: ' – Covers damages caused by the backup and overflow of water from drains or sewers.',
      },
      {
        title: 'Service line',
        texts:
          ' – Help in the case of a problem with the service lines (electric, sewer, etc.) that run from your home to the street. This could include an accident or wear and tear, and would cover excavation, repair, and damaged property costs.',
      },
      {
        title: 'Foundation and water coverage',
        texts: ' – Covers you in case of damage to your home or foundation caused by seepage or leakage.',
      },
    ],
  },
];
