import React from 'react';
import { Col, Row, TextArea } from '@hippo/components';

import { Label, MediumText } from './style';

import FormikField from '../../components/Form/FormikField';
import Box from '../../components/box/Box';

export const AdditionalComments = () => (
  <Row>
    <Col size={[1, 1, 8 / 12]} offset={[0, 0, 2 / 12]}>
      <Box borderRadius={6} paddingBottom={4}>
        <Label marginBottom={0}>Additional Comments (optional)</Label>
        <MediumText marginY={3}>
          Please let us know any additional information on the home, i.e. bonus room, additional square footage,
          kitchen/bathroom upgrades.
        </MediumText>
        <FormikField placeholder='Add comments here' name='additional_comments' component={TextArea} />
      </Box>
    </Col>
  </Row>
);
