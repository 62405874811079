import { Footer } from 'components/footer/Footer';
import React from 'react';
import { Col, Grid, Row } from '@hippo/components';
import { ThemeProviderOverride } from 'components/ThemeProviderOverride';
import { License } from 'reducers/white-label/types';
import { OrgNameMapping } from 'helpers/enums/organization';
import { Logo } from './Logo';
import { WhiteLabelResponse } from '../types/whiteLabel';
import { Flex } from './Flex';

type LennarSearchWrapperProps = {
  builder?: WhiteLabelResponse['builder'];
  licenses: License[];
  pages?: WhiteLabelResponse['pages'];
  contactInfo?: JSX.Element;
  landingPagePath?: string;
};

export const LennarSearchWrapper: React.FC<LennarSearchWrapperProps> = ({
  children,
  builder,
  licenses,
  pages,
  contactInfo,
  landingPagePath,
}) => (
  <ThemeProviderOverride orgName={OrgNameMapping.Lennar}>
    <Flex flexDirection='column' minHeight='100vh' backgroundColor='primary50' justifyContent='space-between'>
      <Grid style={{ width: '100%' }}>
        <Row>
          <Col size={6 / 12} flexDirection='row' justifyContent='flex-start' height='100px' alignItems='center'>
            <Flex height='48px' justifyContent='center'>
              <Logo organizationName={OrgNameMapping.Lennar} logoUrl={landingPagePath} />
            </Flex>
          </Col>

          {contactInfo ? (
            <Col
              offset={[0, 0, 3 / 12]}
              size={[6 / 12, 6 / 12, 3 / 12]}
              flexDirection='row'
              justifyContent='flex-end'
              alignItems='center'
            >
              {contactInfo}
            </Col>
          ) : null}
        </Row>

        {children}
      </Grid>

      <Footer links={pages?.footer} builder={builder} licenses={licenses} />
    </Flex>
  </ThemeProviderOverride>
);
