export type RowProps = {
  left: {
    iconName: HippoComponents.IconType;
    titles: Array<string>;
    price?: string;
    priceType: DeductibleTypes;
    name?: string;
  };
  right: { name: string; value: Array<BasicNameValueObj> };
};

export interface CoverageRowsDataProps {
  value: RowProps;
  index: number;
  isAddressRow?: boolean;
}
export type BasicNameValueObj = {
  name: string;
  value: string | null;
};

export interface CoverageColLeftProps {
  coverageRowsData: Array<RowProps> | null;
  quoteLink?: string;
}

export enum DeductibleTypes {
  DOLLARS = 'DOLLARS',
  PERCENTAGE = 'PERCENTAGE',
  GENERAL = 'GENERAL',
}
