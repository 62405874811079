import { EventCategory, IHit, HitType } from '@flagship.io/react-sdk';

export const landingPageCTAClickHit: IHit = {
  category: EventCategory.ACTION_TRACKING,
  action: 'Landing Page CTA Click',
  type: HitType.EVENT,
};

export const checkoutPageCTAClickHi: IHit = {
  category: EventCategory.ACTION_TRACKING,
  action: 'Checkout Page CTA Click',
  type: HitType.EVENT,
};

export const LANDING_PAGE_CTA_TEXT = 'landingPageCTAText';
export const CHECKOUT_PAGE_CTA_TEXT = 'checkoutPageCTAText';
export const CHECKOUT_PAGE_TITLE_TEXT = 'checkoutPageTitleText';
