import SmartHomeConsentModal from './SmartHomeConsent';
import SecondHomeModal from './SecondHome';
import SwimmingPoolModal from './SwimmingPool';
import DogsModal from './dogs/Dogs';
import ExoticPetsModal from './exotic-pets/ExoticPets';
import CoverageModal from './coverages/Coverages';
import RedirectModal from './Redirect';

export const DeprecatedAllModals = {
  SmartHomeConsentModal,
  SecondHomeModal,
  SwimmingPoolModal,
  DogsModal,
  ExoticPetsModal,
  CoverageModal,
  RedirectModal,
};
