import { useFlagship, useFsFlag } from '@flagship.io/react-sdk';
import { useEffect, useRef } from 'react';
import { ExperimentFlags } from '../experiments/experiments.types';

export const landingPageCTAClickHit = {
  data: {
    action: 'Landing Page CTA Click',
    category: 'Action Tracking' as const,
  },
  type: 'Event' as const,
};

export const checkoutPageCTAClickHit = {
  data: {
    action: 'Checkout Page CTA Click',
    category: 'Action Tracking' as const,
  },
  type: 'Event' as const,
};

export const useFlagshipFlag = <ExperimentFlag extends ExperimentFlags>({
  key,
  defaultValue,
  visitorExposed,
}: ExperimentFlag & { visitorExposed: boolean }): ExperimentFlag['defaultValue'] => {
  const heapEventSentRef = useRef(false);

  const {
    status: { isVisitorDefined },
  } = useFlagship();

  // For "No flags" variation, this hook can return null instead of undefined.
  const fsFlag = useFsFlag<ExperimentFlag['defaultValue']>(key, defaultValue);

  useEffect(() => {
    /**
     * The "Experiment Seen" event should be tracked only if the current Flagship visitor is defined
     * and when the experiment isn't forced through the flag present in search query
     * and when the experiment is exposed to the visitor.
     */
    if (!isVisitorDefined || !visitorExposed) {
      return;
    }

    const { campaignId, variationGroupId, variationId } = fsFlag.metadata;
    const experimentValue = fsFlag.getValue(true);

    if (variationId && !heapEventSentRef.current) {
      window.heap?.track?.('Flagship Experiment Seen', {
        campaignId,
        modificationKey: key,
        modificationValue: experimentValue,
        variationGroupId,
        variationId,
      });
      heapEventSentRef.current = true;
    }
  }, [fsFlag, key, isVisitorDefined, visitorExposed]);

  return fsFlag.getValue(visitorExposed);
};
