import React from 'react';

import { Diamond } from '../../../components/icons/Diamond';
import { Home } from '../../../components/icons/Home';
import { SaveMoney } from '../../../components/icons/SaveMoney';
import { Watch } from '../../../components/icons/Watch';

const cardsData = {
  watch: {
    icon: <Watch />,
    title: 'Simple Process',
    content: `Home insurance applications can be over 70 questions. We pre-filled the answers so
there are no additional steps or credit checks required, and prepare a policy for you that is
ready to be reviewed and signed in minutes, not days.`,
  },
  saveMoney: {
    icon: <SaveMoney />,
    title: 'Competitive Pricing',
    content: `Agency offers policies specifically designed for new home construction,
something hard to find with other insurers. This could save you an average of 25% or more on your
home insurance premium.`,
  },
  home: {
    icon: <Home />,
    title: 'Tailored Coverage',
    content: `We built the home and have all the details needed to create a customized insurance policy with
just the right coverage for your new home.`,
  },
  diamond: {
    icon: <Diamond />,
    title: 'Secure Policies',
    content: `We work with top-rated insurance partners who hold the risk on policies. All are rated
“A- Excellent” or better by A.M. Best, the gold standard of our industry, which means your policy is secure.`,
  },
};

export default cardsData;
