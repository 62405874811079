export function toNumberOrNull(value: string | number | null = '') {
  if (value === null) {
    return value;
  }

  const parsedValue = Number.parseFloat(`${value}`);

  if (isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return null;
  }
  const cleaned = phoneNumber.replace(/\D/g, '').trim();
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]} - ${match[3]}`;
  }
  return null;
};
