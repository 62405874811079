import React from 'react';
import { Typography } from '@hippo/components';
import styled from 'styled-components';

import { CoveragePriceTableTr } from '../styles';
import PriceBox from '../../../components/PriceBox/PriceBox';
import { BasicNameValueObj } from '../type';

const StyledTbody = styled.tbody`
  width: 100%;
  margin-bottom: 0;
  display: table;
  padding: 10px 0;
`;

const CoveragePriceTableBox: React.FC<{ valueObjArray: Array<BasicNameValueObj> }> = ({ valueObjArray }) => {
  return (
    <StyledTbody>
      {valueObjArray.map((v, index) =>
        v.value ? (
          <tr key={index} data-testid={`coveragePriceTableBox-${v.name}`}>
            <CoveragePriceTableTr>
              <Typography type={'body5'} color={'gray600'}>
                {v.name}
              </Typography>
            </CoveragePriceTableTr>
            <CoveragePriceTableTr>
              {
                /* coercing to number for Typescript */
                !isNaN(+v.value) && <PriceBox type={'body5'} price={v.value} textAlign={'right'} color={'gray600'} />
              }
              {isNaN(+v.value) && (
                <Typography type={'body5'} color={'gray600'} textAlign={'right'}>
                  {v.value}
                </Typography>
              )}
            </CoveragePriceTableTr>
          </tr>
        ) : null
      )}
    </StyledTbody>
  );
};

export default CoveragePriceTableBox;
