export const FLOWS = Object.freeze({
  consent: '/consent/:quoteId',
  confirm: '/confirm/:quoteId',
  static: '/static/:quoteId',
});

export const PAGES = Object.freeze({
  checkout: 'checkout',
  coverages: 'coverages',
  eligibility: 'eligibility',
  success: 'success',
  welcome: 'welcome',
  quote: 'quote',
});
