import { Col, Grid, Row } from '@hippo/components';
import Box from 'components/box/Box';
import React, { FC } from 'react';
import styled from 'styled-components';

import DeprecatedLogo, { defaultLogos } from '../deprecatedLogo/DeprecatedLogo';
import PageContainer from '../PageContainer/PageContainer';
import { FooterContainer } from '../footer/Footer';
import { lennarColorConfig, ThemeProviderOverride } from '../ThemeProviderOverride';
import { HeaderContactInfoContainer } from '../HeaderContactInfo/HeaderContactInfo';
import { isLennar } from '../../helpers/organization-mapping';

import Disclaimer from './components/disclaimer';
import Subtitle from './components/subtitle';
import Title from './components/title';
import { OrgNameMapping } from '../../helpers/enums/organization';

export interface PageProps {
  title: string;
  subtitle?: string;
  disclaimer?: string;
  titleColor?: HippoComponents.Color;
  children?: React.ReactNode;
  landingPagePath?: string;
  logos?: {
    logo_default: string;
    logo_mobile: string;
    logo_white: string;
  };
  orgName: OrgNameMapping | undefined;
}

export const ButtonCol = styled(Col)`
  margin: 80px 0 100px;
`;

const Page: FC<PageProps> = (props) => {
  const { disclaimer, title, subtitle, titleColor = 'gray900', landingPagePath, logos, orgName } = props;

  return (
    <ThemeProviderOverride orgName={orgName}>
      <PageContainer backgroundColor={isLennar(orgName) ? lennarColorConfig.primary50 : lennarColorConfig.gray50}>
        <Grid>
          <Row center={true}>
            <Col size={[1, 7 / 12, 7 / 12]}>
              <Box>
                <DeprecatedLogo logoUrl={landingPagePath} logos={logos || defaultLogos} />
              </Box>
            </Col>
            <Col paddingBottom={[5, 5, 0]} size={[1, 4 / 12, 4 / 12]}>
              <HeaderContactInfoContainer />
            </Col>
          </Row>
          <Row>
            <Col size={1} className={'__no_print'}>
              <Title color={titleColor}>{title}</Title>
            </Col>
            <Col size={1} className={'__no_print'}>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Col>
          </Row>
          <Row>
            <Col>
              <>{props.children}</>
            </Col>
          </Row>
          <Row>
            <Col size={[1, 10 / 12, 10 / 12]} offset={[0, 1 / 12, 1 / 12]}>
              <Disclaimer>{disclaimer}</Disclaimer>
            </Col>
          </Row>
        </Grid>
        <FooterContainer />
      </PageContainer>
    </ThemeProviderOverride>
  );
};

export default Page;
