import React from 'react';
import useQuoteId from '../../helpers/use-quote-id';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorObjProps } from '../../helpers/errors/errorTypes';
import { event } from 'react-fullstory';
import { ExoticPet, Underwriting } from '../../reducers/consent/types';
import { ReduxDispatch } from 'store';
import { PageType } from '../../helpers/enums/pageType';
import { useWhiteLabel } from '../../helpers/use-white-label';

import Page, { ButtonCol } from '../../components/page/Page';
import { State } from '../../reducers/createRootReducer';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';

import { eligibilityData } from './eligibilityData';
import { EligibilityRow } from './eligibilityRow';
import { saveUnderwriting } from '../../actions/consent';
import { Col, Row, Typography } from '@hippo/components';
import Button from '../../components/button/Button';
import { eligibilityId } from './eligibilityId';

const Eligibility: React.FC<{ pageType: PageType }> = ({ pageType }) => {
  const { title, subtitle, disclaimer, fields } = eligibilityData;

  const dispatch = useDispatch<ReduxDispatch>();
  const quoteId = useQuoteId();
  const { underwriting, orgName } = useSelector((state: State) => ({
    underwriting: state.consent.data?.underwriting,
    orgName: state.consent.data?.organization_name,
  }));

  const hasAllFalsy = (arr: Array<ExoticPet>) => {
    return arr.filter((o) =>
      Object.keys(o).every((k) => o[k as keyof ExoticPet] === false || o[k as keyof ExoticPet] === '')
    );
  };

  const isChecked = (fieldName: keyof Underwriting) => {
    if (!underwriting) {
      return false;
    }

    if (fieldName === 'dogs') {
      return underwriting.dogs && underwriting.dogs.length > 0;
    } else if (fieldName === 'exotic_pets') {
      return (
        underwriting.exotic_pets &&
        underwriting.exotic_pets.length > 0 &&
        hasAllFalsy(underwriting.exotic_pets).length <= 0
      );
    } else if (fieldName === 'non_owner_occupied') {
      return underwriting.rental_or_vacant || underwriting.secondary_residence || underwriting.non_owner_occupied;
    } else if (fieldName === 'swimming_pool') {
      return underwriting.pool_fence || underwriting.diving_board;
    } else {
      return underwriting[fieldName];
    }
  };

  const handleSave = async () => {
    event('Consents Continue');

    if (quoteId && underwriting) {
      await dispatch(saveUnderwriting({ quoteId, underwriting }));
    }
  };

  const { errors } = useSelector<State, { errors: ErrorObjProps }>((state) => ({
    errors: state.consent.error,
  }));

  const { pageData } = useWhiteLabel(orgName);
  const landingPageUrl = generateLandingPageUrl(pageType);

  return (
    <Page
      title={title}
      subtitle={subtitle}
      disclaimer={disclaimer}
      landingPagePath={landingPageUrl}
      logos={pageData?.general}
      orgName={orgName}
    >
      {fields.map((field) => (
        <EligibilityRow
          fieldName={field.fieldName}
          iconName={field.iconName}
          title={field.title}
          contents={field.contents}
          modalName={field.modalName}
          checked={!!isChecked(field.fieldName as keyof Underwriting)}
          key={field.fieldName}
          rowError={errors.eligibilityError}
          orgName={orgName}
        />
      ))}
      {errors.generalError ? (
        <Row>
          <Col marginY={3}>
            <Typography textAlign='center' color='error' type='body6'>
              The form contains errors. Please fix them before submitting.
            </Typography>
          </Col>
        </Row>
      ) : null}
      <Row>
        <ButtonCol size={[1, 6 / 12, 5 / 12]} offset={[0, 3 / 12, 3.5 / 12]}>
          <Button data-testid={eligibilityId.continueButton} size='large' styleType='primary' onClick={handleSave}>
            Continue
          </Button>
        </ButtonCol>
      </Row>
    </Page>
  );
};

export default Eligibility;
