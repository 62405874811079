import { getAssignedQuote } from '../utils/api';
import { useQuery } from 'react-query';
import { useModelId } from 'helpers/use-route-query';
import { GetAssignedQuoteResponse } from '../types/getAssignedQuote';
import { AxiosError } from 'axios';

export default () => {
  const modelId = useModelId() || '';

  return useQuery<GetAssignedQuoteResponse, AxiosError<unknown, unknown>>(
    ['AssignedQuote', modelId],
    async () => {
      const { data } = await getAssignedQuote(modelId);
      return data;
    },
    {
      enabled: Boolean(modelId),
      // react-query has invalid types, it accepts number as a number of retries
      retry: (failureCount, { response }): number | boolean | any => {
        const status = response?.status;
        if (status === undefined) return false;

        return failureCount < 3 && status >= 500 ? failureCount + 1 : false;
      },
    }
  );
};
