import React, { ComponentType } from 'react';
import { Col, Row, Typography } from '@hippo/components';

import { SummaryFooter, SummaryWrapper } from './style';

import { formatCurrency, squareFootageFormatter } from '../../helpers/formatters';
import useCarriers from '../../helpers/useCarriers';
import Box from '../../components/box/Box';
import styled from 'styled-components';
import { checkoutId } from './checkoutId';
import { SpaceProps } from 'styled-system';
import useGetConsent from '../../helpers/useGetConsent';

const NoWrapSpaceTypography = styled<ComponentType<HippoComponents.TypographyProps & SpaceProps>>(Typography)`
  white-space: nowrap;
`;

export const Summary: React.FC = () => {
  const consent = useGetConsent();
  const carriers = useCarriers();

  if (!consent.data) return null;

  const { quote, model } = consent.data;

  const insurer = carriers.data?.find(({ name }) => name === quote?.carrier);
  const premium = quote ? quote.premium : 0;
  return (
    <SummaryWrapper>
      <Box paddingX='24px' paddingTop='32px'>
        <Typography type='heading5' color='primary500'>
          Summary
        </Typography>
        <Row paddingTop='28px'>
          <Col size={5 / 12}>
            <Typography type='body4' color='gray600'>
              Insurer
            </Typography>
          </Col>
          <Col size={7 / 12}>
            <Typography type='body4' data-testid={checkoutId.insurerName}>
              {insurer?.display_name || '-'}
            </Typography>
          </Col>
        </Row>
        <Row paddingTop='16px'>
          <Col size={5 / 12}>
            <Typography type='body4' color='gray600'>
              Model
            </Typography>
          </Col>
          <Col size={7 / 12}>
            <Typography type='body4' data-testid={checkoutId.modelName}>
              {model.name || '-'}
            </Typography>
          </Col>
        </Row>
        <Row paddingTop='16px'>
          <Col size={5 / 12}>
            <Typography type='body4' color='gray600'>
              Size
            </Typography>
          </Col>
          <Col size={7 / 12}>
            <Typography type='body4' data-testid={checkoutId.modelSqft}>
              {model.sqft ? squareFootageFormatter(model.sqft) : '-'}
            </Typography>
          </Col>
        </Row>
        <Row paddingTop='16px'>
          <Col size={5 / 12}>
            <Typography type='body4' color='gray600'>
              Coverage A
            </Typography>
          </Col>
          <Col size={7 / 12}>
            <Typography type='body4' data-testid={checkoutId.coverageA}>
              {quote.a ? formatCurrency(quote.a) : '-'}
            </Typography>
          </Col>
        </Row>
      </Box>

      <SummaryFooter>
        <Row>
          <Col size={1 / 3} justifyContent='center'>
            <NoWrapSpaceTypography color='white' type='heading6'>
              Quote
            </NoWrapSpaceTypography>
            <Typography color='white' type='body5'>
              &nbsp;
            </Typography>
          </Col>
          <Col size={2 / 3}>
            <>
              {premium && (
                <>
                  <NoWrapSpaceTypography
                    color='white'
                    type='heading4'
                    textAlign='right'
                    data-testid={checkoutId.premiumMonth}
                  >
                    ${formatCurrency((premium / 12).toFixed(0), '')}/mo
                  </NoWrapSpaceTypography>
                  <NoWrapSpaceTypography
                    color='white'
                    type='body5'
                    textAlign='right'
                    data-testid={checkoutId.premiumYear}
                  >
                    ${formatCurrency(premium, '')}/yr
                  </NoWrapSpaceTypography>
                </>
              )}
            </>
          </Col>
        </Row>
      </SummaryFooter>
    </SummaryWrapper>
  );
};
