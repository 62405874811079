import { FlagshipProvider, LogLevel } from '@flagship.io/react-sdk';
import { PropsWithChildren } from 'react';
import { useModelId } from '../../helpers/use-route-query';
import { useRouteMatch } from 'react-router-dom';

export const FlagshipWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const modelId = useModelId();

  const routeMatch = useRouteMatch<{ quoteId: string }>('/consent/:quoteId/*');

  const envId = window?.appConfig?.FLAGSHIP_ENV_ID ?? '';
  const apiKey = window?.appConfig?.FLAGSHIP_API_KEY ?? '';

  const visitorId = routeMatch?.params?.quoteId || modelId || undefined;

  if (!envId || !apiKey || !visitorId) return <>{children}</>;

  return (
    <FlagshipProvider
      envId={envId}
      apiKey={apiKey}
      visitorData={{
        isAuthenticated: false,
        id: visitorId,
      }}
      logLevel={LogLevel.ERROR}
      fetchNow={true}
      data-testid='flagship-provider'
    >
      {children}
    </FlagshipProvider>
  );
};
