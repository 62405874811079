import { Col, Grid, Row, TextInput, Typography } from '@hippo/components';
import { string as yupString } from 'yup';
import { useCallback, useState } from 'react';
import { AsyncAwareButton } from 'components/AsyncAwareButton';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { PageType } from '../../helpers/enums/pageType';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { OrgNameMapping } from '../../helpers/enums/organization';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import useCommunities from '../../helpers/useCommunities';
import { ITEMS_PER_ROW } from './GetQuote';
import { useHistory } from 'react-router';
import { getQuotePath } from '../../helpers/paths';

const zipCodeValidation = yupString().min(5).max(5).required();

export const ZipCode = () => {
  const history = useHistory();
  const [zipCode, setZipCode] = useState('');
  const [zipCodeError, setZipCodeError] = useState(false);

  const communities = useCommunities(
    {
      zip: zipCode ?? '',
      limit: ITEMS_PER_ROW,
      offset: 0,
    },
    {
      enabled: false,
    }
  );

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: [],
  });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const isZipCodeValid = await zipCodeValidation.isValid(zipCode);

      if (!isZipCodeValid) {
        return setZipCodeError(true);
      }

      await communities.refetch().then((response) => {
        if (response.data && response.data.count > 0) {
          history.push(getQuotePath({ zipCode }));
        }
      });
    },
    [communities, history, zipCode]
  );

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={whiteLabel.data?.licenses ?? []}
      landingPagePath={generateLandingPageUrl(PageType.Referral)}
      pages={whiteLabel.data?.pages}
    >
      <Row>
        <Col mt='40px'>
          <Typography type='heading4' color='black' textAlign='center'>
            Let’s find your community and model
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col mt='3' mb='40px'>
          {communities.error ? (
            <Typography key='error' type='body4' color='error' textAlign='center'>
              Try revising your search criteria
            </Typography>
          ) : (
            <Typography key='text' type='body4' color='gray600' textAlign='center'>
              Enter the zip code of your model
            </Typography>
          )}
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Grid style={{ width: '100%' }} flex={1}>
          <Row>
            <Col offset={[0, 4 / 12, 4 / 12]} size={[1, 4 / 12, 4 / 12]}>
              <TextInput
                error={zipCodeError ? 'Please provide proper zip code' : undefined}
                value={zipCode}
                onChange={(e) => {
                  setZipCodeError(false);
                  setZipCode(e.value);
                }}
                name='zipCode'
                label='Zip code'
              />
            </Col>
          </Row>
          {communities.isSuccess && communities.data.count === 0 && (
            <Row center={true}>
              <Typography key='error' type='body4' color='error' textAlign='center'>
                No communities found for provided zip code
              </Typography>
            </Row>
          )}
          <Row>
            <Col mt='80px' size={[1, 4 / 12, 4 / 12]} offset={[0, 4 / 12, 4 / 12]}>
              <AsyncAwareButton showLoader={communities.isLoading} size='large' styleType='primary'>
                Search
              </AsyncAwareButton>
            </Col>
          </Row>
        </Grid>
      </form>
    </LennarSearchWrapper>
  );
};
