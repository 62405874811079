import React, { Fragment } from 'react';
import { Col, Divider, Link, Typography } from '@hippo/components';
import Box from 'components/box/Box';

import { CoverageColLeftProps } from '../type';

import CoverageRow from './CoverageRow';

export const CoverageColLeft: React.FC<CoverageColLeftProps> = (props) => {
  const { coverageRowsData } = props;

  if (!coverageRowsData) {
    return null;
  }

  const children = (
    <Box paddingBottom={[0, 20, 0]}>
      <Col offset={[0, 2 / 12, 1 / 12]} paddingTop={48} paddingBottom={40}>
        <Typography paddingX={[20, 0, 0]} type={'heading6'} color={'primary500'}>
          Please take a minute to review your quote:
        </Typography>
      </Col>
      <Col offset={[0, 1 / 12, 0]} size={[1, 10 / 12, 1]}>
        {coverageRowsData.map((value, index) => (
          <Fragment key={index}>
            <CoverageRow value={value} key={index} index={index} />

            {index !== coverageRowsData.length - 1 && <Divider color='gray200' margin={20} />}

            {index === coverageRowsData.length - 1 && props.quoteLink && (
              <>
                <Divider color='gray200' margin={20} />
                <Box
                  paddingBottom={2}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  className={'__no_print'}
                  textAlign={'center'}
                >
                  <Link type={'allCaps3'} backgroundColorType={'light'} href={props.quoteLink}>
                    DOWNLOAD MY QUOTE
                  </Link>
                </Box>
              </>
            )}
          </Fragment>
        ))}
      </Col>
    </Box>
  );

  return (
    <Col
      size={[1, 1, 8 / 12]}
      last={false}
      backgroundColor={'primary100'}
      paddingBottom={0}
      paddingLeft={['0px', '0px', '0px']}
      paddingRight={['0px', '0px', '0px']}
    >
      {children}
    </Col>
  );
};
