import { useLocation } from 'react-router-dom';
import { RouteParams } from 'types';

import { getQueryVariable } from './use-hmac';

export const useQuoteId = () => {
  const location = useLocation<RouteParams>();
  if (location.search) {
    return getQueryVariable(location.search.substring(1), 'quoteId');
  }
  return null;
};

export const useModelId = () => {
  const location = useLocation<RouteParams>();
  if (location.search) {
    return getQueryVariable(location.search.substring(1), 'modelId');
  }
  return null;
};
