import * as React from 'react';
import { Icon, Typography } from '@hippo/components';
import Switch from 'components/Switch/Switch';

import { SwitchCheck, SwitchIcon, SwitchRow, SwitchText } from './style';

interface SwitchRowProps {
  icon: HippoComponents.IconType;
  title: string;
  description: string;
  name: string;
  checked?: boolean;
  onChange: (onChangeValues: HippoComponents.OnChange<boolean>) => void;
}

export default class SwitchRowComponent extends React.Component<SwitchRowProps> {
  onChange = (onChangeValues: HippoComponents.OnChange<boolean>) => {
    this.props.onChange(onChangeValues);
  };

  render() {
    const { title, name, description, checked } = this.props;
    return (
      <SwitchRow>
        <SwitchIcon>
          <Icon width={40} height={40} icon={this.props.icon} />
        </SwitchIcon>
        <SwitchText>
          <Typography type={['heading8', 'heading7', 'heading7']} paddingBottom={'8px'}>
            {title}
          </Typography>
          <Typography type={['body5']}>{description}</Typography>
        </SwitchText>
        <SwitchCheck>
          <Switch name={name} colorType={'dark'} onChange={this.onChange} size={'small'} checked={!!checked} />
        </SwitchCheck>
      </SwitchRow>
    );
  }
}
