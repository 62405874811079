import { Guuid } from '../types/common';

export const getDivisionListPath = () => '/lennar/search';

export const getCommunityListPath = (divisionId?: string) =>
  divisionId ? `${getDivisionListPath()}/${divisionId}` : `${getDivisionListPath()}/:divisionId?`;

const createStringParamsForQuote = (parameters?: {
  zipCode?: Guuid;
  communityId?: Guuid;
  modelId?: Guuid;
  page?: string;
  street?: string;
  state?: string;
  city?: string;
}) => {
  const params = new URLSearchParams('');

  parameters?.zipCode && params.append('zipCode', parameters.zipCode);
  parameters?.communityId && params.append('communityId', parameters.communityId);
  parameters?.modelId && params.append('modelId', parameters.modelId);
  parameters?.page && params.append('page', parameters.page);
  parameters?.street && params.append('street', parameters.street);
  parameters?.state && params.append('state', parameters.state);
  parameters?.city && params.append('city', parameters.city);

  return params.toString();
};

export const getQuotePath = (params?: Parameters<typeof createStringParamsForQuote>[0]) => {
  const stringParams = createStringParamsForQuote(params);
  return stringParams ? `/get-quote?${stringParams}` : '/get-quote';
};

export const getPropertyInfoPath = (params?: Parameters<typeof createStringParamsForQuote>[0]) => {
  const stringParams = createStringParamsForQuote(params);
  return stringParams ? `/get-quote/property-info?${stringParams}` : '/get-quote/property-info';
};

export const getContactInfoPath = (params?: Parameters<typeof createStringParamsForQuote>[0]) => {
  const stringParams = createStringParamsForQuote(params);
  return stringParams ? `/get-quote/contact-info?${stringParams}` : '/get-quote/contact-info';
};

export const getLandingPath = (communityId?: string, modelId?: string) => {
  const params = new URLSearchParams('');

  communityId && params.append('communityId', communityId);
  modelId && params.append('modelId', modelId);

  const queryParams = params.toString();

  return queryParams ? `/?${queryParams}` : '/';
};

export const getCoverageExplanationUrl = (slug?: string) =>
  slug ? `/coverage-explanation/${slug}` : `/coverage-explanation/:slug`;
