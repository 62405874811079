import { Typography } from '@hippo/components';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ColorProps, SpaceProps } from 'styled-system';

export const SummaryWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SummaryFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.primary500};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px 24px 20px 24px;
  margin-top: 30px;
`;

export const Label: React.FC<Partial<HippoComponents.TypographyProps & SpaceProps>> = ({ children, ...props }) => (
  <Typography type={'allCaps3'} marginTop={['20px', '20px', '20px']} marginBottom={['20px', '30px', '10px']} {...props}>
    {children}
  </Typography>
);

export const LargeText: React.FC<Partial<HippoComponents.TypographyProps & SpaceProps>> = ({ children, ...props }) => (
  <Typography type={['body2', 'body2', 'body1']} paddingBottom={['10px', '10px', '0px']} {...props}>
    {children}
  </Typography>
);

export const MediumText: React.FC<Partial<HippoComponents.TypographyProps & SpaceProps>> = ({ children, ...props }) => (
  <Typography type={['body5', 'body5', 'body4']} color={'gray600'} {...props}>
    {children}
  </Typography>
);

export const SmallText: React.FC<Partial<HippoComponents.TypographyProps & SpaceProps>> = ({ children, ...props }) => (
  <Typography type={['body6', 'body6', 'body5']} color={'gray600'} {...props}>
    {children}
  </Typography>
);

export const Delimiter = styled.div`
  height: 1px;
  background-color: ${({ theme }) => rgba(theme.colors.primary500, 0.5)};
  margin: 40px 0;
`;

export const InlineLink = styled.a<ColorProps>`
  color: ${({ theme, color }) => (color !== null ? color : theme.colors.gray700)};
`;
