export type Guuid = string;

export enum ProductType {
  HO3 = 'ho-3',
  HO5 = 'ho-5',
  HO6 = 'ho-6',
}

export enum CoralogixLogSeverity {
  Debug = 1,
  Verbose = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Critical = 6,
}
