import React from 'react';
import { FieldAttributes, FieldInputProps, useField } from 'formik';

import { CustomFieldProps } from './types';

type FormikFieldProps<Value = any> = FieldAttributes<Value> & {
  children?: (props: CustomFieldProps<Value>) => React.ReactElement;
  label?: string;
};

const FormikField: React.FC<FormikFieldProps> = ({ component: FieldComponent, children, ...props }) => {
  const [field, meta, helpers] = useField({
    ...props,
    value: props.value === null ? undefined : props.value,
    type: 'text',
  });

  const onChange = (change: HippoComponents.OnChange<any>) => {
    // Hippo onChange event doesnt contain target but formik onChange requires native
    field.onChange({ target: { ...props, ...change } });
  };

  const commonProps: FieldInputProps<any> = {
    ...props,
    ...field,
    onChange,
    error: meta.touched ? meta.error : '',
    textSize: props.textSize || 18,
  };

  if (children) {
    return children({ field: commonProps, meta, helpers });
  }

  if (FieldComponent) {
    return <FieldComponent {...commonProps} />;
  }

  return null;
};

export default FormikField;
