import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'reducers/createRootReducer';
import { ConsentData } from 'reducers/consent/types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import useQuoteId from './use-quote-id';
import useHmac from './use-hmac';
import { fetchConsentData } from '../actions/consent';

/**
 * @deprecated use useGetConsent instead
 */
export const useConsentData = () => {
  const quoteId = useQuoteId();
  const hmac = useHmac();

  const { storedData, storedQuoteId, routerPath } = useSelector((state: State) => {
    return {
      storedData: state.consent.data,
      routerPath: window.location.pathname,
      storedQuoteId: state.consent.params ? state.consent.params.quoteId : undefined,
    };
  });

  const [data, setData] = useState<ConsentData | null>(storedData);
  const dispatch = useDispatch<ThunkDispatch<State, void, AnyAction>>();

  useEffect(() => {
    if (storedData && storedQuoteId === quoteId) {
      setData(storedData);
    } else if (quoteId && hmac && routerPath) {
      let flowType = 'consent';
      if (routerPath.indexOf('/static') === 0) {
        flowType = 'static';
      } else if (routerPath.indexOf('/confirm') === 0) {
        flowType = 'confirm';
      }
      dispatch(fetchConsentData(quoteId, flowType, hmac));
    }
  }, [quoteId, storedQuoteId, storedData, dispatch, hmac, routerPath]);

  return data;
};
