import React from 'react';

export const SaveMoney = () => (
  <svg viewBox='0 0 176 142' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g
      id='Website'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g
        id='Home_HippoBuildersOne-01-v2-Desktop-Copy-4'
        transform='translate(-1072 -2012)'
        stroke='currentColor'
        strokeWidth='4'
      >
        <g id='Group-3' transform='translate(1074 2014)'>
          <path
            d='M97.974 34.408c6.4 2.898 11.29 8.46 9.736 13.362-2.326 7.345-16.17 8.793-26.83 1.103-8.479-6.118-7.004-13.134-.547-15.748 4.776-1.934 11.968-1.29 17.64 1.283z'
            id='Stroke-1'
          />
          <path id='Stroke-3' d='M141 33l11-6.863L141.957 22' />
          <path id='Stroke-5' d='M69 79l-11.21 8L49 80.834' />
          <path id='Stroke-7' d='M122 12.692L113.406 9 103 15' />
          <path id='Stroke-9' d='M30 58l-11 5.995L28.955 70' />
          <path id='Stroke-11' d='M0 63.691L56.292 99 172 25.118 112.475 0z' />
          <path id='Stroke-13' d='M56.293 99.7l-46.02-28.95L0 76.583 56.293 112 172 37.892 160.434 33z' />
          <path id='Stroke-15' d='M56.293 124.95L10.94 96.534 0 102.727 56.293 138 172 64.19 159.68 59z' />
          <path id='Stroke-17' d='M56.293 112.95L10.94 84.534 0 90.727 56.293 126 172 52.19 159.68 47z' />
        </g>
      </g>
    </g>
  </svg>
);
