import Page from 'components/page/Page';
import { Col } from '@hippo/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CheckoutLoanInformation, WritableCheckout } from 'reducers/consent/types';
import { toNumberOrNull } from 'utils/data-transform';
import { event } from 'react-fullstory';
import { PageType } from 'helpers/enums/pageType';

import useQuoteId from '../../helpers/use-quote-id';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { Spinner } from '../../components/Spinner';

import { CheckoutForm } from './CheckoutForm';
import { useLocation } from 'react-router';
import { errorHandler } from '../../helpers/errors/errorHandler';
import { push } from 'connected-react-router';
import { PAGES } from '../../globals/routes';
import useGetConsent from '../../helpers/useGetConsent';
import usePostConsent from '../../helpers/usePostConsent';
import { getImages } from '../../helpers/use-white-label';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { useCheckoutPageTitleText } from '../../experiments/hooks/useCheckoutPageTitleText';

export type FormState = {
  customer_approval: boolean;
  organization_name: string;
  additional_comments: string;

  company: CheckoutLoanInformation['company'];
  contact_email: CheckoutLoanInformation['contact_email'];
  contact_name: CheckoutLoanInformation['contact_name'];
  contact_phone: CheckoutLoanInformation['contact_phone'];
  loan_amount: CheckoutLoanInformation['loan_amount'];
  sales_price: CheckoutLoanInformation['sales_price'];
  loan_number: CheckoutLoanInformation['loan_number'];
  has_no_loan: CheckoutLoanInformation['has_no_loan'];

  phone_number: string;
  mailing_address_city: string;
  mailing_address_state: string;
  mailing_address_street: string;
  mailing_address_street2: string;
  mailing_address_zip: string;
};

export const CheckoutPage: React.FC<{ pageType: PageType }> = ({ pageType }) => {
  const consent = useGetConsent();
  const postConsent = usePostConsent();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const quoteId = useQuoteId();
  const images = getImages(consent.data?.organization_name ?? OrgNameMapping.Default);

  const isMiniFlow = pageType === PageType.Confirm;
  const checkoutPageTitleText = useCheckoutPageTitleText(Boolean(isMiniFlow) === false);

  const title = isMiniFlow ? 'Confirm Your Updated Quote' : checkoutPageTitleText;

  const handleSubmit = async (values: FormState) => {
    if (!quoteId) {
      return;
    }
    event('Consented');

    const checkout: WritableCheckout = {
      additional_comments: values.additional_comments || '',
      personal_information: {
        mailing_address_city: values.mailing_address_city,
        mailing_address_state: values.mailing_address_state,
        mailing_address_street2: values.mailing_address_street2,
        mailing_address_street: values.mailing_address_street,
        mailing_address_zip: values.mailing_address_zip,
        phone_number: values.phone_number,
      },
      loan_information: {
        company: values.company || null,
        contact_email: values.contact_email || null,
        contact_name: values.contact_name || null,
        contact_phone: values.contact_phone || null,
        loan_amount: toNumberOrNull(values.loan_amount),
        loan_number: toNumberOrNull(values.loan_number),
        sales_price: toNumberOrNull(values.sales_price),
      },
    };

    const searchParams = new URLSearchParams(search);
    const utmSource = searchParams.get('utm_source');
    const utmCampaign = searchParams.get('utm_campaign');

    try {
      await postConsent.mutateAsync({
        quoteId,
        search,
        orgName: values.organization_name,
        payload: {
          ...checkout,
          customer_approval: values.customer_approval || false,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
        },
      });

      dispatch(push(`/consent/${quoteId}/${PAGES.success}${search}`));
    } catch (error: any) {
      errorHandler({ error, errorType: 'checkoutError', quoteId, dispatch });
    }
  };

  const landingPageUrl = generateLandingPageUrl(pageType);

  return (
    <>
      {!!consent.isLoading ? (
        <Col size={1} alignItems='center'>
          <Spinner />
        </Col>
      ) : (
        <Page
          title={title}
          subtitle={''}
          landingPagePath={landingPageUrl}
          logos={images}
          orgName={consent.data?.organization_name}
        >
          {consent.data && (
            <CheckoutForm
              onSubmit={handleSubmit}
              consentData={consent.data}
              isSaving={postConsent.isLoading}
              isMiniFlow={isMiniFlow}
            />
          )}
        </Page>
      )}
    </>
  );
};
