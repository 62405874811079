import React, { useCallback } from 'react';
import { Col, Divider, Link, Row, Typography } from '@hippo/components';
import Switch from '../../components/Switch/Switch';
import { AppThunkDispatch } from '../../types';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';

import IconWithTexts from '../../components/IconWithTexts/IconWithTexts';
import { PageError } from '../../helpers/errors/errorTypes';
import { isLennar } from '../../helpers/organization-mapping';

import { EligibilityDataRowProps } from './eligibilityData';
import { patchUnderwriting } from '../../actions/consent';
import { openModal } from '../../actions/modals';
import { DeductibleTypes } from '../coverages/type';

type SwitchBoxProps = {
  fieldName: string;
  handleSwitchChange: (change: HippoComponents.OnChange<boolean>) => void;
  handleEditClicked: (e: React.MouseEvent) => void;
  modalName?: string;
  checked?: boolean;
};

const SwitchBox: React.FC<SwitchBoxProps> = (props) => (
  <Col size={[3 / 12, 2 / 12, 2 / 12]}>
    <Switch
      name={props.fieldName}
      size={'small'}
      colorType={'light'}
      onChange={props.handleSwitchChange}
      checked={props.checked || false}
    />
    <Col alignItems={'stretch'}>
      {props.checked && props.modalName !== undefined ? (
        <Link type={'body5'} onClick={props.handleEditClicked}>
          EDIT
        </Link>
      ) : (
        <></>
      )}
    </Col>
  </Col>
);

const handleModalChanges = (
  change: HippoComponents.OnChange<boolean>,
  dispatch: AppThunkDispatch,
  modalName?: EligibilityDataRowProps['modalName']
) => {
  if (change.value) {
    if (modalName) {
      dispatch(openModal({ modalName }));
      if (modalName === 'SwimmingPoolModal') {
        dispatch(patchUnderwriting({ [change.name]: true }));
      }
    } else {
      dispatch(patchUnderwriting({ [change.name]: true }));
    }
  } else {
    switch (change.name) {
      case 'dogs':
      case 'exotic_pets':
        dispatch(patchUnderwriting({ [change.name]: [] }));
        break;

      default:
        dispatch(patchUnderwriting({ [change.name]: false }));
    }
  }
};

export const EligibilityRow: React.FC<
  EligibilityDataRowProps & {
    checked?: boolean;
    rowError: PageError;
    orgName?: string;
  }
> = ({ iconName, title, contents, modalName, fieldName, tooltips, checked, rowError, orgName }) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleSwitchChange = useCallback<(change: HippoComponents.OnChange<boolean>) => void>(
    (change) => {
      handleModalChanges(change, dispatch, modalName);
    },
    [dispatch, modalName]
  );

  const handleEditClicked = useCallback(
    (e: React.MouseEvent) => {
      if (modalName) {
        dispatch(openModal({ modalName }));
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [dispatch, modalName]
  );

  return (
    <Row
      paddingTop={['10px', '10px', '20px']}
      paddingLeft={['10px', '10px', '70px']}
      paddingRight={['10px', '10px', '60px']}
    >
      <Col size={[10 / 12, 10 / 12, 10 / 12]}>
        <IconWithTexts
          iconSize={[60, 80, 80]}
          iconName={iconName}
          titles={[title]}
          iconAsSeparateCol={false}
          contents={contents}
          topPaddingBetweenTitleTexts={'15px'}
          textsPadding={'9px'}
          titleStyle={['heading8', 'body2', 'body2']}
          tooltips={tooltips}
          priceType={DeductibleTypes.DOLLARS}
          rightComponent={
            isMobile ? (
              <SwitchBox
                fieldName={fieldName}
                modalName={modalName}
                handleSwitchChange={handleSwitchChange}
                handleEditClicked={handleEditClicked}
                checked={checked}
              />
            ) : undefined
          }
        />
      </Col>
      {!isMobile ? (
        <SwitchBox
          fieldName={fieldName}
          modalName={modalName}
          handleSwitchChange={handleSwitchChange}
          handleEditClicked={handleEditClicked}
          checked={checked}
        />
      ) : (
        <></>
      )}
      {rowError[fieldName] ? (
        <Row>
          <Col marginY={3}>
            <Typography textAlign='right' color='error' type='body6'>
              {get(rowError[fieldName], '0.breed')}
            </Typography>
          </Col>
        </Row>
      ) : null}
      <Divider color={isLennar(orgName) ? 'gray200' : 'primary500'} margin={20} />
    </Row>
  );
};
