import React, { useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { Guuid } from '../../types/common';
import { ZipCode } from './ZipCode';
import { CommunityList } from './CommunityList';
import { ModelList } from './ModelList';
import { PropertyInfo, PropertyInfoForm } from './PropertyInfo';
import { ContactInfo } from './ContactInfo';
import { getSearchParams } from './utils/getSearchParams';
import { getStep } from './utils/getStep';

import { getContactInfoPath, getPropertyInfoPath } from '../../helpers/paths';

export const ITEMS_PER_ROW = 50;

export type Step = 'zip' | 'communityList' | 'modelList' | 'propertyInfo' | 'contactInfo';

export type GetQuoteParams = {
  zipCode?: Guuid;
  communityId?: Guuid;
  modelId?: Guuid;
};

type GetQuoteProps = RouteComponentProps<null, StaticContext>;
export const GetQuote: React.FC<GetQuoteProps> = (props) => {
  const [propertyInfo, setPropertyInfo] = useState<PropertyInfoForm | undefined>();

  const params = getSearchParams(props.location.search);
  const { modelId, zipCode, communityId } = params;

  const step: Step = getStep({
    pathname: props.location.pathname,
    zipCode,
    communityId,
    modelId,
  });

  return (
    <Switch>
      <Route path={getPropertyInfoPath()}>
        <PropertyInfo formData={propertyInfo} onSubmit={setPropertyInfo} />
      </Route>
      <Route path={getContactInfoPath()}>
        <ContactInfo />
      </Route>

      <Route path='*'>
        {step === 'zip' && <ZipCode />}
        {step === 'communityList' && <CommunityList />}
        {step === 'modelList' && <ModelList />}
      </Route>
    </Switch>
  );
};
