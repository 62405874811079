import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FLOWS, PAGES } from 'globals/routes';
import QuotePage from 'pages/quote/Quote';
import { PageType } from 'helpers/enums/pageType';
import { hasHmac } from '../utils/hasHmac';

const StaticFlow: FC = () => {
  if (!hasHmac()) {
    return <Redirect to={'/404'} />;
  }

  return (
    <Switch>
      <Route path={`${FLOWS.static}/${PAGES.coverages}`} component={() => <QuotePage pageType={PageType.Static} />} />
      <Redirect to={'/404'} />
    </Switch>
  );
};

export default StaticFlow;
