import React from 'react';
import { Col, Row, TextInput, Typography } from '@hippo/components';
import styled from 'styled-components';
import { Flex } from './Flex';

const ResetButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
`;

type LennarSearchInputProps = {
  onChange: (value: string) => void;
  label: string;
  name: string;
  value: string;
  showResetButton: boolean;
  onResetButtonClick: () => void;
};

export const LennarSearchInput: React.FC<LennarSearchInputProps> = ({
  onChange,
  label,
  name,
  value,
  showResetButton,
  onResetButtonClick,
}) => (
  <Row>
    <Col offset={[0, 2 / 12, 2 / 12]} size={[1, 8 / 12, 8 / 12]}>
      <Flex position='relative'>
        <TextInput
          label={label}
          name={name}
          value={value}
          onChange={(e) => onChange(e.value.toString())}
          autoComplete='off'
        />
        {showResetButton ? (
          <ResetButton type='button' onClick={onResetButtonClick}>
            <Typography type='body4' color='primary500'>
              clear search
            </Typography>
          </ResetButton>
        ) : null}
      </Flex>
    </Col>
  </Row>
);
