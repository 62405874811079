import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

type Props = FlexboxProps & SpaceProps & LayoutProps & BorderProps & PositionProps & ColorProps & TextAlignProps;

const styledSystem = compose(border, color, flexbox, layout, position, space, textAlign);

export const Flex = styled.div<Props>(
  {
    display: 'flex',
  },
  styledSystem
);
