import { rgba } from 'polished';
import styled from 'styled-components';
import { Col, device, Grid, Typography } from '@hippo/components';
import { SpaceProps } from 'styled-system';
import { ComponentType } from 'react';
import { OrgNameMapping } from '../../helpers/enums/organization';

export const HeroImage = styled.img<{ orgName?: OrgNameMapping }>`
  position: absolute;
  object-fit: cover;
  z-index: 0;
  ${device.mobile} {
    object-position: ${({ orgName }) => (orgName === OrgNameMapping.Khov ? '65% center' : undefined)};
  }
`;

export const HeroTint = styled.div<{ transparent?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme, transparent }) =>
    transparent ? rgba(theme.colors.gray900, 0.5) : theme.colors.gray900};
  transition: background-color 1s ease-in-out;
  z-index: 0;
`;

export const StyledGrid = styled(Grid)({
  maxWidth: '1300px',
  width: '100%',
});

export const StyledCoverageInformation = styled.div`
  padding-top: 20px;

  ${device.mobile} {
    margin: 0 -12px;
  }

  ${device.tabletAndDesktop} {
    margin: 0 -10px;
  }
`;

export const CollapsedBarContainer = styled.div`
  background: rgb(0, 0, 0, 0.6);
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.04);

  min-height: 160px;
  position: relative;
  ${device.mobile} {
    min-height: 400px;
  }
  ${device.tablet} {
    min-height: 300px;
  }
  ${device.desktop} {
    border-radius: 10px 10px 10px 10px;
  }
`;

export const CollapsedBarController = styled.div<{ transitionState: string }>`
  width: 100%;
  min-height: 40px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.04);
  transition: border-radius 0.7s;
  border-radius: ${({ transitionState }) =>
    transitionState === 'entering' || transitionState === 'entered' ? '0px 0px 0px 0px' : '0px 0px 10px 10px'};
  position: absolute;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255);
    box-shadow: none;
  }
`;

export const CollapsedBarBottom = styled(Col)<{ transitionState: string; onClick: () => void }>`
  background: #e1e1e1;
  width: 100%;
  min-height: 40px;
  border-radius: 0px 0px 10px 10px;
  margin: auto;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255);
    box-shadow: none;
  }
`;

export const BannerImg = styled.img`
  object-fit: none;
  ${device.mobile} {
    object-fit: contain;
    width: 90%;
  }
`;

export const StyledFontWeight = styled.span`
  font-weight: 300;
`;

export const CollapsedOverlay = styled.div`
  position: relative;
  z-index: 1;
  & > * {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const TextOverflow = styled<ComponentType<HippoComponents.TypographyProps & SpaceProps>>(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
