import { push } from 'connected-react-router';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { OrgNameMapping } from '../helpers/enums/organization';
import { organizationImages } from '../utils/organizationImages';

export const Container = styled.div`
  text-align: center;
  padding: 41px 0;
  cursor: pointer;
`;

const Picture = styled.picture({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const Img = styled.img({
  height: '100%',
  maxWidth: '100%',
});

export interface LogoProps {
  logoUrl?: string;
  organizationName: OrgNameMapping;
  logoDefaultUrl?: string;
  logoMobileUrl?: string;
}

export const Logo: FC<LogoProps> = ({ logoUrl, organizationName, logoDefaultUrl, logoMobileUrl }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (logoUrl) {
      dispatch(push(logoUrl));
    }
  }, [logoUrl, dispatch]);

  const images = organizationImages[organizationName];

  return (
    <Picture onClick={handleClick}>
      <source srcSet={logoDefaultUrl ?? images.logoDefault} media='(min-width: 768px)' />
      <source srcSet={logoMobileUrl ?? images.logoMobile} media='(min-width: 0px)' />
      <Img src={logoMobileUrl ?? images.logoMobile} />
    </Picture>
  );
};
