import styled from 'styled-components';
import { device } from '@hippo/components';

export const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => p.theme.colors.gray600} 1px solid;
  padding: 20px 40px;
  ${device.mobile} {
    padding: 20px;
    flex-wrap: wrap;
  }
`;

export const SwitchText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${device.mobile} {
    order: 3;
    flex: 0 0 100%;
  }
`;

export const SwitchIcon = styled.div`
  flex: 0 0 50px;
  height: 40px;
  ${device.mobile} {
    flex: 1;
    text-align: left;
  }
`;

export const SwitchCheck = styled.div`
  flex: 0 0 50px;
  ${device.mobile} {
    flex: 1;
    text-align: right;
  }
`;
