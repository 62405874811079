import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { emptyErrorObj } from 'helpers/errors/emptyErrorObj';

import { fetchReferralModelAsync } from '../../actions/referral';

import { ReferralModelState } from './types';

const modelInitialState: ReferralModelState = { data: undefined };

export const referralModelReducer = reducerWithInitialState(modelInitialState)
  .case(fetchReferralModelAsync.started, (state) => ({
    ...state,
    error: emptyErrorObj,
    isFetched: false,
    isFetching: true,
  }))
  .case(fetchReferralModelAsync.failed, (state, { error }) => {
    return {
      ...state,
      error,
      isFetching: false,
    };
  })
  .case(fetchReferralModelAsync.done, (state: ReferralModelState, { result }) => ({
    ...state,
    data: result,
    isFetched: true,
    isFetching: false,
  }));
