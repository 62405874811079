import styled from 'styled-components';
import { Button as HippoButton } from '@hippo/components';

type EnhancedHippoButtonProps = {
  width?: string;
};

// In the design, the designer (Will) deliberately changed the styles of Button and Switch to
// differentiate the white label from other Hippo products.
// I’ve used the standard hippo components but overridden the styles to match the design.
// https://projects.invisionapp.com/d/main?origin=v7#/console/17999751/377022091/preview?scrollOffset=315
// It was decided this was a better approach than having too many options in the components themselves here:
// https://bitbucket.org/hippo-inc/hbo-consent-flow/pull-requests/160/adding-new-style-button-and-switch/diff?w=1
const Button = styled(HippoButton)<EnhancedHippoButtonProps>`
  width: ${({ width }) => width};
  > div {
    border-radius: 8px;
  }
`;

export default Button;
