import React from 'react';
import { Typography } from '@hippo/components';

const PageTitle: React.FC<Partial<HippoComponents.TypographyProps>> = ({ children, ...props }) => (
  <Typography
    type={['heading6', 'heading5', 'heading3']}
    textAlign='center'
    marginX={[3, 4, 5]}
    marginBottom={3}
    {...props}
  >
    {children}
  </Typography>
);

export default PageTitle;
