import { Typography } from '@hippo/components';
import { HippoImg, RowWithBorder } from '../styles';

type AgencyInfoProps = {
  isHippo: boolean;
  insurer: string | undefined;
  reference: string | undefined;
  policyType: string | undefined;
};
export const AgencyInfo = ({ isHippo, insurer, reference, policyType }: AgencyInfoProps) => (
  <RowWithBorder>
    <Typography type='body5' textAlign='left' color='gray600' className='_print_padding'>
      {isHippo ? <HippoImg src='/images/hippo-logo.svg' /> : null}
      {insurer ? <div>Insurer: {insurer}</div> : null}
      {reference ? <div>Reference #: {reference}</div> : null}
      {policyType ? <div>Policy Type: {policyType}</div> : null}
    </Typography>
  </RowWithBorder>
);
