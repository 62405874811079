import { Modal } from '@hippo/components';
import { Underwriting } from '../reducers/consent/types';
import { patchUnderwriting } from '../actions/consent';
import { closeModal } from '../actions/modals';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers/createRootReducer';

import SwitchRow from '../components/SwitchRow/SwitchRow';
import SwitchContainer from '../components/SwitchContainer/SwitchContainer';
import { ModalProps } from '../types/modals';

const SecondHomeModal: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  const underwriting = useSelector<State, Underwriting | undefined>((state) =>
    state.consent.data ? state.consent.data.underwriting : undefined
  );

  const handleUpdate = useCallback(
    (change: HippoComponents.OnChange<boolean>) => {
      dispatch(
        patchUnderwriting({
          [change.name]: change.value,
        })
      );
    },
    [dispatch]
  );

  const isChecked = (fieldName: keyof Underwriting) => {
    if (underwriting && underwriting[fieldName]) {
      return !!underwriting[fieldName];
    }
    return false;
  };

  const handleClickSave = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <Modal
      {...props}
      title={'Ok, tell us more.'}
      size={'large'}
      primaryButtonText={'Save Info'}
      onPrimaryButtonClick={handleClickSave}
      disableBodyPadding={true}
      subtitle={'Check all that apply'}
    >
      <SwitchContainer>
        <SwitchRow
          onChange={handleUpdate}
          checked={isChecked('secondary_residence')}
          name='secondary_residence'
          icon='SecondHome'
          title='Second Home'
          description='Is this property a second home not used as your primary residence?'
        />
        <SwitchRow
          onChange={handleUpdate}
          checked={isChecked('rental_or_vacant')}
          name='rental_or_vacant'
          icon='Ghost'
          title='Rental Property or Vacant'
          description='Is this property currently vacant or a rental property occupied by someone other than you?'
        />
      </SwitchContainer>
    </Modal>
  );
};
export default SecondHomeModal;
