import { Row, Typography } from '@hippo/components';
import React from 'react';
import { ThemeProviderOverride } from '../../components/ThemeProviderOverride';
import PageContainer from '../../components/PageContainer/PageContainer';
import { Flex } from '../../components/Flex';
import { HeroImage, HeroTint, StyledGrid } from './style';
import { OrgNameMapping } from '../../helpers/enums/organization';
import useModel from '../../helpers/useModel';
import { organizationImages } from '../../utils/organizationImages';
import { Logo } from '../../components/Logo';
import Button from '../../components/button/Button';
import styled from 'styled-components';

const Link = styled.a({
  textDecoration: 'none',
});

type QuoteNotFoundProps = {
  handleImageLoad: () => void;
  tintTransparent: boolean;
};

export const QuoteNotFound: React.FC<QuoteNotFoundProps> = ({ tintTransparent, handleImageLoad }) => {
  const modelData = useModel();
  const images = organizationImages[OrgNameMapping.Lennar];

  return (
    <ThemeProviderOverride orgName={OrgNameMapping.Lennar}>
      <PageContainer backgroundColor={['primary50', 'primary50', 'white']}>
        <Flex position='absolute' width='100%' height='100%'>
          <HeroImage width='100%' height='100%' onLoad={handleImageLoad} srcSet={images.hero} />
          <HeroTint transparent={tintTransparent} />
        </Flex>
        <Flex display='block' position='relative'>
          <StyledGrid>
            <Row>
              <Flex flexDirection='column' maxWidth='477px'>
                <Flex maxHeight='63px' marginTop='31px' marginBottom='78px'>
                  <Logo organizationName={OrgNameMapping.Lennar} logoDefaultUrl={images.logoWhite} />
                </Flex>

                <Typography type='display7' color='white' as='h2' marginBottom='24px'>
                  We apologize, an online quote for this home is currently unavailable.
                </Typography>
                <Typography type='heading4' color='white' marginBottom='40px'>
                  Contact us by phone or email for a personalized insurance quote.
                </Typography>
                <Typography type='display7' color='white' marginBottom='72px'>
                  Thank you!
                </Typography>

                <Flex flexDirection='column' width='407px' alignItems='center'>
                  <Flex
                    width='100%'
                    as={Link}
                    href={`mailto:${modelData?.data?.community?.agent?.email || 'team@lennarinsurance.com'}`}
                  >
                    <Button size='large' styleType='primary' type='button' style={{ width: '100%' }}>
                      Send us an email
                    </Button>
                  </Flex>

                  <Flex marginBottom='40px' />

                  <Link href={`tel:${modelData?.data?.community?.agent?.phone_number || '(888) 543-6180'}`}>
                    <Typography type='heading6' color='white'>
                      Call us at {modelData?.data?.community?.agent?.phone_number || '(888) 543-6180'}
                    </Typography>
                  </Link>
                </Flex>
              </Flex>
            </Row>
          </StyledGrid>
        </Flex>
      </PageContainer>
    </ThemeProviderOverride>
  );
};
