import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { createRootReducer, State } from 'reducers/createRootReducer';
import { Action, applyMiddleware, createStore, Middleware } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

export type ReduxDispatch<ExtraArgument = unknown> = ThunkDispatch<State, ExtraArgument, Action>;

export default function configureStore(initialState: any, history: History) {
  const middlewares: Array<Middleware> = [routerMiddleware(history), thunk];

  const enhancers = applyMiddleware(...middlewares);

  const reducers = createRootReducer(history);

  return createStore(reducers, initialState, enhancers);
}
