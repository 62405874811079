import React from 'react';
import { Typography } from '@hippo/components';

import { formatCurrency } from '../../helpers/formatters';

const PriceBox: React.FC<{
  price: string | number;
  type: HippoComponents.TypographyTypeName | Array<HippoComponents.TypographyTypeName>;
  color?: HippoComponents.Color;
  textAlign?: string;
}> = ({ price, type, textAlign = 'left', color }) => {
  const formatedCurrency = formatCurrency(price);
  return (
    <Typography type={type} textAlign={textAlign} color={color}>
      {formatedCurrency}
    </Typography>
  );
};

export default PriceBox;
