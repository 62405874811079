import React from 'react';
import { FieldProps, useField } from 'formik';
import { Checkbox, Typography } from '@hippo/components';
import styled from 'styled-components';
import { Flex } from './Flex';

export const checkboxTestId = {
  checked: 'checked',
};

export const HoverLabel = styled.label<{ disabled: boolean; hasError?: boolean }>`
  display: flex;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  }

  button {
    pointer-events: none;
  }

  img {
    border: ${({ hasError }) => (hasError ? '1px solid red' : '')};
    border-radius: ${({ hasError }) => (hasError ? '100%' : '')};
  }
`;

type WrappedCheckboxProps = HippoComponents.CheckboxProps &
  FieldProps<boolean> & {
    label: string;
    isReadOnly: boolean;
    hideErrorMessage?: boolean;
  };

export const WrappedCheckbox: React.FC<WrappedCheckboxProps> = ({
  field: { value, onChange, name },
  label,
  isReadOnly,
  size,
  disabled,
  hideErrorMessage,
}) => {
  const [, meta] = useField(name);

  return (
    <Flex flexDirection='column'>
      <HoverLabel disabled={disabled || isReadOnly} data-testid={name} hasError={Boolean(meta.error)}>
        <Checkbox
          checked={value}
          size={size || 16}
          onClick={() => {
            onChange({
              target: {
                name: name,
                value: !value,
              },
            });
          }}
          disabled={disabled || isReadOnly}
        />

        {typeof label === 'string' ? (
          <Typography type='allCaps4' as='span' p='8px'>
            {label}
          </Typography>
        ) : (
          label
        )}
      </HoverLabel>
      {Boolean(hideErrorMessage) === false && meta.error ? (
        <Typography type='body6' color='error' mt='20px'>
          {meta.error}
        </Typography>
      ) : null}
    </Flex>
  );
};
