import React from 'react';
import styled from 'styled-components';
import { lennarColorConfig } from 'components/ThemeProviderOverride';
import { Link } from 'react-router-dom';

type Direction = 'left' | 'right';
type ArrowProps = {
  fill: string;
  direction?: 'left' | 'right';
  'data-testid'?: string;
};
export const Arrow: React.FC<ArrowProps> = ({ fill, ...props }) => (
  <svg
    className='arrow'
    width='9'
    height='16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid={props['data-testid']}
  >
    <path
      d='M6.52578 7.99999L.373955 1.84816c-.3514714-.35147-.3514714-.921317 0-1.272789.351472-.351471.921325-.351471 1.272795 0L8.43497 7.3636c.35147.35147.35147.92132 0 1.27279L1.64675 15.4246c-.35147.3515-.921323.3515-1.272795 0-.3514714-.3515-.3514714-.9213 0-1.2728L6.52578 7.99999z'
      fill={fill}
    />
  </svg>
);

type LinkProps = {
  direction?: Direction;
  disabled?: boolean;
};
const Button = styled(Link)<LinkProps>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 20px;

  ${(props) => (props.direction === 'left' ? 'transform: scaleX(-1);' : '')};
  ${(props) => (props.disabled === true ? 'cursor: default;' : '')};
`;

type ArrowLinkProps = {
  disabled?: boolean;
  direction?: Direction;
  to: string;
  'data-testid'?: string;
};
export const ArrowLink: React.FC<ArrowLinkProps> = ({ disabled, direction, to, ...props }) => (
  <Button direction={direction} to={disabled ? '#' : to} disabled={disabled}>
    <Arrow
      fill={disabled ? lennarColorConfig.gray200 : lennarColorConfig.primary500}
      data-testid={props['data-testid']}
    />
  </Button>
);
