import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { emptyErrorObj } from 'helpers/errors/emptyErrorObj';

import merge from 'lodash/merge';
import {
  fetchConsentDataAsync,
  patchDiscount,
  patchUnderwriting,
  saveAsync,
  updateOptionsAction,
} from '../../actions/consent';

import { ConsentState } from './types';

const initialState: ConsentState = { data: null, error: emptyErrorObj };

const reducer = reducerWithInitialState(initialState)
  // fetch data
  .case(fetchConsentDataAsync.started, (state, quoteId) => ({
    ...state,
    error: emptyErrorObj,
    isFetched: false,
    isFetching: true,
    params: { quoteId },
  }))
  .case(fetchConsentDataAsync.failed, (state, { error }) => {
    return {
      ...state,
      error,
      isFetching: false,
    };
  })
  .case(fetchConsentDataAsync.done, (state, { params: quoteId, result }) => ({
    ...state,
    data: result,
    isFetched: true,
    isFetching: false,
    params: { quoteId },
  }))
  // save
  .case(saveAsync.started, (state, { quoteId }) => ({
    ...state,
    error: emptyErrorObj,
    isSaved: false,
    isSaving: true,
    params: { quoteId },
  }))
  .case(saveAsync.failed, (state, { error }) => {
    return {
      ...state,
      error,
      isSaving: false,
    };
  })
  .case(saveAsync.done, (state, { params, result }) => {
    // Merging the consent data we have locally with response from backend
    const mergedResultData = merge(state.data, result);
    return {
      ...state,
      data: mergedResultData,
      isSaved: true,
      isSaving: false,
      params: { quoteId: params.quoteId },
    };
  })
  // options
  .case(updateOptionsAction, (state, options) => ({
    ...state,
    options,
  }))
  // patch
  .case(patchUnderwriting, (state, payload) => {
    if (state.data) {
      return { ...state, data: { ...state.data, underwriting: { ...state.data.underwriting, ...payload } } };
    }
    return state;
  })
  .case(patchDiscount, (state, discounts) => {
    if (state.data) {
      return { ...state, data: { ...state.data, discounts: { ...state.data.discounts, ...discounts } } };
    }
    return state;
  });

export default reducer;
