/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Divider, Link, Typography } from '@hippo/components';

import { PageType } from '../../../helpers/enums/pageType';
import { AppThunkDispatch } from '../../../types/redux-thunk';
import { openModal } from '../../../actions/modals';
import { RowWithBorder, Sub, Sup } from '../styles';
import Box from '../../../components/box/Box';
import { formatCurrency } from '../../../helpers/formatters';

import { useResponsive } from 'helpers/useResponsive';
import { ModalNames } from '../../../types/modals';
import { coveragesId } from '../coveragesId';
import { ButtonLink } from '../../../components/ButtonLink';
import { SmartHomeSection } from '../../../components/SmartHomeSection';
import { StyledWrapper } from './style';

export interface CoverageColRightProps {
  pageType: PageType;
  agencyInfo: ReactNode;
  agentInfo: ReactNode;
  ctaButton: ReactNode;
  premium: { monthly: string; yearly: string };
  quoteLink: string;
}

export const CoverageColRight: React.FC<CoverageColRightProps> = ({
  pageType,
  agencyInfo,
  premium,
  quoteLink,
  agentInfo,
  ctaButton,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isDesktop } = useResponsive();

  const handleUnderstandingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openModal({ modalName: ModalNames.CoverageModal }));
  };

  return (
    <Col
      size={[1, 1, 4 / 12]}
      paddingTop={['0px', '0px', '0px']}
      paddingLeft={['0px', '0px', '0px']}
      paddingRight={['0px', '0px', '0px']}
      backgroundColor='white'
    >
      <StyledWrapper>
        {agencyInfo}
        {isDesktop && <Divider color={'primary500'} className={'__print_divider'} />}
        {pageType !== PageType.Consent && pageType !== PageType.Referral ? (
          <>
            <RowWithBorder>
              <Typography type={['allCaps3', 'allCaps3', 'allCaps3']} color={'primary500'} textAlign={'left'}>
                YOUR PREMIUM
              </Typography>
              <Typography type={'display1'} className={'__monthly_pay'}>
                <Sup>$</Sup>
                {formatCurrency(premium.monthly, '')}
                <Sub>/mo</Sub>
              </Typography>
            </RowWithBorder>
            <Divider color={'primary500'} className={'__print_divider'} />
            <RowWithBorder>
              <Typography type={'body4'} color={'gray500'} className={'__yearly_pay'}>
                ${formatCurrency(premium.yearly, '')}/yearly
              </Typography>
            </RowWithBorder>
          </>
        ) : null}
        {isDesktop && (
          <>
            {ctaButton}
            {quoteLink ? (
              <>
                <Box
                  paddingY={2}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  className={'__no_print'}
                  textAlign={'center'}
                >
                  <Link type={'allCaps3'} backgroundColorType={'light'} href={quoteLink}>
                    DOWNLOAD MY QUOTE
                  </Link>
                </Box>
                <Box paddingY={2} className={'__no_print'} textAlign={'center'}>
                  <ButtonLink
                    onClick={(e) => handleUnderstandingClick(e)}
                    data-testid={coveragesId.understandingMyPlanButton}
                  >
                    <Typography type={'allCaps3'}>UNDERSTANDING MY PLAN</Typography>
                  </ButtonLink>
                </Box>
              </>
            ) : null}
          </>
        )}
        <SmartHomeSection pageType={pageType} />
        {isDesktop && <Divider color={'primary500'} className={'__print_divider'} />}

        {agentInfo}
        <RowWithBorder flex={'1 0 auto'} justifyContent={'flex-end'} pb={[0, 0, 70]}>
          <Typography type={'body6'} paddingBottom={3} color='gray700'>
            * This preliminary quote is based upon current builder data. Any changes to the size of the home, number of
            bedrooms, bathrooms or garages may change the quote.
          </Typography>
          <Typography type={'body6'} color='gray700'>
            Note: This is not a policy. This quote is based on information displayed. If anything is incorrect, please
            notify us to adjust it and provide you with an updated rate. Acceptance is subject to the insurance
            company’s normal underwriting review, possibly including verification of insurance score and previous claims
            history. Insurance companies at times take rate changes which could affect this quote prior to issuance.
          </Typography>
        </RowWithBorder>
      </StyledWrapper>
    </Col>
  );
};
