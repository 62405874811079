import { UseQueryOptions, useQuery } from 'react-query';
import { ModelSummary, getModelsForCommunity } from '../utils/api';

const getModelsForCommunityKey = (parameters: Parameters<typeof getModelsForCommunity>[0]) => [
  'modelsForCommunity',
  JSON.stringify(parameters),
];

export default (
  parameters: Parameters<typeof getModelsForCommunity>[0],
  options?:
    | Omit<UseQueryOptions<Array<ModelSummary>, unknown, Array<ModelSummary>, string[]>, 'queryKey' | 'queryFn'>
    | undefined
) =>
  useQuery(
    getModelsForCommunityKey(parameters),
    async () => {
      const { data } = await getModelsForCommunity(parameters);
      return data;
    },
    options
  );
