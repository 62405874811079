import 'react-app-polyfill/stable';
import FullStory from 'react-fullstory';
import { ModalProvider, ResetStyle, theme } from '@hippo/components';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { FlagshipWrapper } from './components/FlagshipWrapper/FlagshipWrapper';

import './style.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import configureStore from './store';
import { ScrollToTop } from './utils/scroll-to-top';
import { defaultColorConfig } from './components/ThemeProviderOverride';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import ModalController from './components/modal/Modal';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const history = createBrowserHistory();
const store = configureStore({}, history);

const themeOverride = { ...theme };
themeOverride.colors = defaultColorConfig;
themeOverride.fontFamily = '"Source Sans Pro"';

const orgId = window?.appConfig?.FULL_STORY_ORG_ID ?? '';

const render = () => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={themeOverride}>
          <React.Fragment>
            <FullStory org={orgId} host='fullstory.com' />
            <ResetStyle />
            <ConnectedRouter history={history}>
              <FlagshipWrapper>
                <ScrollToTop />
                <React.Suspense fallback={null}>
                  <ModalProvider>
                    <ModalController />
                  </ModalProvider>
                </React.Suspense>
                <App />
              </FlagshipWrapper>
            </ConnectedRouter>
            <ToastContainer theme='colored' icon={false} autoClose={15000} />
          </React.Fragment>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
  );
};

if (process.env.REACT_APP_API_MOCK === 'ON' && process.env.NODE_ENV === 'development') {
  import(/* webpackChunkName: 'ApiServer' */ './mocks/apiServer').then(({ mockWorker }) => {
    mockWorker();
    render();
  });
} else {
  render();
}
