import React, { FC, ReactNode } from 'react';
import { Typography } from '@hippo/components';

interface SubtitleProps {
  children?: ReactNode;
}

const Subtitle: FC = (props: SubtitleProps) => (
  <Typography type={'body4'} textAlign={'center'} marginTop={'7px'} marginBottom={'55px'} color={'gray200'}>
    {props.children}
  </Typography>
);

export default Subtitle;
