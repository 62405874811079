type getArrayfromObj = {
  obj: any;
  exception: Array<string>;
  chunkRule: any;
};

const getArrayFromObj = ({ obj, exception, chunkRule }: getArrayfromObj) => {
  const arr = [];
  let index = 0;
  let temp = '';
  const exceptionSet = new Set(exception);
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      const p = property;

      if (!exceptionSet.has(property) && obj[p]) {
        temp = `${temp} ${obj[p]}`;
      }
      if (chunkRule[index]) {
        const item = temp.slice();
        arr.push(item);
        temp = '';
      }
      index++;
    }
  }
  return arr;
};

export default getArrayFromObj;
