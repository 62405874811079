import React from 'react';
import { Col, Divider as HippoDivider, Row } from '@hippo/components';
import styled from 'styled-components';

const RowWithMargin = styled(Row)`
  margin: 0 -20px;
`;

type DividerProps = {
  size?: number;
  offset?: number;
};

export const Divider: React.FC<DividerProps> = ({ size = 1, offset = 0 }) => (
  <RowWithMargin>
    <Col size={size} offset={offset}>
      <HippoDivider color='gray100' />
    </Col>
  </RowWithMargin>
);
