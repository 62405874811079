import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useConsentData } from 'helpers/use-consent-data';
import { FLOWS } from './globals/routes';
import {
  getLandingPath,
  getQuotePath,
  getCommunityListPath,
  getDivisionListPath,
  getCoverageExplanationUrl,
} from './helpers/paths';
import ConfirmationFlow from './routes/confirmation-flow';
import ConsentFlow from './routes/consent-flow';
import Box from 'components/box/Box';
import { Spinner } from 'components/Spinner';
import { useDispatch } from 'react-redux';
import { fetchOptions } from './actions/consent';
import { useEffect } from 'react';
import { initializeCoralogixRum } from './utils/initializeCoralogixRum';

import MissingPage from 'pages/missing/Missing';
import { ThankYou } from 'pages/thankYou/ThankYou';
import CommunitiesRatesheet from 'pages/communitiesRatesheet/CommunitiesRatesheet';
import { GetQuote } from 'pages/getQuote/GetQuote';
import StaticFlow from './routes/static-flow';
import LandingPage from './pages/landing/Landing';
import { DivisionList } from './pages/divisionList/DivisionList';
import { CommunityList } from './pages/communityList/CommunityList';
import { CoverageExplanation } from './pages/coverageExplanation/CoverageExplanation';

initializeCoralogixRum();

const FlowRoutes = () => {
  useConsentData();

  return (
    <Switch>
      <Route path={FLOWS.consent} component={ConsentFlow} />
      <Route path={FLOWS.confirm} component={ConfirmationFlow} />
      <Route path={FLOWS.static} component={StaticFlow} />
      <Redirect to={'/404'} />
    </Switch>
  );
};

const quoteIdRegexStr = '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}';
export const pathToFlows = `/(consent|confirm|static)/:quoteId(${quoteIdRegexStr})`;

const LoadingFallback = () => (
  <Box flex='1' height='100vh' alignItems='center' justifyContent='center'>
    <Spinner />
  </Box>
);
export const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOptions());
  }, [dispatch]);

  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <Switch>
        <Route path={pathToFlows} component={FlowRoutes} />
        <Route path={getQuotePath()} component={GetQuote} />
        <Route path='/thank-you' component={ThankYou} />
        <Route exact={true} path={getLandingPath()} component={LandingPage} />

        <Route path={getDivisionListPath()} exact={true} component={DivisionList} />
        <Route path={getCommunityListPath()} component={CommunityList} />

        <Route path='/communities/:communityId/ratesheet' component={CommunitiesRatesheet} />
        <Route path={getCoverageExplanationUrl()} component={CoverageExplanation} />
        <Route exact={true} path='/404' component={MissingPage} />
        <Route exact={true} path='/500' component={MissingPage} />
        <Redirect to='/404' />
      </Switch>
    </React.Suspense>
  );
};

export default App;
