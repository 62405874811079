import Box from 'components/box/Box';
import { Col, Grid, Row, Typography } from '@hippo/components';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'reducers/createRootReducer';
import { FooterLink, Separator } from 'components/footer/style';
import { License, WhiteLabelPages } from 'reducers/white-label/types';
import { footerId } from './footerId';

/**
 * @deprecated Just use Footer to avoid useSelector and redux state
 */
export const FooterContainer: React.FC = () => {
  const whiteLabel = useSelector((state: State) => state.consent.data?.white_label);
  const footer = useSelector((state: State) => state.whiteLabel.data?.pages.footer);

  const licenses = useMemo(
    () =>
      whiteLabel?.license_number && whiteLabel?.state
        ? [
            {
              state: whiteLabel.state,
              license_number: whiteLabel.license_number,
              description: '',
            },
          ]
        : [],
    [whiteLabel?.license_number, whiteLabel?.state]
  );

  return <Footer builder={whiteLabel} links={footer} licenses={licenses} />;
};

type FooterProps = {
  builder?: {
    agency_name: string;
    copyright: string;
    license_name: string;
    license_number?: string;
    state?: string;
  };
  links?: WhiteLabelPages['footer'];
  licenses: License[];
};

export const Footer: React.FC<FooterProps> = ({ builder, links, licenses }) => (
  <Box marginBottom={[80, 4, 5]} marginTop={[30, 4, 5]}>
    <Grid style={{ width: '100%' }}>
      <Row flexDirection='column' middle={true}>
        <Col>
          <Typography type={'heading11'} color={'gray600'} textAlign={'center'} as='p' data-testid={footerId.licenses}>
            {builder && builder.agency_name && licenses.length > 0 ? (
              <>
                Licensed as{' '}
                {builder.agency_name === 'Toll Brothers Agency'
                  ? 'Hippo Builder Insurance Agency'
                  : builder.agency_name}
                .{' '}
                {licenses.map((license, index) => (
                  <Typography type={'body5'} color={'gray600'} as={'span'} key={index}>
                    {`${license.state} #${license.license_number}`}
                    {licenses.length - 1 === index ? '' : ', '}
                  </Typography>
                ))}
              </>
            ) : null}
          </Typography>
        </Col>
        <Col>
          <Typography type={'heading11'} color={'gray600'}>
            {links?.privacy_policy_doc ? (
              <FooterLink href={links?.privacy_policy_doc} target='_blank'>
                Privacy Policy
              </FooterLink>
            ) : null}

            {links?.privacy_policy_doc && links?.terms_of_use_doc ? <Separator /> : null}

            {links?.terms_of_use_doc ? (
              <FooterLink href={links?.terms_of_use_doc} target='_blank'>
                Terms of Use
              </FooterLink>
            ) : null}
          </Typography>
        </Col>
      </Row>
    </Grid>
  </Box>
);
