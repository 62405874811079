import { Col, Row, StatesDropdown, TextInput, Typography, Zipcode } from '@hippo/components';
import { useEffect, useState } from 'react';
import Button from 'components/button/Button';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { getSearchParam } from '../../utils/getSearchParam';
import { PageType } from '../../helpers/enums/pageType';
import { useHistory } from 'react-router';
import { getContactInfoPath, getQuotePath } from '../../helpers/paths';
import useCommunitiesRatesheet from '../../helpers/useCommunitiesRatesheet';

export type PropertyInfoForm = {
  addressLine1: string;
  zipCode: string;
  state: string;
  city: string;
};

export const PropertyInfo = ({
  formData,
  onSubmit,
}: {
  formData: PropertyInfoForm | undefined;
  onSubmit: (data: PropertyInfoForm) => void;
}) => {
  const history = useHistory();
  const modelId = getSearchParam(window.location.search, 'modelId') || undefined;
  const communityId = getSearchParam(window.location.search, 'communityId') || undefined;

  const [form, setForm] = useState<PropertyInfoForm>(
    formData ?? {
      addressLine1: '',
      zipCode: '',
      state: '',
      city: '',
    }
  );

  const onChange = (changeEvent: HippoComponents.OnChange<HippoComponents.DropdownItemListItemValue>) => {
    setForm({
      ...form,
      [changeEvent.name]: changeEvent.value,
    });
  };

  const communitiesRatesheet = useCommunitiesRatesheet(communityId ?? '', {
    enabled: Boolean(communityId),
  });

  const community = communitiesRatesheet.data;

  useEffect(() => {
    if (communitiesRatesheet.error) {
      history.push(getQuotePath());
    }

    if (formData === undefined && community) {
      setForm({
        addressLine1: community?.street,
        zipCode: community?.zip,
        state: community?.state,
        city: community?.city,
      });
    }
  }, [formData, communitiesRatesheet.error, community, history]);

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: community?.state ? [community?.state] : [],
  });

  const isValidForm = () => {
    if (form.zipCode.length !== 5) return false;

    return Object.values(form).every((v) => v);
  };

  const onContinue = () => {
    onSubmit(form);
    history.push(
      getContactInfoPath({
        zipCode: form.zipCode,
        communityId,
        modelId,
        street: community?.street,
        state: community?.state,
        city: community?.city,
      })
    );
  };

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={whiteLabel.data?.licenses ?? []}
      landingPagePath={generateLandingPageUrl(PageType.Referral)}
      pages={whiteLabel.data?.pages}
    >
      <Row>
        <Col mt='40px' mb='80px'>
          <Typography type='heading4' color='black' textAlign='center'>
            Please enter your property information
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onContinue();
        }}
      >
        <Row>
          <Col size={[1, 1, 8 / 12]} offset={[0, 0, 2 / 12]}>
            <TextInput
              label='Property address'
              name='addressLine1'
              options={{ required: true }}
              value={form.addressLine1}
              onChange={onChange}
            />
          </Col>
          <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
            <TextInput label='City' name='city' options={{ required: true }} value={form.city} onChange={onChange} />
          </Col>
          <Col size={[1, 1, 2 / 12]}>
            <StatesDropdown
              onChange={onChange}
              name='state'
              value={form.state}
              label='States'
              numberOfItemsToBeShown={5}
            />
          </Col>
          <Col size={[1, 1, 2 / 12]}>
            <Zipcode
              label='Zip code'
              name='zipCode'
              options={{ required: true }}
              value={form.zipCode}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row paddingTop='110px'>
          <Col size={[1, 1, 4 / 12]} offset={[0, 0, 4 / 12]}>
            <Button styleType='primary' size={['small', 'large', 'large']} disabled={!isValidForm()}>
              Continue
            </Button>
          </Col>
        </Row>
      </form>
    </LennarSearchWrapper>
  );
};
