import React from 'react';

export const Diamond = () => (
  <svg viewBox='0 0 184 184' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='Asset-Artboard-Page' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='icon-marketing-/-secure-policies'>
        <path id='Container' opacity='.5' d='M0 0h184v184H0z' />
        <path
          id='Stroke-1'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M127 35.5L92.576 152.543'
        />
        <path
          id='Stroke-3'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M57 35.5l34.424 117.043'
        />
        <path
          id='Stroke-5'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M116 69L92 36'
        />
        <path
          id='Stroke-7'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M68 69l24-33'
        />
        <path
          id='Stroke-9'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M27 69.5h130'
        />
        <path
          id='Stroke-11'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M92 154L25 68.496 57.143 35h69.989L159 68.496z'
        />
      </g>
    </g>
  </svg>
);
