import React, { Fragment, useState } from 'react';
import { Col, Row, Typography } from '@hippo/components';
import { formatSquareFeet } from 'helpers/formatters';
import { Arrow } from 'components/ArrowButton';
import { lennarColorConfig } from 'components/ThemeProviderOverride';
import { Divider } from 'components/Divider';
import { CommunityRow } from 'components/CommunityRow';
import { ModelSummary } from 'utils/api';
import { Spinner } from 'components/Spinner';
import useModelsForCommunity from '../../helpers/useModelsForCommunity';
import { getSearchParam } from '../../utils/getSearchParam';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { PageType } from '../../helpers/enums/pageType';
import { Link } from 'react-router-dom';
import { getLandingPath } from '../../helpers/paths';
import { filterList } from '../divisionList/DivisionList';
import { Flex } from '../../components/Flex';
import { LennarSearchInput } from '../../components/LennarSearchInput';
import useCommunitiesRatesheet from '../../helpers/useCommunitiesRatesheet';

const getHomeDetails = (model: ModelSummary) =>
  model.bedroom || model.baths || model.sqft
    ? [
        model.bedroom ? `${model.bedroom} beds` : null,
        model.baths ? `${model.baths} baths` : null,
        model.sqft ? `${formatSquareFeet(model.sqft)} sq ft` : null,
      ]
        .filter((x) => x !== null)
        .join(' | ')
    : 'Home details not available';

export const ModelList = () => {
  const [model, setModel] = useState('');

  const communityId = getSearchParam(window.location.search, 'communityId') ?? '';
  const modelList = useModelsForCommunity(communityId, { enabled: Boolean(communityId) });

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: [],
  });

  const filteredModels = filterList<Array<ModelSummary & { value: undefined; display: string }>>(
    model,
    modelList.data?.map((model) => ({
      ...model,
      display: `${model.model_name}${model.sqft}`,
      value: undefined,
    })) ?? []
  );

  const communitiesRatesheet = useCommunitiesRatesheet(communityId ?? '', {
    enabled: Boolean(communityId),
  });

  const communityName = communitiesRatesheet.data?.community_name;

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={whiteLabel.data?.licenses ?? []}
      landingPagePath={generateLandingPageUrl(PageType.Referral)}
      pages={whiteLabel.data?.pages}
    >
      <Row>
        <Col mt='40px'>
          <Typography type='heading4' color='black' textAlign='center'>
            Select your model
          </Typography>
        </Col>
      </Row>
      {communityName && (
        <Row>
          <Col mt='3'>
            <Typography key='text' type='body4' color='gray600' textAlign='center'>
              Here are models in the{' '}
              <Typography type='body4' color='primary500' textAlign='center' as='span'>
                &quot;{communityName}&quot;
              </Typography>{' '}
              community
            </Typography>
          </Col>
        </Row>
      )}

      <Flex mt='26px' />
      <LennarSearchInput
        label='Model'
        name='model'
        value={model}
        onChange={setModel}
        showResetButton={false}
        onResetButtonClick={() => setModel('')}
      />

      <Row>
        {modelList.isLoading ? (
          <Col size={1} alignItems='center'>
            <Spinner />
          </Col>
        ) : (
          <Col size={[1, 8 / 12, 8 / 12]} offset={[0, 2 / 12, 2 / 12]}>
            {filteredModels.length === 0 ? (
              <Flex width='100%' justifyContent='center' mt='50px'>
                <Typography type='body4' color='gray600'>
                  No results for the “{model}”
                </Typography>
              </Flex>
            ) : null}
            <Fragment>
              {filteredModels?.map((model) => (
                <Link style={{ textDecoration: 'none' }} to={getLandingPath(communityId, model.id)} key={model.id}>
                  <CommunityRow py='12px'>
                    <Col size={4.5 / 12}>
                      <Typography type='body4' color='gray600' textAlign='left'>
                        {model.model_name || '-'}
                      </Typography>
                    </Col>
                    <Col size={7.5 / 12}>
                      <Typography type='body5' color='gray600' textAlign='left'>
                        {model.elevation ? `Elevation ${model.elevation} | ` : null}
                        {getHomeDetails(model)}
                      </Typography>
                    </Col>

                    <Arrow fill={lennarColorConfig.primary500} />
                  </CommunityRow>
                  <Divider />
                </Link>
              ))}
            </Fragment>
          </Col>
        )}
      </Row>
    </LennarSearchWrapper>
  );
};
