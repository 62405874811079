const initializeRetry = (maxRetries = 3) => {
  if (maxRetries <= 0) {
    return;
  }

  if (window?.appConfig?.CORALOGIX_RUM_PUBLIC_KEY && window.CoralogixRum?.init) {
    try {
      window.CoralogixRum?.init?.({
        public_key: window.appConfig.CORALOGIX_RUM_PUBLIC_KEY,
        application: 'hbo-consent-flow',
        version: '1.0',
        coralogixDomain: 'US1',
        environment: window.location.hostname,
      });
    } catch {}
  } else {
    setTimeout(() => {
      initializeRetry(maxRetries - 1);
    }, 1000);
  }
};

export const initializeCoralogixRum = () => {
  initializeRetry();
};
