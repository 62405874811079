import React, { FC } from 'react';
import { Typography } from '@hippo/components';

const Disclaimer: FC = (props) => (
  <Typography marginY={'10px'} textAlign={'center'} type={'body5'} color={'gray600'}>
    {props.children}
  </Typography>
);

export default Disclaimer;
