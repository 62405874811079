import React from 'react';

export const Home = () => (
  <svg viewBox='0 0 184 184' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='Asset-Artboard-Page' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='icon-marketing-/-modern-coverage'>
        <path id='Container' opacity='.5' d='M0 0h184v184H0z' />
        <g
          id='house'
          transform='translate(13 74)'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='4'
        >
          <path id='Stroke-28' d='M122 40v62M35.5 40v62' />
          <path id='Stroke-22' d='M67 103h24V70H67z' />
          <path id='Stroke-25' d='M38 29V11h10v10.588' />
          <path id='Stroke-26' d='M45 62h18V48H45zm50 0h18V48H95z' />
          <path id='Stroke-27' d='M23 40L78.999 0 135 40z' />
          <path d='M85 87c0 1.656-1.345 3-3 3s-3-1.344-3-3a3 3 0 016 0' id='Stroke-34' />
          <path id='Stroke-36' d='M0 103.5h158' />
        </g>
        <g
          id='star-2'
          transform='translate(110 32)'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='4'
        >
          <path d='M33.5 60V7m0-6V0m0 67v-1' id='Combined-Shape' />
          <path d='M33.5 57.627V9.373m0-5.463V3m0 61v-.91' id='Combined-Shape' transform='rotate(45 33.5 33.5)' />
          <path d='M33.5 57.627V9.373m0-5.463V3m0 61v-.91' id='Combined-Shape' transform='rotate(-45 33.5 33.5)' />
          <path d='M60 33.5H7m-6 0H0m67 0h-1' id='Combined-Shape' />
        </g>
        <g
          id='star-1'
          transform='translate(32 6)'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='4'
        >
          <path d='M33.5 60V7m0-6V0m0 67v-1' id='Combined-Shape' />
          <path d='M33.5 57.627V9.373m0-5.463V3m0 61v-.91' id='Combined-Shape' transform='rotate(45 33.5 33.5)' />
          <path d='M33.5 57.627V9.373m0-5.463V3m0 61v-.91' id='Combined-Shape' transform='rotate(-45 33.5 33.5)' />
          <path d='M60 33.5H7m-6 0H0m67 0h-1' id='Combined-Shape' />
        </g>
        <path
          d='M27 95c0-8.42-5.58-14-14-14 8.42 0 14-5.582 14-14 0 8.418 5.58 14 14 14-8.42 0-14 5.58-14 14z'
          id='burst-1'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154 152c0-8.42-5.58-14-14-14 8.42 0 14-5.582 14-14 0 8.418 5.58 14 14 14-8.42 0-14 5.58-14 14z'
          id='burst-2'
          stroke='currentColor'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
);
