import { UseQueryOptions, useQuery } from 'react-query';
import { getCommunitiesRatesheet } from '../utils/api';
import { CommunitiesRatesheetResponse } from '../types/communitiesRatesheet';

const getCommunitiesRatesheetKey = (parameters: Parameters<typeof getCommunitiesRatesheet>[0]) => [
  'communitiesRatesheet',
  JSON.stringify(parameters),
];

export default (
  parameters: Parameters<typeof getCommunitiesRatesheet>[0],
  options?:
    | Omit<
        UseQueryOptions<CommunitiesRatesheetResponse, unknown, CommunitiesRatesheetResponse, string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) =>
  useQuery(
    getCommunitiesRatesheetKey(parameters),
    async () => {
      const { data } = await getCommunitiesRatesheet(parameters);
      return data;
    },
    options
  );
