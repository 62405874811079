import React from 'react';
import { Col, Currency, Email, Icon, Phone, Row, TextInput } from '@hippo/components';
import { Field } from 'formik';

import { Label, SmallText } from './style';

import FormikField from '../../components/Form/FormikField';
import Box from '../../components/box/Box';
import { WrappedCheckbox } from '../../components/WrappedCheckbox';

interface LoanInfoProps {
  noLoanInformation?: boolean;
}

export const LoanInfo: React.FC<LoanInfoProps> = ({ noLoanInformation }) => (
  <>
    <Row>
      <Col size={[9 / 12, 10 / 12, 10 / 12]}>
        <Label marginBottom={0}>Additional Information (Optional)</Label>
        <Box flexDirection='row' marginY='20px'>
          <Field
            name='has_no_loan'
            size={24}
            component={WrappedCheckbox}
            label={
              <SmallText marginLeft='8px' forwardedAs='span'>
                I will not have a mortgage
              </SmallText>
            }
          />
        </Box>

        {noLoanInformation ? null : (
          <>
            <Row>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Sale price of home'
                  name='sales_price'
                  component={Currency}
                  textSize={[24, 24, 28]}
                />
              </Col>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Original loan amount'
                  name='loan_amount'
                  component={Currency}
                  textSize={[24, 24, 28]}
                />
              </Col>
            </Row>
            <Row>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Mortgagee/lender company'
                  name='company'
                  component={TextInput}
                  textSize={[24, 24, 28]}
                />
              </Col>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Mortgage contact name'
                  name='contact_name'
                  component={TextInput}
                  textSize={[24, 24, 28]}
                />
              </Col>
            </Row>
            <Row>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Mortgage contact phone'
                  name='contact_phone'
                  component={Phone}
                  textSize={[24, 24, 28]}
                />
              </Col>
              <Col size={[1, 1, 6 / 12]}>
                <FormikField
                  label='Mortgage contact email'
                  name='contact_email'
                  component={Email}
                  textSize={[24, 24, 28]}
                />
              </Col>
            </Row>
          </>
        )}
      </Col>
      <Col size={[3 / 12, 2 / 12, 2 / 12]} first={[false, true, true]}>
        <Icon icon={'Documents'} size={['64px', '64px', '80px']} />
      </Col>
    </Row>
  </>
);
