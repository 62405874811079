import React, { ReactNode } from 'react';
import { Col, device, Row, Typography } from '@hippo/components';
import { CollapsedBarContainer, TextOverflow } from './style';
import { WhenNotShown } from '../coverages/styles';
import Box from '../../components/box/Box';
import { PremiumRange } from '../../reducers/consent/types';
import { getPremiumRangeValue } from '../../utils/getPremiumRangeValue';
import styled from 'styled-components';

export const communityInfoOnPanelId = (name: string) => `CommunityInfoOnPanel-${name}`;

type CommunityInfoOnPanelProps = {
  name: string;
  value?: string;
};

const StyledCol = styled(Col)`
  ${device.mobile} {
    padding-left: 0;
  }
`;

const CommunityInfoOnPanel: React.FC<CommunityInfoOnPanelProps> = ({ name, value }) => (
  <StyledCol size={[1, 1, 4 / 12]} data-testid={communityInfoOnPanelId(name)}>
    <Typography type={['body5', 'body5', 'body5']} color='gray600'>
      {name}
    </Typography>
    <TextOverflow type={['body5', 'body5', 'body4']} color={'white'}>
      <span title={value}>{value || ''}</span>
    </TextOverflow>
  </StyledCol>
);

type BasicCoverageInformationBarProps = {
  carrier: { display_name: string } | undefined;
  panelInfo: {
    address: string;
    community: string;
    model: string;
    modelSqft: string;
    premium: string;
    premiumMonthly: string;
  };
  premiumRange: PremiumRange | undefined;
  ctaButton: ReactNode;
  isDesktop: boolean;
};
export const BasicCoverageInformationBar: React.FC<BasicCoverageInformationBarProps> = ({
  panelInfo,
  carrier,
  premiumRange,
  ctaButton,
  isDesktop,
}) => (
  <CollapsedBarContainer>
    <Row paddingTop={'20px'} paddingLeft={'40px'} around={true}>
      <Col size={[1, 1, 7 / 12]} paddingTop={'15px'}>
        <Row paddingBottom={'20px'}>
          <Typography type={['heading7', 'heading7', 'heading6']} color={'white'}>
            {panelInfo.address}
          </Typography>
        </Row>
        <Row>
          {panelInfo.community ? <CommunityInfoOnPanel name={'COMMUNITY'} value={panelInfo.community} /> : null}
          {panelInfo.model ? <CommunityInfoOnPanel name={'MODEL'} value={panelInfo.model} /> : null}
          {panelInfo.modelSqft ? <CommunityInfoOnPanel name={'HOUSE SIZE'} value={panelInfo.modelSqft} /> : null}
        </Row>
      </Col>
      <WhenNotShown mobileNotShown={true} tabletNotShown={true}>
        <Col size={2 / 12}>
          <Box borderLeft={`1px solid`} borderColor='gray600' height={'120px'} />
        </Col>
      </WhenNotShown>

      <Col size={[1, 1, 3 / 12]} paddingTop={'15px'}>
        <Row paddingBottom={[0, 0, '10px']} paddingTop={['16px', '16px', 0]}>
          <Typography type={['body5', 'body5', 'body6']} color='gray600' textAlign={'left'}>
            PREMIUM
          </Typography>
        </Row>
        <Row>
          {premiumRange ? (
            <Typography type={['display7', 'display7', 'display6']} color={'white'}>
              <sup>$</sup>
              {getPremiumRangeValue(premiumRange.min, premiumRange.max)}
            </Typography>
          ) : (
            <>
              <Typography type={['display7', 'display7', 'heading3']} color={'white'}>
                <sup>$</sup>
                {panelInfo.premiumMonthly}
              </Typography>
              <Typography type='body4' color={['gray600', 'gray600', 'white']} paddingTop={'20px'}>
                <sup>/mo ($</sup>
                {panelInfo.premium}
                <sub>/yr)*</sub>
              </Typography>
            </>
          )}
        </Row>
        <Row>
          <Typography
            type={['body5', 'body5', 'body4']}
            color={['white', 'white', 'gray600']}
            paddingTop={'5px'}
            data-testid='carrier'
          >
            Insurer: {carrier?.display_name || '-'}
          </Typography>
        </Row>
      </Col>
    </Row>
    {!isDesktop && <>{ctaButton}</>}
  </CollapsedBarContainer>
);
