import { ModalNames } from '../../types/modals';

type ContentsProps = {
  title: string;
  texts: string;
};

export type EligibilityDataRowProps = {
  iconName: HippoComponents.IconType;
  title: string;
  contents: Array<ContentsProps>;
  modalName?:
    | ModalNames.SecondHomeModal
    | ModalNames.SwimmingPoolModal
    | ModalNames.DogsModal
    | ModalNames.ExoticPetsModal;
  fieldName: string;
  tooltips?: string;
  rowIndex?: number;
};

export type EligibilityDataProps = {
  title: string;
  subtitle: string;
  subNote: string;
  disclaimer: string;
  fields: Array<EligibilityDataRowProps>;
};

const fields: Array<EligibilityDataRowProps> = [
  {
    fieldName: 'non_owner_occupied',
    iconName: 'Construction',
    title: 'Non-owner Occupied',
    contents: [
      {
        title: '',
        texts: 'Is this a secondary home, rental property, or vacant?',
      },
    ],
    modalName: ModalNames.SecondHomeModal,
  },
  {
    fieldName: 'business_on_site',
    iconName: 'SuitcaseP',
    title: 'Business with customers on site',
    contents: [
      {
        title: '',
        texts:
          'Do you have any business with foot traffic into your home (e.g. hairdresser, counselor, daycare)? Does not include home offices.',
      },
    ],
  },
  {
    fieldName: 'swimming_pool',
    iconName: 'FrPool',
    title: 'Swimming Pool',
    contents: [
      {
        title: '',
        texts: 'Do you have a swimming pool?',
      },
    ],
    modalName: ModalNames.SwimmingPoolModal,
  },
  {
    fieldName: 'trampoline',
    iconName: 'Trampoline',
    title: 'Trampoline',
    contents: [
      {
        title: '',
        texts: 'Do you have a trampoline?',
      },
    ],
  },
  {
    fieldName: 'dogs',
    iconName: 'Dogs',
    title: 'Dogs',
    contents: [
      {
        title: '',
        texts: 'Do you or a member of your household have a dog?',
      },
    ],
    modalName: ModalNames.DogsModal,
  },
  {
    fieldName: 'exotic_pets',
    iconName: 'ExoticPet',
    title: 'Exotic pets',
    contents: [
      {
        title: '',
        texts:
          'Do you or a member of your household own any exotic pets, animals with bite/injury history, or livestock which reside at the property?',
      },
    ],
    modalName: ModalNames.ExoticPetsModal,
  },
];

export const eligibilityData = {
  title: 'Just a few more questions',
  subtitle: 'Please review each item below and check all that apply:',
  fields: fields,
  disclaimer:
    'I agree that if I submit an application for insurance, the information I provide on this site must be true and correct',
};
