import { Dictionary } from 'types';

export const extraCoveragesName: Dictionary<string> = {
  equipment_breakdown: 'Equipment Breakdown',
  limited_animal_liability: 'Limited Animal Liability and Excluded for Certain Breeds and/or Bite History',
  pprc: 'Personal Property Replacement Cost',
  service_line: 'Service Line',
  water_backup: 'Water Backup Coverage',
  earthquake: 'Earthquake',
  sinkhole_collapse: 'Sinkhole Collapse',
  screened_enclosure: 'Screened Enclosure',
  catastrophic_ground_collapse: 'Catastrophic Ground Collapse',
  loss_assessment: 'Loss Assessment',
  ordinance_or_law: 'Increased Ordinance or Law Coverage',
};
