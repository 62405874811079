import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FLOWS, PAGES } from 'globals/routes';
import { PageType } from 'helpers/enums/pageType';
import { hasHmac } from '../utils/hasHmac';

import { CheckoutPage } from 'pages/checkout/CheckoutPage';
import EligibilityPage from 'pages/eligibility/Eligibility';
import LandingPage from 'pages/landing/Landing';
import SuccessPage from 'pages/success/Sucess';

const ConsentFlow: FC = () => {
  if (!hasHmac()) {
    return <Redirect to={'/404'} />;
  }

  return (
    <Switch>
      <Route path={`${FLOWS.consent}/${PAGES.welcome}`} component={LandingPage} />
      <Route
        path={`${FLOWS.consent}/${PAGES.eligibility}`}
        component={() => <EligibilityPage pageType={PageType.Consent} />}
      />
      <Route path={`${FLOWS.consent}/${PAGES.checkout}`} render={() => <CheckoutPage pageType={PageType.Consent} />} />
      <Route path={`${FLOWS.consent}/${PAGES.success}`} component={() => <SuccessPage pageType={PageType.Consent} />} />
      <Redirect to={'/404'} />
    </Switch>
  );
};
export default ConsentFlow;
