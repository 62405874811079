import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';

type Props = FlexboxProps & SpaceProps & LayoutProps & BorderProps & PositionProps & ColorProps & TextAlignProps;

const styledSystem = compose(border, color, flexbox, layout, position, space, textAlign);

/**
 * @deprecated flexDirection and boxSizing should not be set, now it's impossible to change it. Use Flex component instead
 */
const Box = styled.div<Props>(
  {
    display: 'flex',
    flexDirection: 'column', // TODO remove this property!
    boxSizing: 'border-box',
  },
  styledSystem
);

export default Box;
