import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'reducers/createRootReducer';
import { Col, Grid, Row, Typography } from '@hippo/components';
import Box from 'components/box/Box';
import { FooterContainer } from 'components/footer/Footer';
import DeprecatedLogo from 'components/deprecatedLogo/DeprecatedLogo';
import PageContainer from 'components/PageContainer/PageContainer';
import PageTitle from 'components/PageTitle/PageTitle';
import { PageType } from 'helpers/enums/pageType';
import { useWhiteLabel } from 'helpers/use-white-label';

import { lennarColorConfig, ThemeProviderOverride } from '../../components/ThemeProviderOverride';
import { isLennar } from '../../helpers/organization-mapping';
import { successId } from './successId';

const Success: React.FC<{ pageType: PageType }> = () => {
  const { agent, whiteLabel, orgName } = useSelector((state: State) => ({
    agent: state.consent.data ? state.consent.data.agent : null,
    whiteLabel: state.consent.data ? state.consent.data.white_label : null,
    orgName: state.consent.data?.organization_name,
  }));

  const { pageData } = useWhiteLabel(orgName);

  return (
    <ThemeProviderOverride orgName={orgName}>
      <PageContainer backgroundColor={isLennar(orgName) ? lennarColorConfig.primary50 : lennarColorConfig.gray50}>
        <Grid>
          <Row>
            <Col paddingBottom={['15px']}>
              <Box flexBasis={[72, 72, 144]} justifyContent='center' alignItems='center'>
                <DeprecatedLogo logos={pageData?.general} />
              </Box>
            </Col>
          </Row>
          <Row>
            <Col alignItems='center' justifyContent='center' size={[1, 10 / 12, 8 / 12]} offset={[0, 1 / 12, 2 / 12]}>
              <PageTitle data-testid={successId.pageTitle}>Thank You!</PageTitle>
              <Typography type={['heading8', 'heading7', 'heading6']} textAlign='center' marginX={3}>
                Thank you for submitting your application. You will be contacted by{' '}
                {whiteLabel ? whiteLabel.agency_name : 'an agent'} shortly.
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col
              alignItems='center'
              justifyContent='center'
              size={[10 / 12, 8 / 12, 6 / 12]}
              offset={[1 / 12, 2 / 12, 3 / 12]}
            >
              <Typography
                type={['body6', 'body5', 'body5']}
                textAlign='center'
                marginX={3}
                marginY={3}
                color={'gray700'}
              >
                <p>
                  We will coordinate directly with your lender and will provide them with the policy declarations and
                  invoice when they are ready for it (usually thirty days before closing). If you will have an escrow
                  account, the policy premium will be coordinated by your lender and is included in closing costs. If
                  you are not financing or setting up an escrow account, your agent will contact you thirty days prior
                  to your projected closing date to confirm and issue the policy. Payment of premium can be made at that
                  time.
                </p>
                <p>
                  <br />
                  If you have questions or changes (such as updated lender or closing date), please reach out to:
                </p>
              </Typography>
              {agent && (
                <Typography type={['heading9']} textAlign='center'>
                  <p>
                    <strong>{agent.agent_name}</strong>
                  </p>
                  <p>
                    <strong>{agent.agent_phone}</strong>
                  </p>
                </Typography>
              )}
            </Col>
          </Row>
        </Grid>
        <FooterContainer />
      </PageContainer>
    </ThemeProviderOverride>
  );
};

export default Success;
