import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { emptyErrorObj } from 'helpers/errors/emptyErrorObj';

import { fetchWhiteLabelDataAsync } from '../../actions/whiteLabel';

import { WhiteLabelState } from './types';

const initialState: WhiteLabelState = { data: undefined };

const reducer = reducerWithInitialState(initialState)
  .case(fetchWhiteLabelDataAsync.started, (state) => ({
    ...state,
    error: emptyErrorObj,
    isFetched: false,
    isFetching: true,
  }))
  .case(fetchWhiteLabelDataAsync.failed, (state, { error }) => {
    return {
      ...state,
      error,
      isFetching: false,
    };
  })
  .case(fetchWhiteLabelDataAsync.done, (state: WhiteLabelState, { result }) => ({
    ...state,
    data: result,
    isFetched: true,
    isFetching: false,
  }));

export default reducer;
