import styled from 'styled-components';
import { Switch as HippoSwitch } from '@hippo/components';

// In the design, the designer (Will) deliberately changed the styles of Button and Switch to
// differentiate the white label from other Hippo products.
// I’ve used the standard hippo components but overridden the styles to match the design.
// https://projects.invisionapp.com/d/main?origin=v7#/console/17999751/377022091/preview?scrollOffset=315
// It was decided this was a better approach than having too many options in the components themselves here:
// https://bitbucket.org/hippo-inc/hbo-consent-flow/pull-requests/160/adding-new-style-button-and-switch/diff?w=1
const Switch = styled(HippoSwitch)<HippoComponents.StyledSwitchCheckedProps>`
  margin-bottom: 10px;
  border: 3px solid ${(p) => (p.checked ? p.theme.colors.primary500 : p.theme.colors.gray100)};
  transition: border 200ms ease;
  border-radius: 8px;
  padding-top: 0;
  margin-top: 9px;
  width: 95px;

  // N and Y labels
  div:nth-child(1) {
  }
  // track
  div:nth-child(2) {
    border-radius: 8px;
    height: 47px;
    margin-bottom: -10px;
  }
  // cover
  div:nth-child(3) {
    border-radius: 8px;
  }
`;

export default Switch;
