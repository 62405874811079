import { Col, Grid, Row, Typography } from '@hippo/components';
import { FooterContainer } from 'components/footer/Footer';
import DeprecatedLogo from 'components/deprecatedLogo/DeprecatedLogo';
import PageContainer from 'components/PageContainer/PageContainer';
import React, { useCallback, useState } from 'react';
import Box from 'components/box/Box';
import { CoverageColLeft } from 'pages/coverages/components/ColLeft';
import { CoverageColRight } from 'pages/coverages/components/ColRight';
import { WhenNotShown } from 'pages/coverages/styles';
import { useWhiteLabel } from 'helpers/use-white-label';
import { PageType } from 'helpers/enums/pageType';

import { HeroImage, HeroTint } from './style';
import getCoveragesFormattedData, { getRightPremiumPriceData, policyTypes } from '../coverages/coveragesData';
import useCarriers from '../../helpers/useCarriers';
import useGetConsent from '../../helpers/useGetConsent';
import useQuoteId from '../../helpers/use-quote-id';
import { useLocation } from 'react-router';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { AgentInfo } from '../coverages/components/AgentInfo';
import { AgencyInfo } from '../coverages/components/AgencyInfo';
import { GetMyPolicyButton } from '../coverages/components/GetMyPolicyButton';
import { useLandingPageCTAText } from '../../experiments/hooks/useLandingPageCTAText';

const QuoteHeroImage: React.FC<{ organizationName: OrgNameMapping | undefined }> = ({ organizationName }) => {
  const [tintTransparent, setTintTransparent] = useState(false);
  const handleImageLoad = useCallback(() => {
    !tintTransparent && setTintTransparent(true);
  }, [tintTransparent]);

  const { pageData } = useWhiteLabel(organizationName);

  return (
    <Box position='absolute' top={0} left={0} right={0} height={[0, 400, 548]}>
      <HeroImage
        width={'100%'}
        height={'100%'}
        onLoad={handleImageLoad}
        srcSet={pageData && pageData.quote.hero_image}
      />
      <HeroTint transparent={tintTransparent} />
    </Box>
  );
};

const CoverageInformation: React.FC = () => {
  const consent = useGetConsent();

  const {
    communityName,
    street,
    street2,
    city,
    state,
    zip,
    firstName,
    lastName,
    modelName,
    modelType,
    modelSize,
    organizationName,
  } = {
    street: consent.data?.property_information.street || '',
    street2: consent.data?.property_information.street2 || '',
    city: consent.data?.property_information.city || '',
    state: consent.data?.property_information.state || '',
    zip: consent.data?.property_information.zip || '',
    firstName: consent.data?.checkout.personal_information.first_name || '',
    lastName: consent.data?.checkout.personal_information.last_name || '',
    communityName: consent.data?.community.name || '',
    modelName: consent.data?.model.name || '',
    modelType: consent.data?.model.is_condo ? 'Condo' : 'House',
    modelSize: consent.data?.model.sqft || '',
    organizationName: consent.data?.organization_name,
  };

  const { pageData } = useWhiteLabel(organizationName);

  return (
    <>
      <Row flex={1}>
        <Col
          offset={[2 / 12, 2 / 12, 1 / 12]}
          size={[8 / 12, 8 / 12, 7 / 12]}
          alignItems={['center', 'center', 'flex-start']}
          justifyContent='center'
        >
          <DeprecatedLogo isWhite={[false, false, true]} logos={pageData?.general} />
        </Col>
      </Row>
      <Row flex={1}>
        <Col offset={[1 / 12, 1 / 12, 1 / 12]} size={[10 / 12, 10 / 12, 5 / 12]} paddingTop={4}>
          <Typography type={['heading7', 'heading4', 'heading4']} color={['primary500', 'primary500', 'white']}>
            Smart coverage for
          </Typography>
          <Typography type={['heading7', 'heading4', 'heading4']} color={['primary500', 'primary500', 'white']}>
            {firstName} {lastName}
          </Typography>
          <Typography type={'heading7'} color={['gray700', 'gray700', 'white']} paddingTop={4}>
            {street} {street2}
          </Typography>
          <Typography type={'heading7'} color={['gray700', 'gray700', 'white']}>
            {city}, {state} {zip}
          </Typography>
          <Box borderTop='1px solid' borderColor='primary500' marginTop={4} marginBottom={4} />
        </Col>
      </Row>
      <Row flex={1}>
        <Col offset={[1 / 12, 1 / 12, 1 / 12]} size={[3 / 12, 2 / 12, 2 / 12]}>
          <Typography type={['allCaps3', 'allCaps2', 'allCaps2']} color={['primary500', 'gray700', 'gray100']}>
            Community
          </Typography>
        </Col>
        <Col size={[8 / 12, 2 / 12, 2 / 12]}>
          <Typography type={'body5'} color={['gray700', 'gray700', 'white']}>
            {communityName}
          </Typography>
        </Col>
      </Row>
      <Row flex={1}>
        <Col offset={[1 / 12, 1 / 12, 1 / 12]} size={[3 / 12, 2 / 12, 2 / 12]}>
          <Typography type={['allCaps3', 'allCaps2', 'allCaps2']} color={['primary500', 'gray700', 'gray100']}>
            Model
          </Typography>
        </Col>
        <Col size={[8 / 12, 2 / 12, 2 / 12]}>
          <Typography type={'body5'} color={['gray700', 'gray700', 'white']}>
            {modelName}
          </Typography>
        </Col>
      </Row>
      <Row flex={1}>
        <Col offset={[1 / 12, 1 / 12, 1 / 12]} size={[3 / 12, 2 / 12, 2 / 12]}>
          <Typography type={['allCaps3', 'allCaps2', 'allCaps2']} color={['primary500', 'gray700', 'gray100']}>
            Type
          </Typography>
        </Col>
        <Col size={[8 / 12, 2 / 12, 2 / 12]}>
          <Typography type={'body5'} color={['gray700', 'gray700', 'white']}>
            {modelType}
          </Typography>
        </Col>
      </Row>
      <Row flex={1}>
        <Col offset={[1 / 12, 1 / 12, 1 / 12]} size={[3 / 12, 2 / 12, 2 / 12]}>
          <Typography type={['allCaps3', 'allCaps2', 'allCaps2']} color={['primary500', 'gray700', 'gray100']}>
            Size
          </Typography>
        </Col>
        <Col size={[8 / 12, 2 / 12, 2 / 12]}>
          <Typography type={'body5'} color={['gray700', 'gray700', 'white']}>
            {modelSize} sq ft
          </Typography>
        </Col>
      </Row>
    </>
  );
};

const Quote: React.FC<{ pageType: PageType }> = ({ pageType }) => {
  const consent = useGetConsent();
  const carriers = useCarriers();
  const quoteId = useQuoteId();
  const { search } = useLocation();

  const landingPageCTAText = useLandingPageCTAText(true);

  const formattedCoveragesData = consent.data ? getCoveragesFormattedData(consent.data) : null;

  const agent = consent.data?.agent;
  const quote = consent.data?.quote;
  const carrier = carriers.data?.find(({ name }) => name === consent.data?.quote?.carrier);
  const isCarrierHippo = carrier?.name === 'hippo';

  return (
    <PageContainer backgroundColor={'primary50'}>
      {/* HIP-13624 - minHeight - 900 because landing page does not look good when browser height is small */}
      <Box position='relative' width={'100%'} minHeight={900} marginBottom={[0, 4]}>
        <WhenNotShown mobileNotShown={true} tabletNotShown={true}>
          <QuoteHeroImage organizationName={consent.data?.organization_name} />
        </WhenNotShown>
        <Box flex={1} position='relative'>
          <Grid>
            <CoverageInformation />
            <Row>
              <Col offset={[0 / 12, 0 / 12, 0 / 12]} size={[1, 1, 1]} marginY={'40px'} className={'__print_page_main'}>
                <Box backgroundColor={'white'} borderRadius={'10px'}>
                  <Row margin={[0, 0, 0]}>
                    <CoverageColLeft coverageRowsData={formattedCoveragesData?.coverageRowsData || null} />
                    {consent.data ? (
                      <CoverageColRight
                        pageType={pageType}
                        agencyInfo={
                          <AgencyInfo
                            isHippo={isCarrierHippo}
                            insurer={carrier?.display_name ?? ''}
                            reference={consent.data.reference_id}
                            policyType={quote ? policyTypes[quote.product] ?? '' : ''}
                          />
                        }
                        agentInfo={
                          agent ? (
                            <AgentInfo name={agent.agent_name} email={agent.agent_email} phone={agent.agent_phone} />
                          ) : null
                        }
                        premium={getRightPremiumPriceData(consent.data.quote.premium)}
                        quoteLink={consent.data.coverages.quote_doc_link}
                        ctaButton={
                          pageType !== PageType.Static ? (
                            <Box marginBottom={2} marginTop={4} className={'__no_print'} textAlign={'center'}>
                              <GetMyPolicyButton
                                pageType={pageType}
                                smartHomeKit={consent.data?.smart_home_kit || false}
                                isCarrierHippo={isCarrierHippo}
                                quoteId={quoteId}
                                search={search}
                                buttonSize='medium'
                              >
                                {landingPageCTAText}
                              </GetMyPolicyButton>
                            </Box>
                          ) : null
                        }
                      />
                    ) : null}
                  </Row>
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>

      <FooterContainer />
    </PageContainer>
  );
};

export default Quote;
