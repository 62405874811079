import { ErrorObjProps } from 'helpers/errors/errorTypes';
import { State } from 'reducers/createRootReducer';
import { Dispatch } from 'redux';
import fsa from 'typescript-fsa';

import { errorHandler } from '../helpers/errors/errorHandler';
import hbo from '../utils/api';
import { WhiteLabelResponse } from '../reducers/white-label/types';

const actionCreator = fsa('WHITE_LABEL');

export const fetchWhiteLabelDataAsync = actionCreator.async<null, WhiteLabelResponse, ErrorObjProps>('FETCH_DATA');

export const fetchWhiteLabelData = (orgName?: string) => async (dispatch: Dispatch, getState: () => State) => {
  const { whiteLabel } = getState();

  if (!whiteLabel.isFetching && orgName) {
    dispatch(fetchWhiteLabelDataAsync.started(null));

    try {
      const { data } = await hbo.get<WhiteLabelResponse>(`/v1/white-label?org=${orgName}`);
      dispatch(
        fetchWhiteLabelDataAsync.done({
          params: null,
          result: {
            ...data,
            licenses: data.licenses || [],
          },
        })
      );
    } catch (error: any) {
      errorHandler({ error, errorType: 'quoteDataFetchError', dispatch });
    }
  }
};
