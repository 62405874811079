import { Col, Phone, Row, TextInput, Typography } from '@hippo/components';
import Switch from 'components/Switch/Switch';
import { useState } from 'react';
import { AsyncAwareButton } from 'components/AsyncAwareButton';
import { Form, Formik } from 'formik';
import FormikField from 'components/Form/FormikField';
import * as yup from 'yup';
import { getSearchParam } from '../../utils/getSearchParam';
import { createLead } from '../../utils/api';
import { CreateLeadErrorResponse, CreateLeadRequest, LeadDuplicationReason } from '../../types/api/createLead';
import { useHistory } from 'react-router';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { PageType } from '../../helpers/enums/pageType';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { PAGES } from '../../globals/routes';

export const errorMessage = {
  sameAddressAndEmail: 'A lead with the same property address and email already exists. Unable to create lead',
  sameJobAndEmail: 'A lead with the same job ID and email already exists. Unable to create lead',
  sameJobAndEmailAndAddress:
    'A lead with the same property address, job ID and email already exists. Unable to create lead',
  defaultMessage: 'Lead with this data already exists',
};

const mapDuplicationReasonToErrorMessage = (duplicationReason: LeadDuplicationReason | null): string => {
  switch (duplicationReason) {
    case LeadDuplicationReason.sameAddressAndEmail:
      return errorMessage.sameAddressAndEmail;
    case LeadDuplicationReason.sameJobAndEmail:
      return errorMessage.sameJobAndEmail;
    case LeadDuplicationReason.sameJobAndEmailAndAddress:
      return errorMessage.sameJobAndEmailAndAddress;

    case null:
      return errorMessage.defaultMessage;
  }
};

const requiredText = 'Required field';
const specialCharactersText = 'Special characters are not allowed';
const invalidPhoneText = 'Invalid phone number';
const invalidEmailText = 'Invalid email address';

const specialCharactersRegex = /^((-|\s)*[A-z])*$/;

const hasAdditionalInsurance = 'hasAdditionalInsurance';

const contactInformationSchema = yup.object({
  firstName: yup.string().nullable().matches(specialCharactersRegex, specialCharactersText).required(requiredText),
  lastName: yup.string().nullable().matches(specialCharactersRegex, specialCharactersText).required(requiredText),
  email: yup.string().nullable().email(invalidEmailText).required(requiredText),
  phoneNumber: yup.string().min(10, invalidPhoneText).required(requiredText),
  additionalInsuresFirstName: yup.string().when(hasAdditionalInsurance, {
    is: true,
    then: yup.string().nullable().matches(specialCharactersRegex, specialCharactersText).required(requiredText),
    otherwise: yup.string().nullable(),
  }),
  additionalInsuranceLastName: yup.string().when(hasAdditionalInsurance, {
    is: true,
    then: yup.string().nullable().matches(specialCharactersRegex, specialCharactersText).required(requiredText),
    otherwise: yup.string().nullable(),
  }),
  additionalInsuranceEmail: yup.string().when(hasAdditionalInsurance, {
    is: true,
    then: yup.string().nullable().email(invalidEmailText).required(requiredText),
    otherwise: yup.string().nullable(),
  }),
  additionalInsurancePhoneNumber: yup.string().when(hasAdditionalInsurance, {
    is: true,
    then: yup.string().min(10, invalidPhoneText).required(requiredText),
    otherwise: yup.string().nullable(),
  }),
});

export type QuoteContactInfoForm = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInsuresFirstName?: string;
  additionalInsuranceLastName?: string;
  additionalInsuranceEmail?: string;
  additionalInsurancePhoneNumber?: string;
  hasAdditionalInsurance: boolean;
};

const formInitialState: QuoteContactInfoForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  hasAdditionalInsurance: false,
  additionalInsuresFirstName: '',
  additionalInsuranceLastName: '',
  additionalInsuranceEmail: '',
  additionalInsurancePhoneNumber: '',
};

export const ContactInfo = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const modelId = getSearchParam(window.location.search, 'modelId') || '';
  const communityId = getSearchParam(window.location.search, 'communityId') || '';
  const state = getSearchParam(window.location.search, 'state') || '';
  const city = getSearchParam(window.location.search, 'city') || '';
  const street = getSearchParam(window.location.search, 'street') || '';
  const zipCode = getSearchParam(window.location.search, 'zipCode') || '';

  const onContinue = (contactInformation: QuoteContactInfoForm) => {
    setIsLoading(true);

    const additionalInsureds = contactInformation.hasAdditionalInsurance
      ? [
          {
            first_name: contactInformation.additionalInsuresFirstName,
            last_name: contactInformation.additionalInsuranceLastName,
            email: contactInformation.additionalInsuranceEmail,
            phone_number: contactInformation.additionalInsurancePhoneNumber,
          },
        ]
      : [];
    const payload: CreateLeadRequest = {
      contact_information: {
        first_name: contactInformation.firstName,
        last_name: contactInformation.lastName,
        email: contactInformation.email,
        phone_number: contactInformation.phoneNumber,
      },
      property_information: {
        property_address_state: state,
        property_address_city: city,
        model_id: modelId,
        community_id: communityId,
        property_address_street: street,
        property_address_street2: '',
        property_address_zip_code: zipCode,
      },
      additional_insureds: additionalInsureds,
    };

    createLead(payload)
      .then((result) => {
        const consentUrl = result.data.consentUrl;
        if (consentUrl) {
          window.location.href = consentUrl.replace(PAGES.welcome, PAGES.eligibility);
        } else {
          history.push(`/thank-you?org=${OrgNameMapping.Lennar}`);
        }
      })
      .catch((e) => {
        const error = e as AxiosError<CreateLeadErrorResponse>;

        if (error.response?.data?.status === 409) {
          const leadDuplicates = error.response.data.context?.leadDuplicates;
          const duplicationReason = leadDuplicates.length > 0 ? leadDuplicates[0].duplicationReason : null;
          const errorMessage = mapDuplicationReasonToErrorMessage(duplicationReason);

          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('There was an error trying to save the changes', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: [],
  });

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={whiteLabel.data?.licenses ?? []}
      landingPagePath={generateLandingPageUrl(PageType.Referral)}
      pages={whiteLabel.data?.pages}
    >
      <Row>
        <Col mt='40px' mb='40px'>
          <Typography type='heading4' color='black' textAlign='center'>
            Please enter your contact information
          </Typography>
        </Col>
      </Row>

      <Formik
        validationSchema={contactInformationSchema}
        initialValues={formInitialState}
        onSubmit={onContinue}
        enableReinitialize={true}
      >
        {({ isValid, dirty, values }) => (
          <Form>
            <Row paddingTop='40px'>
              <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
                <FormikField label='First name' name='firstName' component={TextInput} />
              </Col>
              <Col size={[1, 1, 4 / 12]}>
                <FormikField label='Last name' name='lastName' component={TextInput} />
              </Col>
            </Row>
            <Row>
              <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
                <FormikField label='Email' name='email' component={TextInput} />
              </Col>
              <Col size={[1, 1, 4 / 12]}>
                <FormikField label='Phone number' name='phoneNumber' component={Phone} />
              </Col>
            </Row>
            <Row>
              <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
                <Typography type='heading7'>Secondary Insured</Typography>
                <Typography type='body5'>Do you have a secondary insured to added to your policy? </Typography>
              </Col>
              <Col size={[1, 1, 2 / 12]} offset={[0, 0, 2 / 12]}>
                <FormikField component={Switch} colorType='dark' name='hasAdditionalInsurance' size='small' />
              </Col>
            </Row>
            {values.hasAdditionalInsurance ? (
              <>
                <Row>
                  <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
                    <FormikField label='First name' name='additionalInsuresFirstName' component={TextInput} />
                  </Col>
                  <Col size={[1, 1, 4 / 12]}>
                    <FormikField label='Last name' name='additionalInsuranceLastName' component={TextInput} />
                  </Col>
                </Row>
                <Row>
                  <Col size={[1, 1, 4 / 12]} offset={[0, 0, 2 / 12]}>
                    <FormikField label='Email' name='additionalInsuranceEmail' component={TextInput} />
                  </Col>
                  <Col size={[1, 1, 4 / 12]}>
                    <FormikField label='Phone number' name='additionalInsurancePhoneNumber' component={Phone} />
                  </Col>
                </Row>
              </>
            ) : null}
            <Row paddingTop='110px'>
              <Col size={[1, 1, 5 / 12]} offset={[0, 0, 3.5 / 12]}>
                <AsyncAwareButton
                  showLoader={isLoading}
                  styleType='primary'
                  size={['small', 'large', 'large']}
                  disabled={isLoading || !isValid || !dirty}
                >
                  See quote
                </AsyncAwareButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </LennarSearchWrapper>
  );
};
