import { Col, Icon, IconButton, Row, TextArea, Typeahead, Typography } from '@hippo/components';
import Switch from 'components/Switch/Switch';
import Box from 'components/box/Box';
import React from 'react';
import { Dog, DogBreed } from 'reducers/consent/types';
import { isMobile } from 'react-device-detect';

interface DogsRowProps {
  dog: Dog;
  index: number;
  breeds?: Array<DogBreed>;
  updateDog: (dog: Dog, index: number) => void;
  onDelete: () => void;
  showDelete?: boolean;
}

const DogsRow: React.FC<DogsRowProps> = (props) => {
  const handleChangeField = (e: HippoComponents.OnChange<{ name: string; value: string } | string | boolean>) => {
    props.updateDog({ ...props.dog, [e.name]: e.value }, props.index);
  };

  const handleChangeTextarea =
    (name: string) => (e: HippoComponents.OnChange<{ name: string; value: string } | string | boolean>) =>
      handleChangeField({ ...e, name });

  const getBreedId = (breedDisplay: string) => {
    if (props.breeds) {
      const breed = props.breeds.find((v) => v.display === breedDisplay);
      return breed ? breed.id : '';
    }
    return '';
  };

  const getDisplayBreed = (breedId?: string) => {
    if (props.breeds) {
      const breed = props.breeds.find((v) => v.id === breedId);
      return breed ? breed.display : '';
    }
    return '';
  };

  const handleChangeBreed = (e: HippoComponents.OnChange<HippoComponents.DropdownItemListItemValue>) => {
    props.updateDog({ ...props.dog, [e.name]: getBreedId(e.value.toString()) }, props.index);
  };

  const handleChangeMix = (e: HippoComponents.OnChange<HippoComponents.DropdownItemListItemValue>) => {
    props.updateDog({ ...props.dog, [e.name]: getBreedId(e.value.toString()) }, props.index);
  };

  const breedOptions = props.breeds ? props.breeds.map((v) => v.display) : [];

  return (
    <Box paddingX={3} paddingY={4}>
      <Box flexDirection={['column', 'row', 'row']} alignItems={['left', 'center', 'center']}>
        <Box flex={1} paddingX={2}>
          <Typography type={['allCaps1']} color={'gray900'}>
            Dog {props.index + 1}
          </Typography>
        </Box>

        <Box flex={3} paddingTop={3} paddingX={2}>
          <Typeahead
            itemList={breedOptions}
            label='Breed'
            name={`breed`}
            value={getDisplayBreed(props.dog.breed)}
            onChange={handleChangeBreed}
          />
        </Box>
        <Box flex={3} paddingTop={3} paddingX={2}>
          <Typeahead
            itemList={breedOptions}
            focusedLabel={'Mixed with (optional)'}
            unfocusedLabel={'Mixed with'}
            name={`mix`}
            onChange={handleChangeMix}
            value={getDisplayBreed(props.dog.mix)}
          />
        </Box>
        <Box flex={2} paddingX={2} flexDirection={['row', 'column', 'column']} justifyContent={'space-between'}>
          <Typography type={['body4']} paddingY={[3, 0, 0]}>
            Have bite history?
          </Typography>
          <Switch
            colorType={'dark'}
            name={`biteHistory`}
            checked={props.dog.biteHistory || false}
            onChange={handleChangeField}
            size={'small'}
          />
        </Box>
        {!isMobile ? (
          <Box flex={1}>{props.showDelete && <IconButton icon={'Delete'} onClick={props.onDelete} />}</Box>
        ) : null}
      </Box>
      {props.dog.biteHistory && (
        <Box alignItems='stretch'>
          <TextArea onChange={handleChangeTextarea(`comment`)} placeholder={'Please describe'}>
            {props.dog.comment}
          </TextArea>
        </Box>
      )}
      {isMobile ? (
        <Box paddingX={2} paddingTop={'15px'}>
          <button onClick={props.onDelete}>
            {props.showDelete && (
              <Row alignItems={'center'}>
                <Col size={24 / 300}>
                  <Icon icon={'Delete'} size={'24px'} />
                </Col>
                <Col paddingLeft={'5px'}>
                  <Typography type={'allCaps3'} color={'gray600'} textAlign={'left'}>
                    Remove dog
                  </Typography>
                </Col>
              </Row>
            )}
          </button>
        </Box>
      ) : null}
    </Box>
  );
};

export default DogsRow;
