export const checkoutId = {
  firstName: 'checkoutFirstName',
  lastName: 'checkoutLastName',
  email: 'checkoutEmail',
  propertyAddressStreet: 'checkoutPropertyAddress',
  city: 'checkoutCity',
  state: 'checkoutState',
  zipCode: 'checkoutZipCode',
  customerApprovalCheckbox: 'checkoutFormCustomerApproval',
  submitButton: 'checkoutFormSubmit',
  insurerName: 'checkoutInsurerName',
  modelName: 'checkoutModelName',
  modelSqft: 'checkoutModelSqft',
  coverageA: 'checkoutCoverageA',
  premiumMonth: 'checkoutPremiumMonth',
  premiumYear: 'checkoutPremiumYear',
  continueButton: 'checkoutContinueButton',
  summaryError: 'checkoutSummaryError',
};
