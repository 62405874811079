import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { State } from '../../reducers/createRootReducer';
import { ModalProps } from '../../types/modals';
import { DeprecatedAllModals } from '../../modals/modals';
import { closeModal } from '../../actions/modals';

const ModalController: FC = () => {
  const { currentModal, isOpen } = useSelector((state: State) => state.modal);
  const dispatch = useDispatch<ThunkDispatch<State, void, AnyAction>>();

  if (currentModal) {
    const {
      modalName,
      afterClose,
      onEscapeKeydown,
      onBackgroundClick,
      closeOnEsc,
      closeOnBackgroundClick,
      ...modalProps
    } = currentModal;
    const ModalComponent = DeprecatedAllModals[modalName] as FC<ModalProps>;

    // Modify after close to dispatch close modal
    const modifiedAfterClose = () => {
      dispatch(closeModal());
      if (afterClose) {
        afterClose();
      }
    };

    const newModalProps = {
      ...modalProps,
      isOpen: isOpen,
      afterClose: modifiedAfterClose,
      onBackgroundClick: onBackgroundClick,
      onEscapeKeydown: onEscapeKeydown,
    };

    // TODO: Move this functionality to Hippo Components Modal
    // Allow boolean to close modal on esc key press or background click
    // Modals will close back default on background click and esc keypress
    if (closeOnEsc !== false) {
      newModalProps.onEscapeKeydown = (event) => {
        modifiedAfterClose();
        if (onEscapeKeydown) {
          onEscapeKeydown(event);
        }
      };
    }

    if (closeOnBackgroundClick !== false) {
      newModalProps.onBackgroundClick = (event) => {
        modifiedAfterClose();
        if (onBackgroundClick) {
          onBackgroundClick(event);
        }
      };
    }
    const modalKey = `${modalName}-${new Date().getTime()}`;
    return (
      <React.Suspense fallback={null}>
        <ModalComponent key={modalKey} {...newModalProps} />
      </React.Suspense>
    );
  }
  return null;
};

export default ModalController;
