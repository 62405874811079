import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { saveAsync } from '../../actions/consent';

import { isValidErrors } from './isValidErrors';
import { errorFormatter } from './errorFormatter';
import { ErrorObjProps } from './errorTypes';
import { toast } from 'react-toastify';

type ErrorHandlerProps = {
  error: any;
  errorType: string;
  quoteId?: string;
  dispatch: Dispatch;
};

export const errorHandler = ({ error, errorType, quoteId, dispatch }: ErrorHandlerProps) => {
  const errors = error?.response?.data?.errors || [];
  const errorStatusCode = error?.response?.status ?? 0;

  if (errorStatusCode) {
    if (errorStatusCode === 422 && isValidErrors(errors) && quoteId) {
      const errorObj = errorFormatter({ errors, errorType }) as ErrorObjProps;
      dispatch(saveAsync.failed({ params: { quoteId }, error: errorObj }));
    } else if (errorStatusCode === 404) {
      dispatch(push(`/404`));
    }

    const message = errors?.[0]?.msg;

    if (message) {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
};
