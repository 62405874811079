import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@hippo/components';

import { OrgNameMapping } from '../helpers/enums/organization';

export const lennarColorConfig: HippoComponents.ColorConfig = {
  ...theme.colors,
  primary50: '#EEF6F6',
  primary100: '#E7EDEF',
  primary300: '#45778C',
  primary500: '#005DAA',
  primary600: '#005DAA',
  primary700: '#002335',
  white: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#E1E1E1',
  gray200: '#BFBFBF',
  gray500: '#989898',
  gray600: '#7F7F7F',
  gray700: '#404040',
  gray800: '#1F1F1F',
  gray900: '#000000',
  black: '#000000',
  shadow50: '#0000000F',
  shadow100: '#0000001A',
  shadow500: '#0000009A',
  success: '#00AD64',
  warning: '#E1BC29',
  error: '#BF0E08',
  simplisafeBlue: '#005DAA',
  contrastingBrandColor: '#FFFFFF',
  supplementBrandColor: '#005DAA',
};

export const khovColorConfig: HippoComponents.ColorConfig = {
  ...theme.colors,
  primary50: '#EEF6F6',
  primary100: '#E7EDEF',
  primary300: '#003C4C',
  primary500: '#005DAA',
  primary600: '#005DAA',
  primary700: '#002335',
  white: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#E1E1E1',
  gray200: '#BFBFBF',
  gray500: '#989898',
  gray600: '#7F7F7F',
  gray700: '#404040',
  gray800: '#1F1F1F',
  gray900: '#000000',
  black: '#000000',
  shadow50: '#0000000F',
  shadow100: '#0000001A',
  shadow500: '#0000009A',
  success: '#00AD64',
  warning: '#E1BC29',
  error: '#BF0E08',
  simplisafeBlue: '#005DAA',
  contrastingBrandColor: '#FFFFFF',
  supplementBrandColor: '#005DAA',
};

export const mattamyColorConfig: HippoComponents.ColorConfig = khovColorConfig;

export const defaultColorConfig: HippoComponents.ColorConfig = {
  ...theme.colors,
  primary50: '#EEF6F6',
  primary100: '#E7EDEF',
  primary300: '#45778C',
  primary500: '#114B5F',
  primary600: '#0C3645',
  primary700: '#002335',
  white: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#E1E1E1',
  gray200: '#BFBFBF',
  gray500: '#989898',
  gray600: '#7F7F7F',
  gray700: '#404040',
  gray800: '#1F1F1F',
  gray900: '#000000', // For backward compatibility
  black: '#000000',
  shadow50: '#0000000F',
  shadow100: '#0000001A',
  shadow500: '#0000009A',
  success: '#00AD64',
  warning: '#E1BC29',
  error: '#BF0E08',
  simplisafeBlue: '#114B5F',
  contrastingBrandColor: '#FFFFFF',
  supplementBrandColor: '#114B5F',
};

export const hbiaColorConfig: HippoComponents.ColorConfig = {
  ...theme.colors,
  primary50: '#EEF6F6',
  primary100: '#E7EDEF',
  primary300: '#028115',
  primary500: '#028115',
  primary600: '#007000',
  primary700: '#005A00',
  white: '#FFFFFF',
  gray50: '#F6F6F6',
  gray100: '#E1E1E1',
  gray200: '#BFBFBF',
  gray500: '#989898',
  gray600: '#7F7F7F',
  gray700: '#404040',
  gray800: '#1F1F1F',
  gray900: '#000000', // For backward compatibility
  black: '#000000',
  shadow50: '#0000000F',
  shadow100: '#0000001A',
  shadow500: '#0000009A',
  success: '#00AD64',
  warning: '#E1BC29',
  error: '#BF0E08',
  simplisafeBlue: '#114B5F',
  contrastingBrandColor: '#FFFFFF',
  supplementBrandColor: '#114B5F',
};

export const tollColorConfig = hbiaColorConfig;

type ThemeProviderProps = {
  orgName?: OrgNameMapping;
};

const getColorTheme = (orgName: OrgNameMapping) => {
  type ColorConfigMap = { [key in OrgNameMapping]: HippoComponents.ColorConfig };
  const colorsMap: ColorConfigMap = {
    [OrgNameMapping.Default]: defaultColorConfig,
    [OrgNameMapping.Lennar]: lennarColorConfig,
    [OrgNameMapping.Toll]: defaultColorConfig,
    [OrgNameMapping.Khov]: khovColorConfig,
    [OrgNameMapping.Mattamy]: mattamyColorConfig,
    [OrgNameMapping.Hbia]: hbiaColorConfig,
  };

  return colorsMap[orgName] || defaultColorConfig;
};

export const mapTheme = (orgName: OrgNameMapping) => {
  const themeOverride = { ...theme };

  themeOverride.colors = getColorTheme(orgName);
  themeOverride.fontFamily = 'Source Sans Pro';
  return themeOverride;
};

export const ThemeProviderOverride: React.FC<ThemeProviderProps> = ({ children, orgName }) => (
  <ThemeProvider theme={mapTheme(orgName || OrgNameMapping.Lennar)}>
    <div>{children}</div>
  </ThemeProvider>
);
