import { Dictionary } from 'types';

const errorMessages: Dictionary<string> = {
  'error.age.number.min': 'You must be 21 to enter.',
  'error.income.number.min': 'You must make at least $500/month',
  'error.phone_number.string.pattern.base': 'You must put valid phone number',
  'error.breed.any.required': 'You missed breed, please put valid breed name',
  'error.phone_number.string.base': 'Please provide a phone number',
};

const genericMessages: Dictionary<string> = {
  'error.string.empty': 'Required',
  'error.number.base': 'Please put valid numbers',
  'error.object.unknown': 'Please put valid values',
  'error.string.email': 'Please provide a valid email address',
};

type GetErrorMessagesProps = {
  type: string;
  key: string;
};

export const getErrorMessages = ({ type, key }: GetErrorMessagesProps) => {
  return errorMessages[`error.${key}.${type}`] || genericMessages[`error.${type}`];
};
