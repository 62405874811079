import { CommunityData, ModelData, QuoteData } from '../../reducers/referral/types';
import { ConsentData } from '../../reducers/consent/types';
import { getCoverageRowsData } from '../coverages/coveragesData';
import { formatCurrency } from '../../helpers/formatters';
import { Carrier } from '../../types/Carriers';
import { DeductibleTypes, RowProps } from '../coverages/type';

export const coverageLeftInfo = (model: ModelData | undefined, quote: QuoteData | undefined) => {
  const coverage = {
    a: quote?.coverage_a?.toString() || '',
    b: quote?.coverage_b?.toString() || '',
    c: quote?.coverage_c?.toString() || '',
    d: quote?.coverage_d?.toString() || '',
    e: quote?.coverage_e?.toString() || '',
    f: quote?.coverage_f?.toString() || '',
    pprc: quote?.pprc || '',
    deductible: quote?.deductible || '',
    deductible_amount: '',
    deductible_type: DeductibleTypes.DOLLARS,
    hurricane_deductible: quote?.hurricane_deductible || '',
    hurricane_deductible_amount: '',
    hurricane_deductible_type: DeductibleTypes.DOLLARS,
    wind_deductible: quote?.wind_deductible || '',
    wind_deductible_amount: '',
    wind_deductible_type: DeductibleTypes.DOLLARS,
    wildfire_deductible: quote?.wildfire_deductible || '',
    wildfire_deductible_amount: '',
    wildfire_deductible_type: DeductibleTypes.DOLLARS,
    ordinance_or_law_display: '',
    extended_rebuilding_cost: '',
    equipment_breakdown: quote?.equipment_breakdown || '',
    water_backup: quote?.water_backup || '',
    service_line: quote?.service_line || '',
    foundation: model?.FoundationType || '',
    limited_animal_liability: quote?.limited_animal_liability || '',
    quote_doc_link: quote?.quote_doc_link || '',
    sinkhole_collapse: quote?.sinkhole_collapse || false,
  };

  return getCoverageRowsData(coverage);
};

export const referralFlowPanelInfo = (
  community?: CommunityData,
  modelName?: string,
  model?: ModelData,
  quote?: QuoteData
) => {
  const modelSqft = model?.sqft;
  const premium = quote?.premium;
  const street = community?.street ? `${community?.street},` : '';
  const city = community?.city ? `${community?.city},` : '';
  return {
    address: `${street}${city}${community?.state || ''}`,
    community: community?.community_name || '',
    model: `${modelName || ''}`,
    modelSqft: modelSqft ? `${parseInt(modelSqft, 10).toLocaleString()} sq ft` : '',
    premium: premium ? formatCurrency(premium, '') : '',
    premiumMonthly: premium ? formatCurrency((+`${premium}` / 12).toFixed(0), '') : '',
  };
};

export const consentFlowPanelInfo = (consentData: ConsentData | null) => {
  const modelSqftOnQuote = consentData?.model?.sqft?.toString() || '';
  const premiumOnQuote = consentData?.quote?.premium;
  const streetOnQuote = consentData?.property_information?.street
    ? `${consentData?.property_information?.street}, `
    : '';
  const cityOnQuote = consentData?.property_information?.city ? `${consentData?.property_information?.city}, ` : '';
  return {
    address: `${streetOnQuote}${cityOnQuote}${consentData?.property_information.state || ''}`,
    community: consentData?.community.name || '',
    model: consentData?.model.name || '',
    modelSqft: modelSqftOnQuote ? `${parseInt(modelSqftOnQuote, 10).toLocaleString()} sq ft` : '',
    premium: premiumOnQuote ? formatCurrency(premiumOnQuote, '') : '',
    premiumMonthly: premiumOnQuote ? formatCurrency((+`${premiumOnQuote}` / 12).toFixed(0), '') : '',
  };
};

export interface CollapsedBarContent {
  panelInfo: {
    address: string;
    community: string;
    model: string;
    modelSqft: string;
    premium: string;
    premiumMonthly: string;
  };
  coverageLeftInfo: RowProps[] | null;
  carrier: Carrier | undefined;
}

export const collapsedBarContent: CollapsedBarContent = {
  panelInfo: {
    address: '',
    community: '',
    model: '',
    modelSqft: '',
    premium: '',
    premiumMonthly: '',
  },
  coverageLeftInfo: [],
  carrier: undefined,
};
