import styled from 'styled-components';

export const DogContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: ${(p) => p.theme.colors.gray600} 1px solid;
`;

export const AddRowUI = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  > *:first-child {
    margin-right: 8px;
  }
`;
