import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { closeModalAction, openModalAction, openQueuedModalAction, queueModalAction } from '../../actions/modals';
import { ModalNames } from '../../types/modals';
interface ModalOptions {
  modalName: ModalNames;
  afterOpen?: () => void;
  afterClose?: () => void;
  beforeOpen?: Promise<void> | (() => void);
  beforeClose?: Promise<void> | (() => void);
  onEscapeKeydown?: (event: Event) => void;
  onBackgroundClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  closeOnEsc?: boolean;
  closeOnBackgroundClick?: boolean;
}

export interface ModalState {
  queue: Array<ModalOptions>;
  isOpen: boolean;
  currentModal: ModalOptions | null;
}

const reducer = reducerWithInitialState<ModalState>({
  queue: [],
  isOpen: false,
  currentModal: null,
})
  .case(openModalAction, (state, modalOptions) => ({
    ...state,
    isOpen: true,
    currentModal: modalOptions,
  }))
  .case(closeModalAction, (state) => ({
    ...state,
    isOpen: false,
    currentModal: null,
  }))
  .case(queueModalAction, (state, modalOptions) => ({
    ...state,
    queue: [...state.queue, modalOptions],
  }))
  .case(openQueuedModalAction, (state) => ({
    ...state,
    currentModal: state.queue[0],
    queue: state.queue.slice(1),
    isOpen: true,
  }));

export default reducer;
