import { Col, Grid, Row, Typography } from '@hippo/components';
import { useState } from 'react';
import { Flex } from '../../components/Flex';
import { CommunityRow } from '../../components/CommunityRow';
import { Arrow } from '../../components/ArrowButton';
import { lennarColorConfig } from '../../components/ThemeProviderOverride';
import { Divider } from '../../components/Divider';
import { getCommunityListPath } from '../../helpers/paths';
import { Link, useRouteMatch } from 'react-router-dom';
import useCommunitiesMetadata from '../../helpers/useCommunitiesMetadata';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { Spinner } from '../../components/Spinner';
import { LennarSearchInput } from '../../components/LennarSearchInput';
import { OrgNameMapping } from '../../helpers/enums/organization';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';

const specialCharactersRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
const normalizeText = (text: string | null | undefined) =>
  text?.replaceAll(' ', '').replaceAll(specialCharactersRegex, '').toLowerCase();

type SearchableList = Array<{
  display: string | null | undefined;
}>;

export function filterList<List extends SearchableList>(text: string, list: List) {
  if (!text) return list;

  const normalizedDivision = normalizeText(text) ?? '';

  return list.filter(({ display }) => normalizeText(display)?.toLowerCase().includes(normalizedDivision)) as List;
}

export const DivisionList = () => {
  const match = useRouteMatch<{ divisionId: string }>();
  const divisionId = match.params.divisionId || '';

  const [division, setDivision] = useState(divisionId);
  const metadata = useCommunitiesMetadata();

  const filteredDivisions = filterList(division, metadata.data?.divisions ?? []);

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: [],
  });

  return (
    <LennarSearchWrapper
      pages={whiteLabel.data?.pages}
      builder={whiteLabel.data?.builder}
      licenses={[]}
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
    >
      <Grid style={{ width: '100%' }} flex={1}>
        <Row paddingTop='30px'>
          <Col>
            <Typography type='heading4' color='black' textAlign='center'>
              Let&apos;s find your community
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography key='text' type='body4' color='gray600' textAlign='center' mt='3'>
              Select the division or search to narrow the list.
            </Typography>
          </Col>
        </Row>
        <Flex mt='26px' />
        <LennarSearchInput
          label='Division'
          name='division'
          value={division}
          onChange={setDivision}
          showResetButton={false}
          onResetButtonClick={() => setDivision('')}
        />

        {metadata.isLoading === false && filteredDivisions.length === 0 && (
          <Flex width='100%' justifyContent='center' mt='50px'>
            <Typography type='body4' color='gray600'>
              No results for the “{division}”
            </Typography>
          </Flex>
        )}

        {metadata.isLoading && (
          <Flex width='100%' justifyContent='center' mt='50px' flex='1' alignItems='center'>
            <Spinner />
          </Flex>
        )}

        {filteredDivisions.map((division) => (
          <Col offset={[0, 2 / 12, 2 / 12]} size={[1, 8 / 12, 8 / 12]} key={division.value}>
            <Link
              to={getCommunityListPath(division.value ?? '')}
              style={{ textDecoration: 'none' }}
              data-testid='community-link'
            >
              <CommunityRow py='12px'>
                <Col size={1}>
                  <Typography type='body4' color='primary500' textAlign='left'>
                    {division.display}
                  </Typography>

                  <Arrow fill={lennarColorConfig.primary500} />
                </Col>
              </CommunityRow>
              <Divider />
            </Link>
          </Col>
        ))}
      </Grid>
    </LennarSearchWrapper>
  );
};
