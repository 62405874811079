import { UseQueryOptions, useQuery } from 'react-query';
import { getCommunities } from '../utils/api';
import { CommunitiesPayload, CommunitiesResponse } from '../types/communities';

const getCommunitiesKey = (parameters: Parameters<typeof getCommunities>[0]) => [
  'communities',
  JSON.stringify(parameters),
];

export default (
  parameters: Parameters<typeof getCommunities>[0],
  options?:
    | Omit<
        UseQueryOptions<CommunitiesResponse, unknown, CommunitiesResponse, (string | CommunitiesPayload)[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) =>
  useQuery(
    getCommunitiesKey(parameters),
    async () => {
      const { data } = await getCommunities(parameters);
      return data;
    },
    options
  );
