import { Col, device, Row } from '@hippo/components';
import styled from 'styled-components';

const Sub = styled.sub`
  font-size: 16px;
  opacity: 0.5;
`;

const Sup = styled.sup`
  vertical-align: super;
  font-size: 16px;
  opacity: 0.5;
`;

const CoveragePriceTableTr = styled.td`
  text-align: left;
`;

const RowWithBorder = styled(Row)`
  border: 1em solid transparent;
  flex-direction: column;
`;

const HippoImg = styled.img`
  padding: 26px 0px;
`;

const BorderRadiusCol = styled(Col)`
  ${device.mobile} {
    border-radius: 0 0 10px 10px;
  }

  ${device.tablet} {
    border-radius: 0 0 10px 10px;
  }

  ${device.desktop} {
    border-radius: 10px 0 0 10px;
  }
`;

interface WhenNotShownProps {
  mobileNotShown?: boolean;
  tabletNotShown?: boolean;
  desktopNotShown?: boolean;
}

const displayNone = 'display:none;';
/**
 * @deprecated use useResponsive instead
 */
const WhenNotShown = styled.div<WhenNotShownProps>`
  ${device.mobile} {
    ${(p) => p.mobileNotShown && displayNone};
  }

  ${device.tablet} {
    ${(p) => p.tabletNotShown && displayNone};
  }

  ${device.desktop} {
    ${(p) => p.desktopNotShown && displayNone};
  }
`;

export { CoveragePriceTableTr, RowWithBorder, WhenNotShown, Sub, Sup, HippoImg, BorderRadiusCol };
