import React, { ReactNode } from 'react';
import { Typography } from '@hippo/components';

interface TitleProps {
  color?: HippoComponents.Color;
  children?: ReactNode;
}

const Title: React.FC<TitleProps> = (props) => (
  <Typography textAlign={'center'} type={'heading4'} color={props.color}>
    {props.children}
  </Typography>
);

export default Title;
