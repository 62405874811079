import { Col, Row, Typography } from '@hippo/components';
import { Arrow, ArrowLink } from 'components/ArrowButton';
import { lennarColorConfig } from 'components/ThemeProviderOverride';
import { Divider } from 'components/Divider';
import { CommunityRow } from 'components/CommunityRow';
import { useDebouncedState } from '@mantine/hooks';

import { ITEMS_PER_ROW } from './GetQuote';
import useCommunities from '../../helpers/useCommunities';
import { LennarSearchInput } from '../../components/LennarSearchInput';
import { Flex } from '../../components/Flex';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { removeDuplicates } from '../../utils/removeDuplicates';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { getSearchParam } from '../../utils/getSearchParam';
import { getQuotePath } from '../../helpers/paths';
import { Link } from 'react-router-dom';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { generateLandingPageUrl } from '../../components/deprecatedLogo/DeprecatedLogo';
import { PageType } from '../../helpers/enums/pageType';
import { Spinner } from '../../components/Spinner';

export const CommunityList = () => {
  const [communityName, setCommunityName] = useDebouncedState(
    getSearchParam(window.location.search, 'communityName') ?? '',
    400
  );

  const page = parseInt(getSearchParam(window.location.search, 'page') || '0');
  const zipCode = getSearchParam(window.location.search, 'zipCode');
  const communityList = useCommunities(
    {
      zip: zipCode ?? '',
      limit: ITEMS_PER_ROW,
      offset: page * ITEMS_PER_ROW,
      searchName: communityName || undefined,
    },
    { enabled: Boolean(zipCode) }
  );
  const count = communityList.data?.count ?? 0;
  const pagesCount = Math.floor((count - 1) / ITEMS_PER_ROW);
  const state = removeDuplicates(communityList.data?.rows.map(({ state }) => state) ?? []);

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: state,
  });

  const noResults = communityList.isLoading === false && count === 0;

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={(communityList.data?.rows.length ?? 0) > 0 ? whiteLabel.data?.licenses ?? [] : []}
      landingPagePath={generateLandingPageUrl(PageType.Referral)}
      pages={whiteLabel.data?.pages}
    >
      <Row>
        <Col mt='40px'>
          <Typography type='heading4' color='black' textAlign='center'>
            Select your community
          </Typography>
        </Col>
      </Row>

      <Row>
        <Col mt='3'>
          <Typography key='text' type='body4' color='gray600' textAlign='center'>
            Here are communities in the{' '}
            <Typography type='body4' color='primary500' textAlign='center' as='span'>
              &quot;{zipCode}&quot;
            </Typography>{' '}
            zip code
          </Typography>
        </Col>
      </Row>

      <Flex mt='26px' />
      <LennarSearchInput
        label='Community'
        name='community'
        value={communityName}
        onChange={setCommunityName}
        showResetButton={noResults}
        onResetButtonClick={() => {
          setCommunityName('');
        }}
      />

      {noResults && (
        <Flex width='100%' justifyContent='center' mt='50px'>
          <Typography type='body4' color='gray600'>
            No results for the “{communityName}”
          </Typography>
        </Flex>
      )}

      {communityList.isLoading ? (
        <Row center={true} pt='56px' pb='12px'>
          <Spinner />
        </Row>
      ) : (
        <Row>
          <Col size={[1, 1, 8 / 12]} offset={[0, 0, 2 / 12]}>
            <>
              {communityList.data?.rows.map((community) => (
                <Link
                  to={getQuotePath({
                    zipCode: zipCode ?? '',
                    communityId: community.id,
                  })}
                  key={community.id}
                  style={{ textDecoration: 'none' }}
                >
                  <CommunityRow py='12px'>
                    <Col size={4.5 / 12}>
                      <Typography type='body4' color='primary500' textAlign='left'>
                        {community.community_name}
                      </Typography>
                    </Col>
                    <Col size={7.5 / 12}>
                      <Typography type='body5' color='gray600' textAlign='left'>
                        {community.street ? `${community.street},` : null}{' '}
                        {community.city ? `${community.city},` : null} {community.state} {community.zip}{' '}
                      </Typography>
                    </Col>
                    <Arrow fill={lennarColorConfig.primary500} />
                  </CommunityRow>
                  <Divider />
                </Link>
              ))}
            </>
          </Col>
        </Row>
      )}

      {count > 50 && (
        <Row pt='10px'>
          <Col size={10 / 12} flexDirection='row' justifyContent='flex-end' alignItems='center'>
            <Typography type='body6' color='gray800' textAlign='center'>
              Page {page + 1} of {pagesCount + 1}
            </Typography>

            <ArrowLink
              direction='left'
              disabled={page === 0}
              to={getQuotePath({
                zipCode: zipCode ?? '',
                page: (page - 1).toString(),
              })}
              data-testid='previous-page'
            />
            <ArrowLink
              disabled={pagesCount === page}
              to={getQuotePath({
                zipCode: zipCode ?? '',
                page: (page + 1).toString(),
              })}
              data-testid='next-page'
            />
          </Col>
        </Row>
      )}
    </LennarSearchWrapper>
  );
};
