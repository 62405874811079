import { fetchWhiteLabelData } from 'actions/whiteLabel';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'reducers/createRootReducer';
import { WhiteLabelPages, WhiteLabelResponse } from 'reducers/white-label/types';
import _isEqual from 'lodash/isEqual';
import { organizationImages } from '../utils/organizationImages';
import { OrgNameMapping } from './enums/organization';

export const getImages = (orgName: OrgNameMapping) => {
  const images = organizationImages[orgName];
  return {
    logo_default: images.logoDefault,
    logo_white: images.logoWhite,
    logo_mobile: images.logoMobile,
  };
};

/**
 * @deprecated use "useWhiteLabel" hook from "helpers/useWhiteLabel.ts"
 */
export const useWhiteLabel = (orgName?: OrgNameMapping) => {
  const { builder, pages, licenses } = useSelector<
    State,
    { builder?: WhiteLabelResponse['builder']; pages?: WhiteLabelPages; licenses: WhiteLabelResponse['licenses'] }
  >((state) => ({
    builder: state.whiteLabel.data ? state.whiteLabel.data.builder : undefined,
    pages: state.whiteLabel.data ? state.whiteLabel.data.pages : undefined,
    licenses: state.whiteLabel.data && state.whiteLabel.data.licenses ? state.whiteLabel.data.licenses : [],
  }));

  const dispatch = useDispatch();

  const [pageData, setPageData] = useState<WhiteLabelPages>();
  const [builderData, setBuilderData] = useState<WhiteLabelResponse['builder']>({
    agency_name: '',
    copyright: '',
    license_name: '',
  });

  const [licensesData, setLicensesData] = useState<WhiteLabelResponse['licenses']>([]);
  useEffect(() => {
    if (pages && builder && licenses) {
      setPageData(pages);
      setBuilderData(builder);
      setLicensesData((prevState) => (!_isEqual(prevState, licenses) ? licenses : prevState));
    } else {
      dispatch(fetchWhiteLabelData(orgName));
    }
  }, [dispatch, orgName, pages, builder, licenses]);

  const images = organizationImages[orgName ?? OrgNameMapping.Default];

  const pageDataWithImages: WhiteLabelPages | undefined = useMemo(
    () =>
      pageData
        ? {
            ...pageData,
            general: {
              logo_default: images.logoDefault,
              logo_white: images.logoWhite,
              logo_mobile: images.logoMobile,
            },
            quote: {
              hero_image: images.hero,
            },
          }
        : undefined,
    [pageData, images]
  );

  return {
    pageData: pageDataWithImages,
    builderData,
    licensesData,
  };
};
