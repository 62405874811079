import { UseQueryOptions, useQuery } from 'react-query';
import { WhiteLabelPayload, WhiteLabelResponse } from '../types/whiteLabel';
import { getWhiteLabel } from '../utils/api';

export const whiteLabelKey = (organizationId: string, states?: string) => ['WhiteLabel', organizationId, states];

export default (
  payload: WhiteLabelPayload,
  queryOptions?: Omit<
    UseQueryOptions<WhiteLabelResponse, unknown, WhiteLabelResponse, (string | undefined)[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery(
    whiteLabelKey(payload.org, payload.state?.join(' ')),
    async () => {
      const { data } = await getWhiteLabel(payload);
      return data;
    },
    queryOptions
  );
};
