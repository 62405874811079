import { rgba } from 'polished';
import styled from 'styled-components';

export const HeroImage = styled.img`
  position: absolute;
  object-fit: cover;
  z-index: 0;
`;

export const HeroTint = styled.div<{ transparent?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme, transparent }) =>
    transparent ? rgba(theme.colors.gray900, 0.5) : theme.colors.gray900};
  transition: background-color 1s ease-in-out;
  z-index: 0;
`;
