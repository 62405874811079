import React, { useCallback } from 'react';
import { Col, Icon, Row, Typography } from '@hippo/components';
import { isMobile } from 'react-device-detect';

import { BasicNameValueObj, CoverageRowsDataProps, DeductibleTypes } from '../type';

import { AdditionalCoverageList } from './AdditionalCoverageList';
import CoveragePriceTableBox from './PriceTableBox';
import { ButtonLink } from '../../../components/ButtonLink';
import Box from '../../../components/box/Box';
import { openModal } from '../../../actions/modals';
import { ModalNames } from '../../../types/modals';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '../../../types';
import { understandingCoveragesData } from '../../../modals/coverages/understandingPlanData';
import { push } from 'connected-react-router';
import { getCoverageExplanationUrl } from '../../../helpers/paths';
import { Flex } from '../../../components/Flex';
import PriceBox from '../../../components/PriceBox/PriceBox';
import { useResponsive } from '../../../helpers/useResponsive';

const components = {
  list: AdditionalCoverageList,
  table: CoveragePriceTableBox,
};

const WithAccordion: React.FC<{
  type: 'list' | 'table';
  valueObjArray: Array<BasicNameValueObj>;
}> = ({ type, valueObjArray }) => {
  const SpecificComponent = components[type];
  return <SpecificComponent valueObjArray={valueObjArray} />;
};

const CoverageRow: React.FC<CoverageRowsDataProps> = ({ value, index }) => {
  const { left, right } = value;
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isDesktop } = useResponsive();

  const { isPhone } = useResponsive();

  const handleUnderstandingClick = useCallback(() => {
    if (isPhone) {
      const coverageSlug = understandingCoveragesData[index].slug;
      dispatch(push(getCoverageExplanationUrl(coverageSlug)));
    } else {
      dispatch(openModal({ modalName: ModalNames.CoverageModal, clickedIndex: index }));
    }
  }, [dispatch, index, isPhone]);

  return (
    <Row
      paddingLeft={['20px', '20px', '70px']}
      paddingRight={['20px', '20px', '60px']}
      paddingBottom={['0px', '0px', '5px']}
      justifyContent={['between', 'between', 'start']}
    >
      <Col size={[1, 10 / 12, 5 / 12]} data-testid={left.titles}>
        <Row reverse={false} flexDirection='row' paddingLeft='0px' flexWrap='nowrap'>
          <ButtonLink onClick={handleUnderstandingClick}>
            {isMobile ? (
              <Col alignItems='flex-end' className='__no_print'>
                <Icon icon={left.iconName} size={64} />
              </Col>
            ) : (
              <Icon icon={left.iconName} size={64} className='__no_print' />
            )}
          </ButtonLink>

          <Flex className='__coverage_item_and_price' flexDirection='column' paddingTop='10px' paddingLeft='15px'>
            <ButtonLink onClick={handleUnderstandingClick}>
              <Flex flexDirection='column'>
                {left.titles.map((t, i) => (
                  <Typography type='allCaps3' color='primary500' textAlign='left' key={i}>
                    {t}
                  </Typography>
                ))}
              </Flex>
            </ButtonLink>

            {left.price ? (
              left.priceType === DeductibleTypes.PERCENTAGE ? (
                <Typography color='gray600' type='body2'>
                  {left.price}%
                </Typography>
              ) : (
                Number.isInteger(+left.price) && <PriceBox price={left.price} color='gray600' type='body2' />
              )
            ) : null}
          </Flex>
          {!isDesktop ? (
            <Col>
              <Box paddingY={2} className='__no_print' textAlign='end'>
                <ButtonLink type='button' onClick={handleUnderstandingClick} data-testid='learn_more'>
                  <Typography textAlign='end' type='allCaps3'>
                    Learn more
                  </Typography>
                </ButtonLink>
              </Box>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col size={[1, 10 / 12, 6 / 12]} offset={[0, 0, 1 / 12]}>
        {/* We are manually setting the type for now for a hot fix,
                    need to figure out a way to dynamically pick the component type
                  */}
        <WithAccordion type={right.name === 'coverage details' ? 'list' : 'table'} valueObjArray={right.value} />
      </Col>
      {isDesktop ? (
        <Col>
          <Box paddingY={2} className='__no_print' textAlign='end'>
            <ButtonLink type='button' onClick={handleUnderstandingClick} data-testid='learn_more'>
              <Typography textAlign='end' type='allCaps3'>
                Learn more
              </Typography>
            </ButtonLink>
          </Box>
        </Col>
      ) : null}
    </Row>
  );
};

export default CoverageRow;
