import styled from 'styled-components';
import { Row } from '@hippo/components';

export const CommunityRow = styled(Row)`
  position: relative;
  transition: background-color 300ms linear;
  cursor: pointer;

  .arrow {
    opacity: 0;
    transition: opacity 300ms linear, transform 300ms linear;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translate(0px, -50%);
  }

  * {
    text-decoration: none;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary100};

    .arrow {
      opacity: 1;
      transform: translate(15px, -50%);
    }
  }
`;
