import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import consentReducer from './consent/consent';
import { ConsentState } from './consent/types';
import whiteLabelReducer from './white-label/whiteLabel';
import { WhiteLabelState } from './white-label/types';
import modalReducer, { ModalState } from './modals/modals';
import { referralModelReducer } from './referral/referralModelReducer';
import { referralQuoteReducer } from './referral/referralQuoteReducer';
import { ReferralModelState, ReferralQuoteState } from './referral/types';

export interface State {
  router: RouterState;
  consent: ConsentState;
  whiteLabel: WhiteLabelState;
  modal: ModalState;
  referral: {
    quote: ReferralQuoteState;
    model: ReferralModelState;
  };
}

export const createRootReducer: (history: History) => Reducer = (history) =>
  combineReducers<State>({
    router: connectRouter(history),
    consent: consentReducer,
    whiteLabel: whiteLabelReducer,
    modal: modalReducer,
    referral: combineReducers<State['referral']>({
      quote: referralQuoteReducer,
      model: referralModelReducer,
    }),
  });
