import { ConsentData, Model } from 'reducers/consent/types';
import { Dictionary } from 'types';

import getArrayFromObj from '../../helpers/obj-to-array';
import { Coverages } from '../../reducers/consent/types';
import { extraCoveragesName } from './coveragesName';

import { DeductibleTypes, RowProps } from './type';

type ModelInfo = Model & {
  communityName: string;
};

interface PropertyInformation {
  state: string;
  city: string;
  street: string;
  street2: string;
  zip: string;
}

const getAddressData = (address: PropertyInformation, propertyInfo: ModelInfo) => {
  return {
    left: {
      name: 'PROPERTY ADDRESS',
      value: getArrayFromObj({
        obj: address,
        exception: [''],
        chunkRule: { 1: true, 4: true },
      }),
    },
    right: {
      name: 'HOME INFORMATION',
      value: getArrayFromObj({
        obj: propertyInfo,
        exception: [''],
        chunkRule: { 0: true, 1: true, 3: true },
      }),
    },
  };
};

const getPresentExtraCoverages = (coverages: Record<string, string | undefined | boolean>) => {
  const keys = Object.keys(extraCoveragesName);

  const extraCoverages: Array<{ name: string; value: any }> = [];

  keys.forEach((key) => {
    const coveragesKey = key as keyof Coverages;

    if (coverages[coveragesKey] && coverages[coveragesKey] !== null) {
      extraCoverages.push({
        name: extraCoveragesName[key],
        value: coverages[coveragesKey],
      });
    }
  });

  return extraCoverages;
};

const convertCoverageValue = (type: DeductibleTypes, amount: string) => {
  if (type === DeductibleTypes.PERCENTAGE) {
    return `${amount}%`;
  } else if (type === DeductibleTypes.DOLLARS) {
    return amount;
  }
  return null;
};

type GetCoverageRowsDataParams = {
  a: Coverages['a'];
  b: Coverages['b'];
  c: Coverages['c'];
  d: Coverages['d'];
  e: Coverages['e'];
  f: Coverages['f'];

  deductible: Coverages['deductible'];
  deductible_type: Coverages['deductible_type'];
  ordinance_or_law_display: Coverages['ordinance_or_law_display'];
  sinkhole_collapse: Coverages['sinkhole_collapse'];
  pprc: Coverages['pprc'];

  hurricane_deductible: Coverages['hurricane_deductible'];
  hurricane_deductible_type: Coverages['hurricane_deductible_type'];
  wind_deductible: Coverages['wind_deductible'];
  wind_deductible_type: Coverages['wind_deductible_type'];
  wildfire_deductible: Coverages['wildfire_deductible'];
  wildfire_deductible_type: Coverages['wildfire_deductible_type'];
  extended_rebuilding_cost: Coverages['extended_rebuilding_cost'];
};
export const getCoverageRowsData = (coverages: GetCoverageRowsDataParams): Array<RowProps> => {
  const sections: Array<RowProps> = [
    {
      left: {
        iconName: 'Money',
        price: coverages && coverages.deductible,
        priceType: coverages?.deductible_type || DeductibleTypes.DOLLARS,
        titles: ['deductible'],
      },
      right: {
        name: '',
        value: [],
      },
    },
    {
      left: {
        iconName: 'House',
        price: coverages.a,
        priceType: DeductibleTypes.DOLLARS,
        titles: ['Your Home'],
      },
      right: {
        name: '',
        value: [
          { name: 'Home Rebuilding Coverage', value: coverages.a },
          { name: 'Separate Structure', value: coverages.b },
          { name: 'Loss of Use', value: coverages.d },
          {
            name: 'Ordinance Protection',
            value: coverages.ordinance_or_law_display || null,
          },
          {
            name: 'Sinkhole Coverage',
            value:
              coverages.sinkhole_collapse !== undefined
                ? coverages.sinkhole_collapse
                  ? 'Included'
                  : 'Excluded'
                : null,
          },
        ],
      },
    },
    {
      left: {
        iconName: 'LaptopAlt',
        price: coverages.c,
        priceType: DeductibleTypes.DOLLARS,
        titles: ['your belongings'],
      },
      right: {
        name: '',
        value: [
          { name: 'Personal Property', value: coverages.c },
          { name: 'Replacement Cost', value: coverages.pprc ? 'YES' : 'NO' },
        ],
      },
    },
    {
      left: {
        iconName: 'Liability',
        price: coverages.e,
        priceType: DeductibleTypes.DOLLARS,
        titles: ['PERSONAL LIABILITY'],
      },
      right: {
        name: '',
        value: [
          { name: 'Personal Liability', value: coverages.e },
          { name: 'Medical Payments', value: coverages.f },
        ],
      },
    },
  ];

  const presentExtraCoverages = getPresentExtraCoverages(coverages);

  if (presentExtraCoverages.length) {
    sections.push({
      left: {
        iconName: 'EquipmentBreakdown',
        priceType: DeductibleTypes.DOLLARS,
        titles: ['ADDITIONAL COVERAGES', 'LIMITATIONS, AND', 'EXCLUSIONS'],
      },
      right: {
        name: 'coverage details',
        value: presentExtraCoverages,
      },
    });
  }

  const deductibleGeneral = convertCoverageValue(coverages.deductible_type, coverages.deductible);
  const deductibleHurricane = convertCoverageValue(coverages.hurricane_deductible_type, coverages.hurricane_deductible);
  const deductibleWind = convertCoverageValue(coverages.wind_deductible_type, coverages.wind_deductible);
  const deductibleWildfire = convertCoverageValue(coverages.wildfire_deductible_type, coverages.wildfire_deductible);

  if (deductibleGeneral) {
    sections[0].right.value.push({ name: 'General', value: deductibleGeneral });
  }

  if (deductibleHurricane) {
    sections[0].right.value.push({ name: 'Hurricane', value: deductibleHurricane });
  }

  if (deductibleWind) {
    sections[0].right.value.push({ name: 'Wind or Hail', value: deductibleWind });
  }

  if (deductibleWildfire) {
    sections[0].right.value.push({ name: 'Wildfire', value: deductibleWildfire });
  }

  if (coverages.extended_rebuilding_cost) {
    sections[1].right.value.push({
      name: 'Extended Replacement Cost',
      value: `${parseFloat(coverages.extended_rebuilding_cost) * parseFloat(coverages.a)}`,
    });
  }

  return sections;
};

export const policyTypes: Dictionary<string> = {
  'ho-3': 'Homeowner',
  'ho-5': 'Homeowner',
  'ho-6': 'Condo',
  'dp-3': 'Landlord',
};

export const getRightPremiumPriceData = (premium: number) => ({
  monthly: `${(+premium / 12).toFixed(0)}`,
  yearly: `${premium}`,
});

export default function getCoveragesFormattedData(data: ConsentData) {
  const addressData = getAddressData(data.property_information, {
    communityName: data.community.name || '',
    ...data.model,
  });

  const coverageRowsData = getCoverageRowsData(data.coverages as any);
  return { addressData, coverageRowsData };
}
