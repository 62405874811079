import { useLocation } from 'react-router-dom';
import { RouteParams } from 'types';

export const getQueryVariable = (query: string, variable: string) => {
  const queryParams = query.split('&');
  for (let i = 0; i < queryParams.length; i++) {
    const pair = queryParams[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
};

const useHmac = () => {
  const location = useLocation<RouteParams>();
  if (location.search) {
    return getQueryVariable(location.search.substr(1), 'hmac');
  }
  return null;
};

export default useHmac;
