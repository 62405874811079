import React from 'react';
import { Col, Icon, Row, StatesDropdown, TextInput, Zipcode } from '@hippo/components';

import FormikField from '../../components/Form/FormikField';

export const MailingInfo = () => (
  <Row>
    <Col size={[9 / 12, 10 / 12, 10 / 12]}>
      <Row>
        <Col size={[1, 1, 5 / 10]}>
          <FormikField
            label='Mailing Address'
            name='mailing_address_street'
            component={TextInput}
            textSize={[24, 24, 28]}
          />
        </Col>
      </Row>
      <Row>
        <Col size={[1, 1, 5 / 10]}>
          <FormikField
            label='Mailing Address Line 2'
            name='mailing_address_street2'
            component={TextInput}
            textSize={[24, 24, 28]}
          />
        </Col>
      </Row>
      <Row>
        <Col size={[1, 1, 3 / 10]}>
          <FormikField label='City' name='mailing_address_city' component={TextInput} textSize={[24, 24, 28]} />
        </Col>
        <Col size={[5 / 10, 5 / 10, 2 / 10]}>
          <FormikField
            label='States'
            name='mailing_address_state'
            component={StatesDropdown}
            numberOfItemsToBeShown={5}
            textSize={[24, 24, 28]}
          />
        </Col>
        <Col size={[5 / 10, 5 / 10, 2 / 10]}>
          <FormikField label='ZIP Code' name='mailing_address_zip' component={Zipcode} textSize={[24, 24, 28]} />
        </Col>
      </Row>
    </Col>
    <Col size={[3 / 12, 2 / 12, 2 / 12]} first={[false, true, true]}>
      <Icon icon={'PaperAirplane'} size={['64px', '64px', '80px']} />
    </Col>
  </Row>
);
