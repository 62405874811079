import set from 'lodash/set';

import { emptyErrorObj } from './emptyErrorObj';

import { CurrErrProps, ErrorFormatterProps } from './errorTypes';
import { getErrorMessages } from './errorMessages';

export const errorFormatter = ({ errors, errorType }: ErrorFormatterProps) => {
  return errors.reduce((errObj: any, currErr: CurrErrProps) => {
    const { deepKey, msg, type, key } = currErr;
    const errorMessages = getErrorMessages({ type, key }) || msg;

    return set(errObj, `${errorType}.` + deepKey, errorMessages);
  }, emptyErrorObj);
};
