import { ErrorObjProps } from 'helpers/errors/errorTypes';
import { State } from 'reducers/createRootReducer';
import { Dispatch } from 'redux';
import fsa from 'typescript-fsa';
import { errorHandler } from 'helpers/errors/errorHandler';
import hbo from 'utils/api';
import { QuoteData, ReferralModelResponse } from 'reducers/referral/types';

const actionCreator = fsa('PARTNER_REFERRAL');

export const fetchReferralQuoteAsync = actionCreator.async<null, QuoteData, ErrorObjProps>('FETCH_REFERRAL_QUOTE');

export const fetchReferralQuote = (modelId: string | null) => async (dispatch: Dispatch, getState: () => State) => {
  const {
    referral: { quote },
  } = getState();

  if (!quote.isFetching) {
    dispatch(fetchReferralQuoteAsync.started(null));

    try {
      const { data } = await hbo.get(`/v1/public/models/${modelId}/assigned-quote`);
      const mockResponse: QuoteData = data;
      dispatch(fetchReferralQuoteAsync.done({ params: null, result: mockResponse }));
    } catch (error: any) {
      errorHandler({ error, errorType: 'ReferralQuoteFetchError', dispatch });
    }
  }
};

export const fetchReferralModelAsync = actionCreator.async<null, ReferralModelResponse, ErrorObjProps>(
  'FETCH_MODEL_QUOTE'
);

export const fetchReferralModel = (modelId: string | null) => async (dispatch: Dispatch, getState: () => State) => {
  const {
    referral: { model },
  } = getState();

  if (!model.isFetching) {
    dispatch(fetchReferralModelAsync.started(null));

    try {
      const { data } = await hbo.get(`/v1/public/models/${modelId}`);
      const mockResponse: ReferralModelResponse = data;
      dispatch(fetchReferralModelAsync.done({ params: null, result: mockResponse }));
    } catch (error: any) {
      errorHandler({ error, errorType: 'ReferralModelFetchError', dispatch });
    }
  }
};
