import { Col, Grid, Row, Typography } from '@hippo/components';
import { getSearchParam } from '../../utils/getSearchParam';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { OrgNameMapping } from '../../helpers/enums/organization';
import styled from 'styled-components';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { Spinner } from '../../components/Spinner';
import { Flex } from '../../components/Flex';

const AccentedAnchor = styled.a`
  color: #005daa;
`;

export const ThankYou = () => {
  const org = getSearchParam(window.location.search, 'org') as OrgNameMapping | null;
  const whiteLabel = useWhiteLabel({ org: org ?? '' }, { enabled: Boolean(org) });
  const pageData = whiteLabel.data?.pages;

  return (
    <LennarSearchWrapper
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
      builder={whiteLabel.data?.builder}
      licenses={whiteLabel.data?.licenses ?? []}
      pages={whiteLabel.data?.pages}
    >
      <Grid>
        {whiteLabel.isLoading ? (
          <Flex width='100%' height='100%' alignItems='center' justifyContent='center'>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Row paddingTop='120px'>
              <Col alignItems='center' justifyContent='center' size={[1, 10 / 12, 8 / 12]} offset={[0, 1 / 12, 2 / 12]}>
                <Typography as='h1' type={['heading7', 'heading4', 'heading4']} textAlign='center'>
                  {pageData ? pageData.thank_you.text : <>&nbsp;</>}
                </Typography>
              </Col>
            </Row>
            <Row paddingTop='20px'>
              <Col alignItems='center' justifyContent='center' size={[1, 10 / 12, 8 / 12]} offset={[0, 1 / 12, 2 / 12]}>
                <Typography type='body5' textAlign='center'>
                  {pageData ? (
                    <>
                      One of our agents will reach out to you shortly with next steps, or you can contact us at{' '}
                      <AccentedAnchor href={`tel:${pageData.thank_you.phone}`}>
                        {pageData.thank_you.phone}
                      </AccentedAnchor>{' '}
                      or{' '}
                      <AccentedAnchor href={`mailto:${pageData.thank_you.email}`}>
                        {pageData.thank_you.email}
                      </AccentedAnchor>
                      .
                    </>
                  ) : (
                    <>One of our agents will reach out to you shortly with next steps.</>
                  )}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      </Grid>
    </LennarSearchWrapper>
  );
};
