import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FLOWS, PAGES } from 'globals/routes';
import { PageType } from 'helpers/enums/pageType';
import { hasHmac } from '../utils/hasHmac';

import { CheckoutPage } from 'pages/checkout/CheckoutPage';
import QuotePage from 'pages/quote/Quote';
import SuccessPage from 'pages/success/Sucess';

const ConfirmationFlow: FC = () => {
  if (!hasHmac()) {
    return <Redirect to={'/404'} />;
  }

  return (
    <Switch>
      <Route path={`${FLOWS.confirm}/${PAGES.coverages}`} component={() => <QuotePage pageType={PageType.Confirm} />} />
      <Route path={`${FLOWS.confirm}/${PAGES.checkout}`} render={() => <CheckoutPage pageType={PageType.Confirm} />} />
      <Route path={`${FLOWS.confirm}/${PAGES.success}`} component={() => <SuccessPage pageType={PageType.Confirm} />} />
      <Redirect to={'/404'} />
    </Switch>
  );
};

export default ConfirmationFlow;
