import { push } from 'connected-react-router';
import { ErrorObjProps } from 'helpers/errors/errorTypes';
import { State } from 'reducers/createRootReducer';
import { ConsentData, ConsentOptions, Discounts, Underwriting } from 'reducers/consent/types';
import { Dispatch } from 'redux';
import { AppThunkDispatch } from 'types';
import fsa from 'typescript-fsa';
import { identify } from 'react-fullstory';

import hbo, { getConsentOptions } from '../utils/api';
import { PAGES } from '../globals/routes';
import { errorHandler } from '../helpers/errors/errorHandler';

const actionCreator = fsa('CONSENT');

/**
 * Fetch consent data
 */
export const fetchConsentDataAsync = actionCreator.async<string, ConsentData, ErrorObjProps>('FETCH_DATA');

/**
 * @deprecated
 */
export const fetchConsentData =
  (quoteId: string, flowType: string, hmac: string) => async (dispatch: Dispatch, getState: () => State) => {
    const { consent } = getState();

    if (!consent.isFetching || (consent.params && consent.params.quoteId !== quoteId)) {
      dispatch(fetchConsentDataAsync.started(quoteId));

      try {
        const { status, data } = await hbo.get<ConsentData>(
          `/v1/consent/${quoteId}/data?type=${flowType}&hmac=${hmac}`
        );
        if (status === 200) {
          identify(quoteId, {
            flow_type: flowType,
            reference_id: data.reference_id,
            project: 'hbo-consent-flow',
          });

          const heapId = window.appConfig && window.appConfig.HEAP_ID;
          if (heapId) {
            window.heap.identify(quoteId);
          }

          dispatch(fetchConsentDataAsync.done({ params: quoteId, result: data }));
        } else {
          throw new Error('Failed to get consent data');
        }
      } catch (error: any) {
        errorHandler({ error, errorType: 'consentDataFetchError', quoteId, dispatch });
      }
    }
  };

interface SaveAsyncParams {
  quoteId: string;
}

export const saveAsync = actionCreator.async<SaveAsyncParams, ConsentData, ErrorObjProps>('SAVE_CHECKOUT');

/**
 * Save underwriting
 */
export interface SaveUnderwritingParams extends SaveAsyncParams {
  underwriting: Underwriting;
}

export const saveUnderwriting =
  ({ quoteId, underwriting }: SaveUnderwritingParams) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch(saveAsync.started({ quoteId }));
    try {
      const {
        router: {
          location: { search },
        },
      } = getState();
      const { data, status } = await hbo.post(`/v1/consent/${quoteId}/eligibility${search}`, underwriting);

      if (status === 200) {
        dispatch(saveAsync.done({ params: { quoteId }, result: data }));
        dispatch(push(`/consent/${quoteId}/${PAGES.checkout}${search}`));
      } else {
        throw new Error('Failed to save eligibility.');
      }
    } catch (error: any) {
      errorHandler({ error, errorType: 'eligibilityError', quoteId, dispatch });
    }
  };

/**
 * Save Smart Home Kit
 */
interface SaveSmartHomeKit extends SaveAsyncParams {
  smart_home_kit: boolean;
}
export const saveSmartHomeKit =
  ({ quoteId, smart_home_kit }: SaveSmartHomeKit) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch(saveAsync.started({ quoteId }));
    try {
      const {
        router: {
          location: { search },
        },
      } = getState();
      const { data, status } = await hbo.post(`/v1/consent/${quoteId}/smart_home_kit${search}`, {
        smart_home_kit,
      });

      if (status === 200) {
        dispatch(saveAsync.done({ params: { quoteId }, result: data }));
      } else {
        throw new Error('Failed to save smart home kit.');
      }
    } catch (error: any) {
      errorHandler({ error, errorType: 'smartHomeKitError', quoteId, dispatch });
    }
  };

/**
 * Options
 */

export const updateOptionsAction = actionCreator<ConsentOptions>('OPTIONS');
export const fetchOptions = () => async (dispatch: AppThunkDispatch) => {
  try {
    const { data, status } = await getConsentOptions();

    if (status === 200) {
      dispatch(updateOptionsAction(data));
    } else {
      throw new Error('Failed to save eligibility.');
    }
  } catch (error: any) {
    errorHandler({ error, errorType: 'updateOptionsError', dispatch });
  }
};

/**
 * Patch Underwriting
 */
export const patchUnderwriting = actionCreator<Partial<Underwriting>>('PATCH-UNDERWRITING');

/**
 * Patch Discount
 */
export const patchDiscount = actionCreator<Partial<Discounts>>('PATCH-DISCOUNT');
