export const formatCurrency = (value: string | number, currency = '$') => {
  // https://stackoverflow.com/questions/14751802/split-string-every-3-characters-from-back-using-javascript
  // Split in chunks of 3 "thousands" and join with ','
  const numberString = +value + '';
  const replaceRegexp = new RegExp(`[${currency},]`, 'g');
  const numberFormat = numberString.replace(replaceRegexp, '');
  const [dollars, cents] = numberFormat.split('.');
  const formattedDollarAmt = dollars.split(/(?=(?:...)*$)/).join(',');

  const centsString = cents !== undefined ? `.${cents.substring(0, 2)}` : '';
  const result = `${formattedDollarAmt}${centsString}`;
  return value.toString()[0] !== currency ? currency + result : result;
};

export const formatSquareFeet = (value: string) => {
  if (value.length <= 3) {
    return value;
  }

  const commaIndex = value.length - 3;

  return `${value.substring(0, commaIndex)},${value.substring(commaIndex)}`;
};

export const squareFootageFormatter = (value: number) => `${value.toLocaleString()} sq ft`;
