import React, { useState } from 'react';
import { Col, Modal, Row, Typography } from '@hippo/components';
import { Spinner } from 'components/Spinner';
import Box from 'components/box/Box';
import { ModalNames } from '../types/modals';
import { useWhiteLabel } from '../helpers/use-white-label';
import { Logo } from '../components/Logo';
import { OrgNameMapping } from '../helpers/enums/organization';

export type RedirectModalProps = {
  modalName: ModalNames.RedirectModal;
  data: {
    href: string;
  };
  organizationName: OrgNameMapping;
};

const RedirectModal = ({ data, organizationName }: RedirectModalProps) => {
  const { href } = data;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { pageData } = useWhiteLabel();

  const afterOpen = () =>
    setTimeout(() => {
      setIsModalOpen(false);
      window.location.href = href;
    }, 1500);

  return (
    <Modal title={''} isOpen={isModalOpen} afterOpen={afterOpen} disableClose={true} size='large'>
      <Row>
        {pageData ? (
          <Box position='absolute' top='72px' left='0' right='0' display='flex' alignItems='center'>
            <Box width='235px' height='39px'>
              <Logo organizationName={organizationName} />
            </Box>
          </Box>
        ) : null}
        <Col
          size={1}
          display='flex'
          flexDirection='row'
          mt={['132px', '32px', '32px']}
          mb='24px'
          justifyContent='center'
        >
          <Box mr='15px'>
            <Box width='30px' height='30px'>
              <Spinner isFluid={true} borderWidth='3px' borderColor='gray600' />
            </Box>
          </Box>
          <Typography as='p' type='heading7' color='gray600'>
            Redirecting to Hippo Home Insurance
          </Typography>
        </Col>
        <Col size={1}>
          <Typography as='p' type='body5' color='gray700'>
            You will now be automatically redirected to hippo.com continue the purchase of your new home insurance
            policy with Hippo Home Insurance.
          </Typography>
        </Col>
      </Row>
    </Modal>
  );
};

export default RedirectModal;
