import { FormikErrors } from 'formik';
import { FormState } from './CheckoutPage';
import { Typography } from '@hippo/components';
import { checkoutId } from './checkoutId';
import styled from 'styled-components';
import { scrollToInvalidField } from './CheckoutForm';

const mapFieldNameIntoError = (fieldName: string) => {
  switch (fieldName) {
    case 'customer_approval':
      return 'Accept the Applicants Statement.';

    case 'contact_email':
      return 'Contact email address is invalid.';

    case 'contact_phone':
      return 'Provide a correct contact phone number.';

    case 'phone_number':
      return 'Provide a correct phone number.';

    case 'mailing_address_zip':
      return 'Mailing zip code is invalid.';
  }
};

const ClickableText = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left;
  width: auto;

  &:hover {
    text-decoration: underline;
  }
`;

type CheckoutErrorSummaryProps = {
  errors: FormikErrors<FormState>;
};

export const CheckoutErrorSummary = ({ errors }: CheckoutErrorSummaryProps) => (
  <>
    <Typography type='body5' color='error' mt='5px'>
      Before submitting application please:
    </Typography>
    <>
      {Object.keys(errors).map((key) => (
        <Typography type='body5' color='error' mt='5px' key={key} data-testid={`${checkoutId.summaryError}-${key}`}>
          <ClickableText onClick={() => scrollToInvalidField({})(key)}>{mapFieldNameIntoError(key)}</ClickableText>
        </Typography>
      ))}
    </>
  </>
);
