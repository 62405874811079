import { Guuid } from '../common';

export enum LeadDuplicationReason {
  sameJobAndEmailAndAddress = 'SAME_JOB_AND_EMAIL_AND_ADDRESS',
  sameJobAndEmail = 'SAME_JOB_AND_EMAIL',
  sameAddressAndEmail = 'SAME_ADDRESS_AND_EMAIL',
}

export type CreateLeadErrorResponse = {
  title: string;
  detail: string;
  status: number;
  name: string;
  errors: [];
  context: {
    leadDuplicates: { leadId: Guuid; duplicationReason: LeadDuplicationReason }[];
  };
};

export interface CreateLeadRequest {
  contact_information: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
  };
  property_information: {
    property_address_state: string;
    property_address_city: string;
    property_address_street: string;
    property_address_street2: string;
    property_address_zip_code: string;
    model_id: string;
    community_id: string;
  };
  additional_insureds: Array<{
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
  }>;
}
