import styled from 'styled-components';

export const StyledUl = styled.ul`
  list-style: none;
  padding: 10px 0;
`;

export const BreakableWord = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`;

export const StyledWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
`;

export const StyledLink = styled.a`
  color: #4a90e2;
`;
