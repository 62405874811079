import { Divider, IconButton, Modal, Typography } from '@hippo/components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'reducers/createRootReducer';
import { Dog, DogBreed } from 'reducers/consent/types';
import { v1 as uuidv1 } from 'uuid';
import { omit } from 'lodash';

import { ModalProps } from '../../types/modals';
import Box from '../../components/box/Box';

import DogsRow from './dogs-row/DogsRow';
import { AddRowUI, DogContainer } from './style';
import { patchUnderwriting } from '../../actions/consent';
import { closeModal } from '../../actions/modals';

interface DogRow extends Dog {
  id: string;
}

const DogsModal: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  const storeDogs = useSelector<State, Array<Dog>>((state) =>
    state.consent.data ? state.consent.data.underwriting.dogs : []
  );

  const createRowData = () => ({ id: uuidv1() } as DogRow);

  const getStoreDogsRows = () =>
    storeDogs.map((dog) => ({
      ...dog,
      id: uuidv1(),
    }));
  const [dogs, setPets] = useState<Array<DogRow>>(getStoreDogsRows);

  const mutableDogs = dogs.length ? dogs : [createRowData()];

  const onAddRow = () => {
    setPets([...mutableDogs, createRowData()]);
  };

  const onDelete = (toDeleteId: string) => {
    setPets(mutableDogs.filter(({ id }) => id !== toDeleteId));
  };

  const handleRowUpdate = (item: Dog, index: number) => {
    const newList = mutableDogs.map((v, i) => (i === index ? { ...v, ...item } : v));
    setPets(newList);
  };

  const breeds = useSelector<State, Array<DogBreed> | undefined>((state) =>
    state.consent.options ? state.consent.options.dog_breeds_options : undefined
  );

  const handleClickSave = () => {
    dispatch(
      patchUnderwriting({
        dogs: mutableDogs
          .filter((dog) => dog.breed)
          .map((dog) => ({
            ...omit(dog, 'id'),
            biteHistory: !!dog.biteHistory,
          })),
      })
    );
    dispatch(closeModal());
  };

  return (
    <Modal
      {...props}
      title={'Dogs'}
      size={'large'}
      disableBodyPadding={true}
      subtitle={'Since you have a dog, we need to ask the following questions:'}
      primaryButtonText={'Save info'}
      onPrimaryButtonClick={handleClickSave}
    >
      <>
        <DogContainer>
          {/* please keep for debug purposes */}
          {/* <pre>{JSON.stringify(dogs, null, 2)}</pre> */}
          <Divider color={'primary500'} />
          {mutableDogs.map((item, index) => (
            <DogsRow
              key={item.id}
              index={index}
              updateDog={handleRowUpdate}
              dog={item}
              breeds={breeds}
              onDelete={() => onDelete(item.id)}
              showDelete={mutableDogs.length > 1}
            />
          ))}
        </DogContainer>
        <Box paddingX={3} paddingBottom={4}>
          <Typography type={'allCaps3'} color={'gray500'}>
            <AddRowUI onClick={onAddRow}>
              <IconButton icon={'Plus'} size={12} />
              <div>add another dog</div>
            </AddRowUI>
          </Typography>
        </Box>
        <Divider color={'gray500'} />
      </>
    </Modal>
  );
};

export default DogsModal;
