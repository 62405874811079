import React from 'react';
import styled from 'styled-components';

type SpinnerProps = {
  borderWidth?: string;
  isFluid?: boolean;
  borderColor?: HippoComponents.Color;
};

const Container = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;
  width: ${({ isFluid }) => (isFluid ? '100%' : '64px')};
  height: ${({ isFluid }) => (isFluid ? '100%' : '64px')};

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes load-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: ${({ isFluid }) => (isFluid ? '100%' : 'none')};
  }
`;

const Spinners = styled.div<SpinnerProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${({ isFluid }) => (isFluid ? '100%' : '51px')};
  height: ${({ isFluid }) => (isFluid ? '100%' : '51px')};
  margin: ${({ isFluid }) => (isFluid ? '0px' : '6px')};
  border: ${({ borderWidth }) => borderWidth || '6px'} solid #fff;
  border-radius: 50%;
  animation: load-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ borderColor, theme }) => (borderColor ? theme.colors[borderColor] : theme.colors.primary500)}
    transparent transparent transparent;
`;

export const Spinner: React.FC<SpinnerProps> = (props) => (
  <Container isFluid={props.isFluid} data-testid='loading-spinner'>
    <Spinners {...props} />
    <Spinners {...props} />
    <Spinners {...props} />
    <Spinners {...props} />
  </Container>
);

export const LoadingContainer = styled.div`
  text-align: center;
  padding: 35px 0;
`;
