import { getContactInfoPath, getPropertyInfoPath } from '../../../helpers/paths';
import { Step } from '../GetQuote';

type GetStepParams = {
  modelId?: string;
  communityId?: string;
  zipCode?: string;
  pathname: string;
};

export const getStep = ({ modelId, communityId, zipCode, pathname }: GetStepParams): Step => {
  if (pathname === getPropertyInfoPath()) {
    return 'propertyInfo';
  } else if (pathname === getContactInfoPath()) {
    return 'contactInfo';
  } else if (!zipCode) {
    return 'zip';
  } else if (!communityId) {
    return 'communityList';
  } else if (!modelId) {
    return 'modelList';
  } else {
    return 'contactInfo';
  }
};
