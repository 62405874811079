import React from 'react';
import { Typography } from '@hippo/components';

import { BasicNameValueObj } from '../type';

import { StyledUl } from './style';

export const AdditionalCoverageList: React.FC<{ valueObjArray: Array<BasicNameValueObj> }> = ({ valueObjArray }) => (
  <StyledUl>
    {valueObjArray.map((l, i) => (
      <li key={i}>
        <Typography type={'body5'} textAlign={'left'} color={'gray600'}>
          {l.name}
        </Typography>
      </li>
    ))}
  </StyledUl>
);
