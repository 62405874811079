export interface ModalProps {
  afterOpen?: () => void;
  afterClose?: () => void;
  defaultState?: any;
  beforeOpen?: Promise<void> | (() => void);
  beforeClose?: Promise<void> | (() => void);
  onEscapeKeydown?: (event: Event) => void;
  onBackgroundClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onPrimaryButtonClick?: (updateValues?: any) => void;
  onSecondaryButtonClick?: (updateValues?: any) => void;
  isOpen: boolean;
  clickedIndex?: number;
}

export enum ModalNames {
  SmartHomeConsentModal = 'SmartHomeConsentModal',
  SecondHomeModal = 'SecondHomeModal',
  SwimmingPoolModal = 'SwimmingPoolModal',
  DogsModal = 'DogsModal',
  ExoticPetsModal = 'ExoticPetsModal',
  CoverageModal = 'CoverageModal',
  RedirectModal = 'RedirectModal',
}
