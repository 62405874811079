import { Divider, IconButton, Modal, Typography } from '@hippo/components';
import { patchUnderwriting } from '../../actions/consent';
import { closeModal } from '../../actions/modals';
import Box from 'components/box/Box';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../reducers/createRootReducer';
import { ExoticPet } from '../../reducers/consent/types';
import { v1 as uuidv1 } from 'uuid';

import { ModalProps } from '../../types/modals';
import omit from 'lodash/omit';

import ExoticPetRow from './components/ExoticPetRow';
import { AddRowUI, PetContainer } from './style';

interface PetRow extends ExoticPet {
  id: string;
}

const ExoticPetsModal: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  const storePets = useSelector<State, Array<ExoticPet>>((state) =>
    state.consent.data ? state.consent.data.underwriting.exotic_pets : []
  );

  const createRowData = () => ({ id: uuidv1() } as PetRow);

  const getStorePetsRows = () =>
    storePets.map((pet) => ({
      ...pet,
      id: uuidv1(),
    }));
  const [pets, setPets] = useState<Array<PetRow>>(getStorePetsRows);

  const mutablePets = pets.length ? pets : [createRowData()];

  const onAddRow = () => {
    setPets([...mutablePets, createRowData()]);
  };

  const onDelete = (toDeleteId: string) => {
    setPets(mutablePets.filter(({ id }) => id !== toDeleteId));
  };

  const handleRowUpdate = (item: ExoticPet, index: number) => {
    const newList = mutablePets.map((v, i) => (i === index ? { ...v, ...item } : v));
    setPets(newList);
  };

  const handleClickSave = () => {
    dispatch(
      patchUnderwriting({
        exotic_pets: mutablePets
          .filter((pet) => pet.type)
          .map((pet) => ({
            ...omit(pet, 'id'),
            biteHistory: !!pet.biteHistory,
          })),
      })
    );
    dispatch(closeModal());
  };

  return (
    <Modal
      {...props}
      title={'Exotic Pets'}
      size={'large'}
      disableBodyPadding={true}
      subtitle={'Please describe the animal and any bite or injury history, if applicable'}
      primaryButtonText={'Save info'}
      onPrimaryButtonClick={handleClickSave}
    >
      <>
        <PetContainer>
          {/* please keep for debug purposes */}
          {/* <pre>{JSON.stringify(listWithExtraItem, null, 2)}</pre> */}
          <Divider color={'primary500'} />
          {mutablePets.map((item, index) => (
            <ExoticPetRow
              key={item.id}
              index={index}
              update={handleRowUpdate}
              pet={item}
              onDelete={() => onDelete(item.id)}
              showDelete={mutablePets.length > 1}
            />
          ))}
        </PetContainer>
        <Box paddingX={3} paddingBottom={4}>
          <Typography type={'allCaps3'} color={'gray500'}>
            <AddRowUI onClick={onAddRow}>
              <IconButton icon={'Plus'} size={12} />
              <div>add another exotic pet</div>
            </AddRowUI>
          </Typography>
        </Box>
        <Divider color={'gray500'} />
      </>
    </Modal>
  );
};

export default ExoticPetsModal;
