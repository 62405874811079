import { useQuery } from 'react-query';
import { useModelId } from 'helpers/use-route-query';
import { getModel } from '../utils/api';
import { GetModelResponse } from '../types/getModal';

export default () => {
  const modelId = useModelId() || '';

  return useQuery<GetModelResponse>(
    ['Model', modelId],
    async () => {
      const { data } = await getModel(modelId);
      return data;
    },
    {
      enabled: Boolean(modelId),
    }
  );
};
