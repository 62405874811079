import React from 'react';
import { theme } from '@hippo/components';
import debounce from 'lodash/debounce';

const getBreakpointSize = (breakpoint?: string) => (breakpoint ? parseInt(breakpoint.replace('px', ''), 10) : 0);

const breakpoints = {
  phone: getBreakpointSize(theme.breakpoints[0]),
  desktop: getBreakpointSize(theme.breakpoints[1]),
};

export const useResponsive = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = debounce(() => setWidth(window.innerWidth), 150);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return {
    isPhone: width < breakpoints.phone,
    isTablet: width >= breakpoints.phone && width <= breakpoints.desktop,
    isDesktop: width > breakpoints.desktop,
  };
};
