import styled from 'styled-components';
import { device } from '@hippo/components';

export const StyledAgencyInfo = styled.span`
  padding: 8px 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray100};
  word-break: normal;
  &:last-of-type {
    border-right: none;
  }
  ${device.mobile} {
    &:first-of-type {
      padding-left: 0;
    }
  }
  ${device.tablet} {
    &:first-of-type {
      padding-left: 0;
    }
  }
`;

export const FooterLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Separator = styled.span`
  &:before {
    content: '|';
    margin-left: 8px;
    margin-right: 8px;
  }
`;
