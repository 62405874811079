import { Typography } from '@hippo/components';
import { RowWithBorder } from '../styles';
import { BreakableWord, StyledLink } from './style';

export const AgentInfo = ({ name, phone, email }: { name: string; phone: string; email: string }) => (
  <RowWithBorder>
    <Typography type={'allCaps3'} color={'primary500'} paddingBottom={'20px'} paddingTop={'10px'}>
      PRODUCER INFORMATION
    </Typography>
    <Typography type={'body5'} color={'gray600'} as={'span'}>
      <BreakableWord>Agent: {name}</BreakableWord>
      <BreakableWord>
        Phone: <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
      </BreakableWord>
      <BreakableWord>
        Email: <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
      </BreakableWord>
    </Typography>
  </RowWithBorder>
);
