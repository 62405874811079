import React from 'react';
import { Col, Icon, Phone, Row } from '@hippo/components';

import { Delimiter, Label, LargeText } from './style';

import { ConsentData } from '../../reducers/consent/types';
import FormikField from '../../components/Form/FormikField';
import { Summary } from './Summary';
import { checkoutId } from './checkoutId';

type PersonalInfoAndQuoteProps = {
  firstName: ConsentData['checkout']['personal_information']['first_name'];
  lastName: ConsentData['checkout']['personal_information']['last_name'];
  email: ConsentData['checkout']['personal_information']['email'];
};

export const PersonalInfoAndQuote: React.FC<PersonalInfoAndQuoteProps> = ({ firstName, lastName, email }) => (
  <Row paddingTop={'30px'}>
    <Col size={[1, 4 / 12, 4 / 12]} last={[false, true, true]}>
      <Summary />
    </Col>
    <Col size={[1, 8 / 12, 8 / 12]}>
      <Row>
        <Col offset={[0, 1 / 12, 1 / 12]} rightOffset={[0, 1 / 12, 1 / 12]}>
          <Row>
            <Col>
              <Label>FIRST NAME</Label>
              <LargeText data-testid={checkoutId.firstName}>{firstName}</LargeText>
            </Col>
            <Col>
              <Label>LAST NAME</Label>
              <LargeText data-testid={checkoutId.lastName}>{lastName}</LargeText>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>EMAIL</Label>
              <LargeText data-testid={checkoutId.email}>{email}</LargeText>
            </Col>
          </Row>
          <Row>
            <Col marginTop={'20px'} marginBottom={'10px'}>
              <FormikField
                label='Phone Number (required)'
                name='phone_number'
                component={Phone}
                textSize={[24, 24, 28]}
              />
            </Col>
          </Row>
        </Col>
        <Col size={[3 / 12, 2 / 12, 2 / 12]} first={[false, true, true]}>
          <Icon icon={'House'} size={['64px', '64px', '80px']} />
        </Col>
      </Row>
      <Delimiter />
    </Col>
  </Row>
);
