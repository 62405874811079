import { Col, Icon, IconButton, Row, Typography } from '@hippo/components';
import { understandingCoveragesData } from '../../modals/coverages/understandingPlanData';
import { RouteComponentProps } from 'react-router-dom';
import { StyledGrid } from '../landing/style';
import styled from 'styled-components';
import { ThemeProviderOverride } from '../../components/ThemeProviderOverride';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { Flex } from '../../components/Flex';

const Title = styled.h1({
  fontSize: '24px',
  textTransform: 'uppercase',
  fontWeight: 700,
});

const ParagraphTitle = styled.span({
  fontSize: '18px',
  textTransform: 'uppercase',
});

export const CoverageExplanation = (props: RouteComponentProps<{ slug: string }>) => {
  const dispatch = useDispatch();
  const coverage = understandingCoveragesData.find((c) => c.slug === props.match?.params?.slug);

  const goBackCallback = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  return (
    <ThemeProviderOverride>
      <StyledGrid>
        <Row paddingTop='14px'>
          <Col size={10 / 12} justifyContent='center'>
            {coverage ? (
              <Typography type='allCaps3' color='gray700'>
                <Title>{coverage.title}</Title>
              </Typography>
            ) : (
              <Typography type='body4' color='gray700'>
                Something went wrong and we couldn&apos;t find information about coverage
              </Typography>
            )}
          </Col>
          <Col size={2 / 12} data-testid='close_button_wrapper'>
            <IconButton icon='Close' onClick={goBackCallback} />
          </Col>
        </Row>

        {coverage && (
          <>
            <Row paddingY='24px'>
              <Col alignContent='flex-end' justifyContent='flex-end'>
                <Icon icon={coverage.iconName} size={72} />
              </Col>
            </Row>
            {coverage.contents.map((content, i) => (
              <Row paddingY='10px' flexDirection='row' key={i}>
                <Col>
                  <Typography type='body4' color='gray700' as='span'>
                    <Typography type='allCaps1' color='primary500' as='span'>
                      <ParagraphTitle>{content.title}</ParagraphTitle>
                    </Typography>
                    <span>{content.texts}</span>
                  </Typography>
                </Col>
              </Row>
            ))}
          </>
        )}
        <Flex marginBottom='90px' />
      </StyledGrid>
    </ThemeProviderOverride>
  );
};
