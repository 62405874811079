import { GetQuoteParams } from '../GetQuote';

export const getSearchParams = (search: string): GetQuoteParams => {
  const searchParams = new URLSearchParams(search);
  let params = {};

  for (const [key, value] of searchParams.entries()) {
    params = {
      ...params,
      [key]: value,
    };
  }

  return params;
};
