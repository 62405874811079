import React, { useEffect, useRef } from 'react';
import { Divider, Modal } from '@hippo/components';

import IconWithTexts from '../../components/IconWithTexts/IconWithTexts';

import { understandingCoveragesData } from './understandingPlanData';
import { ModalProps } from '../../types/modals';
import { DeductibleTypes } from '../../pages/coverages/type';

const modalBodyText = `
	Unclear about something? <br />
	Not to worry. Let’s get you up to speed on the important terms and definitions in your Homeowners policy.`;

const ModalDetailList: React.FC<{ clickedIndex?: number }> = ({ clickedIndex }) => {
  const itemRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    if (clickedIndex) {
      itemRefs.current[clickedIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [clickedIndex]);

  return (
    <>
      {understandingCoveragesData.map((item, i) => (
        <div ref={(el) => (itemRefs.current[i] = el)} key={i}>
          <IconWithTexts
            iconName={item.iconName}
            titles={[item.title]}
            contents={item.contents}
            priceType={DeductibleTypes.DOLLARS}
            iconAsSeparateCol={true}
            topPaddingBetweenTitleTexts={'5px'}
          />
          <Divider color={'primary500'} margin={20} />
        </div>
      ))}
    </>
  );
};
const CoverageModal = (props: ModalProps) => {
  return (
    <Modal {...props} title={'Understanding Your Coverage'} subtitle={modalBodyText} icon={'House'} size={'large'}>
      <>
        <Divider color={'primary500'} margin={20} />
        <ModalDetailList clickedIndex={props.clickedIndex} />
      </>
    </Modal>
  );
};

export default CoverageModal;
