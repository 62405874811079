import { Modal } from '@hippo/components';
import { Underwriting } from '../reducers/consent/types';
import { patchUnderwriting } from '../actions/consent';
import { closeModal } from '../actions/modals';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../reducers/createRootReducer';

import SwitchRow from '../components/SwitchRow/SwitchRow';
import SwitchContainer from '../components/SwitchContainer/SwitchContainer';
import { ModalProps } from '../types/modals';

const SwimmingPoolModal: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  const underwriting = useSelector<State, Underwriting | undefined>((state) =>
    state.consent.data ? state.consent.data.underwriting : undefined
  );

  const handleUpdate = (change: HippoComponents.OnChange<boolean>) => {
    if (change.name === 'pool_fence') {
      setPoolFence(change.value);
    }
    if (change.name === 'diving_board') {
      setDivingBoard(change.value);
    }
  };

  const isChecked = (fieldName: keyof Underwriting) => {
    if (underwriting && underwriting[fieldName]) {
      return !!underwriting[fieldName];
    }
    return false;
  };

  const [poolFence, setPoolFence] = useState(isChecked('pool_fence'));
  const [divingBoard, setDivingBoard] = useState(isChecked('diving_board'));

  const handleClickSave = () => {
    if (underwriting) {
      dispatch(
        patchUnderwriting({
          pool_fence: poolFence,
          diving_board: divingBoard,
        })
      );
    }
    dispatch(closeModal());
  };

  return (
    <Modal
      {...props}
      title={'Swimming Pool'}
      primaryButtonText={'Save Info'}
      size={'large'}
      disableBodyPadding={true}
      onPrimaryButtonClick={handleClickSave}
      subtitle={'Since you have a pool, we need to ask the following questions:'}
    >
      <div>
        <SwitchContainer>
          <SwitchRow
            onChange={handleUpdate}
            checked={poolFence}
            name='pool_fence'
            icon='Fence'
            title='Fence'
            description='Is your pool in a fenced yard and/or surrounded by a pool fence?'
          />
          <SwitchRow
            onChange={handleUpdate}
            checked={divingBoard}
            name='diving_board'
            icon='FrPool'
            title='Diving Board'
            description='Do you have a diving board?'
          />
        </SwitchContainer>
      </div>
    </Modal>
  );
};
export default SwimmingPoolModal;
