import React from 'react';
import { Col, Icon, Row } from '@hippo/components';

import { Label, LargeText } from './style';

import { ConsentData } from '../../reducers/consent/types';
import { checkoutId } from './checkoutId';

interface PropertyInfoProps {
  property: ConsentData['property_information'];
}

export const PropertyInfo: React.FC<PropertyInfoProps> = ({ property }) => (
  <Row>
    <Col size={[9 / 12, 10 / 12, 10 / 12]}>
      <Row>
        <Col>
          <Label>Property address</Label>
          <LargeText data-testid={checkoutId.propertyAddressStreet}>{property.street}</LargeText>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label>City</Label>
          <LargeText data-testid={checkoutId.city}>{property.city}</LargeText>
        </Col>
        <Col>
          <Label>State</Label>
          <LargeText data-testid={checkoutId.state}>{property.state}</LargeText>
        </Col>
        <Col>
          <Label>ZIP CODE</Label>
          <LargeText data-testid={checkoutId.zipCode}>{property.zip}</LargeText>
        </Col>
      </Row>
    </Col>

    <Col size={[3 / 12, 2 / 12, 2 / 12]} first={[false, true, true]}>
      <Icon icon={'PaperAirplane'} size={['64px', '64px', '80px']} />
    </Col>
  </Row>
);
