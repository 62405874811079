import { Col, Icon, IconButton, Row, TextArea, TextInput, Typography } from '@hippo/components';
import Switch from 'components/Switch/Switch';
import Box from 'components/box/Box';
import React from 'react';
import { ExoticPet } from 'reducers/consent/types';
import { isMobile } from 'react-device-detect';

interface ExoticPetRowProps {
  pet: ExoticPet;
  index: number;
  update: (pet: ExoticPet, index: number) => void;
  onDelete: () => void;
  showDelete?: boolean;
}

export default class ExoticPetRow extends React.Component<ExoticPetRowProps> {
  handleChangeField = (e: HippoComponents.OnChange<{ name: string; value: string } | string | boolean>) => {
    this.props.update({ ...this.props.pet, [e.name]: e.value }, this.props.index);
  };

  handleChangeTextarea =
    (name: string) => (e: HippoComponents.OnChange<{ name: string; value: string } | string | boolean>) =>
      this.handleChangeField({ ...e, name });

  render() {
    return (
      <Box paddingX={3} paddingY={4}>
        <Box flexDirection={['column', 'row', 'row']} alignItems={['left', 'center', 'center']}>
          <Box flex={5 / 7} paddingTop={3} paddingX={2}>
            <TextInput
              focusedLabel='Type of exotic pet'
              unfocusedLabel='Type of exotic pet'
              name={`type`}
              options={{}}
              value={this.props.pet.type}
              onChange={this.handleChangeField}
            />
          </Box>
          <Box flex={2 / 7} paddingX={2} flexDirection={['row', 'column', 'column']} justifyContent={'space-between'}>
            <Typography type={['body4']} paddingY={[3, 0, 1]}>
              Have bite/injury history?
            </Typography>
            <Box flexDirection='row' justifyContent='space-between'>
              <Switch
                colorType={'dark'}
                name={`biteHistory`}
                checked={this.props.pet.biteHistory || false}
                onChange={this.handleChangeField}
                size={'small'}
              />
              {!isMobile ? (
                <Box flex={1}>
                  {this.props.showDelete && <IconButton icon={'Delete'} onClick={this.props.onDelete} />}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {this.props.pet.biteHistory && (
          <Box alignItems='stretch'>
            <TextArea onChange={this.handleChangeTextarea(`comment`)} placeholder={'Please describe'}>
              {this.props.pet.comment}
            </TextArea>
          </Box>
        )}

        {isMobile ? (
          <Box paddingX={2} paddingTop={'15px'}>
            <button onClick={this.props.onDelete}>
              {this.props.showDelete && (
                <Row alignItems={'center'}>
                  <Col size={24 / 300}>
                    <Icon icon={'Delete'} size={'24px'} />
                  </Col>
                  <Col paddingLeft={'5px'}>
                    <Typography type={'allCaps3'} color={'gray600'} textAlign={'left'}>
                      Remove exotic pet
                    </Typography>
                  </Col>
                </Row>
              )}
            </button>
          </Box>
        ) : null}
      </Box>
    );
  }
}
