import { Col, Icon, Row, TriggerTooltip, Typography } from '@hippo/components';
import Box from 'components/box/Box';
import React from 'react';
import styled from 'styled-components';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { DeductibleTypes } from '../../pages/coverages/type';

import PriceBox from '../PriceBox/PriceBox';

export interface IconWithTextsProps {
  iconName: HippoComponents.IconType;
  titles: Array<string>;
  price?: string | undefined;
  justifyContent?: Array<string>;
  contents?: Array<{ title: string; texts: string }>;
  iconAsSeparateCol?: boolean;
  iconSize?: LayoutProps['size'];
  topPaddingBetweenTitleTexts?: SpaceProps['paddingTop'];
  textsPadding?: SpaceProps['paddingLeft'];
  alignItems?: string | Array<string>;
  titleStyle?: HippoComponents.BreakpointProp<HippoComponents.TypographyTypeName>;
  tooltips?: string;
  titleTextsFlexDirection?: FlexboxProps['flexDirection'];
  rightComponent?: JSX.Element;
  priceType: DeductibleTypes;
}

const StyledDiv = styled.div`
  padding-top: 10px;
  padding-left: 15px;
`;

const IconWithTexts: React.FC<IconWithTextsProps> = ({
  iconName,
  titles,
  price,
  justifyContent,
  contents,
  iconAsSeparateCol,
  iconSize = 64,
  topPaddingBetweenTitleTexts,
  textsPadding = '',
  alignItems,
  titleStyle = 'allCaps3',
  tooltips,
  titleTextsFlexDirection = 'row',
  rightComponent,
  priceType,
}) => {
  return (
    <Row
      justifyContent={justifyContent}
      reverse={false}
      flexDirection={['column', 'row', 'row']}
      paddingLeft='0px'
      flexWrap={'nowrap'}
    >
      {iconAsSeparateCol ? (
        <Col alignItems={alignItems} className={'__no_print'}>
          <Icon icon={iconName} size={iconSize} />
        </Col>
      ) : rightComponent ? (
        <Box flexDirection={'row'} justifyContent={'space-between'} className={'__no_print'}>
          <Icon icon={iconName} size={iconSize} />
          {rightComponent}
        </Box>
      ) : (
        <Icon icon={iconName} size={iconSize} className={'__no_print'} />
      )}

      <StyledDiv className={'__coverage_item_and_price'}>
        <Box flexDirection={titleTextsFlexDirection}>
          {titles.map((t, i) => {
            return (
              <Typography type={titleStyle} color={'primary500'} textAlign={'left'} key={i}>
                {t}
              </Typography>
            );
          })}
          {tooltips && (
            <TriggerTooltip placement={'top'} overlay={tooltips}>
              <span>
                <Icon icon={'Info'} width={16} height={16} />
              </span>
            </TriggerTooltip>
          )}
        </Box>

        {price ? (
          priceType === DeductibleTypes.PERCENTAGE ? (
            <Typography color={'gray600'} type={'body2'}>
              {price}%
            </Typography>
          ) : (
            Number.isInteger(+price) && <PriceBox price={price} color={'gray600'} type={'body2'} />
          )
        ) : null}

        {contents &&
          contents.map((content, i) => (
            <Row
              paddingY={topPaddingBetweenTitleTexts}
              paddingLeft={textsPadding}
              flexDirection={'row'}
              paddingX={['0px', '0px', '10px']}
              key={`row-${i}`}
              alignItems={'baseline'}
            >
              <Typography type={'body5'} color={'gray700'} textAlign={['left', 'justify', 'justify']} as='span'>
                <Typography type={'allCaps3'} color={'primary500'} as='span'>
                  {content.title}
                </Typography>
                <span dangerouslySetInnerHTML={{ __html: content.texts }} />
              </Typography>
            </Row>
          ))}
      </StyledDiv>
    </Row>
  );
};

export default IconWithTexts;
