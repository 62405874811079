import React from 'react';
import { Typography } from '@hippo/components';
import { RowWithBorder } from '../pages/coverages/styles';
import { SmartHomeKit } from '../reducers/consent/types';
import { smartHomeId } from './smartHomeId';
import { ProductType } from '../types/common';
import useGetConsent from '../helpers/useGetConsent';
import { PageType } from '../helpers/enums/pageType';
import { OrgNameMapping } from '../helpers/enums/organization';

const getSmartHomeDescription = (
  isLennar: boolean,
  smartHomeKit: SmartHomeKit,
  productType: string | undefined
): string | null => {
  if (isLennar && productType === ProductType.HO5) {
    if (smartHomeKit === SmartHomeKit.Ring) {
      return 'Your policy currently includes a smart home discount since your home is already equipped with Ring devices which can help alert you of potential fire and other events on your property.';
    }
    if (smartHomeKit === SmartHomeKit.Ringflo) {
      return 'Your policy currently includes a smart home discount since your home is already equipped with Ring and Flo devices which can help alert you of potential fire, leaks, and other events on your property.';
    }
  }

  return null;
};

type SmartHomeSectionProps = {
  pageType: PageType;
};
export const SmartHomeSection: React.FC<SmartHomeSectionProps> = ({ pageType }) => {
  const consent = useGetConsent();

  const smartHomeSelectedKit =
    consent.data?.smart_home_automation.smart_home_ordering_info_selected_kit || SmartHomeKit.Kangaroo;
  const productType = consent.data?.quote.product;
  const isSmartHomeQuoteFeatureTurnedOn = consent.data?.is_smart_home_quote_feature_turned_on || false;
  const isPageStatic = pageType === PageType.Static;
  const isCarrierHippo = consent.data?.quote?.carrier === 'hippo';
  const isLennar = consent.data?.organization_name === OrgNameMapping.Lennar;
  const showSmartHomeSection = isCarrierHippo || isPageStatic;

  const smartHomeDescription = getSmartHomeDescription(isLennar, smartHomeSelectedKit, productType);

  if (isSmartHomeQuoteFeatureTurnedOn && showSmartHomeSection && smartHomeDescription) {
    return (
      <RowWithBorder data-testid={smartHomeId.section}>
        <Typography
          type={['allCaps3', 'allCaps3', 'allCaps3']}
          color={'primary500'}
          textAlign={'left'}
          paddingBottom={'20px'}
          paddingTop={'20px'}
          data-testid={smartHomeId.title}
        >
          Your policy currently includes a smart home discount!
        </Typography>
        <Typography
          type={'body5'}
          textAlign={'left'}
          color={'gray600'}
          paddingBottom={'32px'}
          className={'_print_padding'}
          data-testid={smartHomeId.description}
        >
          {smartHomeDescription}
        </Typography>
      </RowWithBorder>
    );
  }

  return null;
};
