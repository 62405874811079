import React from 'react';
import { Col, Row } from '@hippo/components';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Delimiter, SmallText } from './style';
import { PersonalInfoAndQuote } from './PersonalInfoAndQuote';
import { PropertyInfo } from './PropertyInfo';
import { MailingInfo } from './MailingInfo';
import { LoanInfo } from './LoanInfo';
import { AdditionalComments } from './AdditionalComments';

import { ConsentData } from '../../reducers/consent/types';
import { ButtonCol } from '../../components/page/Page';
import { FormState } from './CheckoutPage';
import { WrappedCheckbox } from '../../components/WrappedCheckbox';
import { checkoutId } from './checkoutId';
import { AsyncAwareButton } from '../../components/AsyncAwareButton';
import { CheckoutErrorSummary } from './CheckoutErrorSummary';
import { checkoutPageCTAClickHit } from '../../helpers/useFlagshipFlag';
import { useFlagship } from '@flagship.io/react-sdk';
import { useCheckoutPageCTAText } from '../../experiments/hooks/useCheckoutPageCTAText';

type CheckoutFormProps = {
  onSubmit: (values: FormState, formikHelpers: FormikHelpers<FormState>) => void;
  consentData: ConsentData;
  isSaving: boolean;
  isMiniFlow: boolean;
};

const requiredText = 'Required field';
const requiredApproval = 'Approval is required to submit application';
const invalidEmailText = 'Invalid email address';
const invalidZipText = 'Zip code must be 5 digits';
const invalidPhoneText = 'Invalid phone number';

const consentDataSchema = yup.object({
  additional_comments: yup.string().nullable(),
  customer_approval: yup.boolean().required(requiredApproval).oneOf([true], requiredApproval),
  company: yup.string().nullable(),

  contact_email: yup.string().when('has_no_loan', {
    is: false,
    then: yup.string().email(invalidEmailText).nullable(),
    otherwise: yup.string().nullable(),
  }),
  contact_name: yup.string().nullable(),
  contact_phone: yup.string().when('has_no_loan', {
    is: false,
    then: yup.string().nullable().min(10, invalidPhoneText),
    otherwise: yup.string().nullable(),
  }),
  loan_amount: yup.string().nullable(),
  sales_price: yup.number().nullable(),
  phone_number: yup.string().when('has_no_loan', {
    is: false,
    then: yup.string().nullable().min(10, invalidPhoneText).required(requiredText),
    otherwise: yup.string().nullable(),
  }),

  mailing_address_city: yup.string().nullable(),
  mailing_address_state: yup.string().nullable(),
  mailing_address_street: yup.string().nullable(),
  mailing_address_street2: yup.string().nullable(),
  mailing_address_zip: yup.number().nullable().min(10000, invalidZipText).max(99999, invalidZipText),
});

export const scrollToInvalidField = (errors: FormikErrors<FormState>) => (fieldName?: string) => {
  const firstError = fieldName ?? Object.keys(errors).at(-1);
  if (!firstError) return;
  const element = document.getElementById(firstError);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ onSubmit, consentData, isSaving, isMiniFlow }) => {
  const checkoutPageCTAText = useCheckoutPageCTAText(true);
  const flagship = useFlagship();

  const {
    checkout: { additional_comments, loan_information, personal_information },
    property_information: propertyInfo,
    customer_approval,
    organization_name,
  } = consentData;

  const initialValues: FormState = {
    additional_comments,

    company: loan_information?.company ?? '',
    contact_email: loan_information?.contact_email ?? '',
    contact_name: loan_information?.contact_name ?? '',
    contact_phone: loan_information?.contact_phone ?? '',
    loan_amount: loan_information?.loan_amount ?? null,
    sales_price: loan_information?.sales_price ?? null,
    loan_number: loan_information?.loan_number ?? null,
    has_no_loan: loan_information?.has_no_loan ?? false,

    phone_number: personal_information.phone_number ?? '',
    mailing_address_city: personal_information.mailing_address_city ?? '',
    mailing_address_state: personal_information.mailing_address_state ?? '',
    mailing_address_street: personal_information.mailing_address_street ?? '',
    mailing_address_street2: personal_information.mailing_address_street2 ?? '',
    mailing_address_zip: personal_information.mailing_address_zip ?? '',

    customer_approval,
    organization_name,
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={consentDataSchema}>
      {({ values, errors }) => (
        <Form>
          <PersonalInfoAndQuote
            firstName={personal_information.first_name}
            lastName={personal_information.last_name}
            email={personal_information.email}
          />
          {propertyInfo && <PropertyInfo property={propertyInfo} />}
          <Delimiter />
          <MailingInfo />
          <Delimiter />
          {!isMiniFlow && (
            <>
              <LoanInfo noLoanInformation={values.has_no_loan} />
              <Delimiter />
              <AdditionalComments />
              <Delimiter />
            </>
          )}
          <Row alignItems='center' paddingBottom='8px'>
            <Col size={8 / 12} offset={2 / 12}>
              <SmallText as='p'>
                Any person who knowingly and with intent to defraud any insurance company or another person files an
                application for insurance or statement of claim containing any materially false information, or conceals
                for the purpose of misleading information concerning any fact material thereof, commits a fraudulent
                insurance act, which is a crime and subjects the person to criminal and civil penalties.
              </SmallText>
            </Col>
          </Row>
          <Row alignItems='center'>
            <Col size={10 / 12} offset={1 / 12} alignItems='flex-end' paddingLeft='30px'>
              <Field
                name='customer_approval'
                size={24}
                component={WrappedCheckbox}
                label={
                  <Col marginLeft='20px' size={10 / 12} data-testid={checkoutId.customerApprovalCheckbox}>
                    <SmallText as='p'>
                      Applicants Statement: I have read the above. I declare that the information provided is true,
                      complete, and correct to the best of my knowledge and belief. This information is being offered to
                      the company as an inducement to issue the policy for which I am applying.
                    </SmallText>
                  </Col>
                }
                hideErrorMessage={true}
              />
            </Col>
          </Row>
          {Object.keys(errors).length > 0 && (
            <Row>
              <Col offset={2 / 12} marginLeft='50px' mt='20px' size={10 / 12}>
                <CheckoutErrorSummary errors={errors} />
              </Col>
            </Row>
          )}
          <Row>
            <ButtonCol size={[1, 6 / 12, 5 / 12]} offset={[0, 3 / 12, 3.5 / 12]}>
              <AsyncAwareButton
                data-testid={checkoutId.submitButton}
                size='large'
                styleType='primary'
                showLoader={isSaving}
                onClick={() => {
                  scrollToInvalidField(errors)();
                  flagship.hit.send(checkoutPageCTAClickHit);
                }}
                type='submit'
              >
                {checkoutPageCTAText}
              </AsyncAwareButton>
            </ButtonCol>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
