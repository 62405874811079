import * as React from 'react';
import { Col, Grid, Icon, Row, Typography } from '@hippo/components';
import { useSelector } from 'react-redux';

import PageContainer from '../../components/PageContainer/PageContainer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { State } from '../../reducers/createRootReducer';

import { Dictionary } from 'types';

type PageContent = {
  errorCode: number;
  title: string;
  texts: string;
};

const missingPageContent: Dictionary<PageContent> = {
  '/404': {
    errorCode: 404,
    title: 'So sorry for the inconvenience',
    texts: 'please choose the correct route <br/> or call us',
  },
  '/500': {
    errorCode: 500,
    title: 'So sorry for the inconvenience',
    texts:
      'Hippo’s site is down for a little fixer upper. <br/> We anticipate we’ll be fixed up very soon. <br/> Thanks for your patience.',
  },
};

const Missing: React.FC = () => {
  const { pathname } = useSelector((state: State) => ({
    pathname: state.router.location.pathname,
  }));

  const pageContent = missingPageContent[pathname];

  return (
    <PageContainer>
      <Grid flex={1}>
        <Row paddingBottom={'62px'} paddingTop={'25px'}>
          <Col size={[4 / 12, 3 / 12, 2 / 12]}>
            <Icon icon='HippotextGreen' />
          </Col>
          <Col size={[2 / 12, 1 / 12, 1 / 12]} offset={[6 / 12, 8 / 12, 9 / 12]}>
            <Icon icon='LogoGreen' />
          </Col>
        </Row>
        <Row>
          <Col alignItems='center' justifyContent='center' size={[1, 10 / 12, 8 / 12]} offset={[0, 1 / 12, 2 / 12]}>
            <Icon icon='HouseWithBandaid' />
            <PageTitle>{pageContent?.title}</PageTitle>
            <Typography type={['body4', 'body2', 'body2']} textAlign='center' marginX={3} color={'gray700'}>
              <div dangerouslySetInnerHTML={{ __html: pageContent?.texts }} />
            </Typography>
          </Col>
        </Row>
      </Grid>
    </PageContainer>
  );
};

export default Missing;
