import React from 'react';
import { Typography } from '@hippo/components';

import { useConsentData } from '../../helpers/use-consent-data';
import { formatPhoneNumber } from '../../utils/data-transform';
import { Flex } from '../Flex';

type HeaderContactInfoProps = {
  phone: string;
  referenceId?: string | null;
};
export const HeaderContactInfo: React.FC<HeaderContactInfoProps> = ({ phone, referenceId }) => (
  <Flex flexDirection='column' justifyContent='center'>
    <Typography type={'body5'} textAlign='right'>
      Connect with your Agent
    </Typography>
    <Typography type={'body5'} textAlign='right'>
      {referenceId && <>Reference Id: {referenceId}</>}
      {referenceId && phone && <span> &#8231; </span>}
      <a href={`tel:${phone}`} style={{ textDecoration: 'none' }}>
        {formatPhoneNumber(phone)}
      </a>
    </Typography>
  </Flex>
);

export const HeaderContactInfoContainer = () => {
  const consentData = useConsentData();

  return (
    <HeaderContactInfo
      referenceId={consentData && consentData.reference_id}
      phone={consentData?.agent?.agent_phone || ''}
    />
  );
};
