import { identify } from 'react-fullstory';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { FlowType } from '../types/getConsent';
import { getConsent } from '../utils/api';
import useHmac from './use-hmac';
import useQuoteId from './use-quote-id';

export default () => {
  const quoteId = useQuoteId() || '';
  const { pathname } = useLocation();
  const hmac = useHmac() || '';

  let flowType = FlowType.consent;
  if (pathname.indexOf('/static') === 0) {
    flowType = FlowType.static;
  } else if (pathname.indexOf('/confirm') === 0) {
    flowType = FlowType.confirm;
  }

  return useQuery(
    ['Consent', quoteId + flowType + hmac],
    async () => {
      const { status, data } = await getConsent(quoteId, flowType, hmac);

      if (status === 200) {
        identify(quoteId, {
          flow_type: flowType,
          reference_id: data.reference_id,
          project: 'hbo-consent-flow',
        });

        const heapId = window.appConfig && window.appConfig.HEAP_ID;
        if (heapId) {
          window.heap.identify(quoteId);
        }
      }

      return data;
    },
    {
      enabled: Boolean(quoteId && flowType && hmac),
    }
  );
};
