import React from 'react';

import Button from './button/Button';
import Box from './box/Box';
import { Spinner } from './Spinner';
import { asyncAwareButtonId } from './asyncAwareButtonId';

type AsyncAwareButtonProps = {
  showLoader?: boolean;
  'data-testid'?: string;
} & HippoComponents.ButtonProps;

const spinnerSize = (buttonSize: HippoComponents.BreakpointProp<HippoComponents.ButtonSize>) => {
  switch (buttonSize) {
    case 'xsmall':
      return '12px';
    case 'small':
      return '12px';
    case 'medium':
      return '14px';
    case 'large':
      return '20px';
    default:
      return '12px';
  }
};

export const AsyncAwareButton: React.FC<AsyncAwareButtonProps> = ({ showLoader = false, children, ...props }) => (
  <Button disabled={showLoader} {...props} data-testid={props['data-testid'] ?? asyncAwareButtonId.submit}>
    {showLoader ? (
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
        <Box
          width={spinnerSize(props.size)}
          height={spinnerSize(props.size)}
          marginRight='5px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          marginBottom='5px'
          flexDirection='row'
        >
          <Spinner isFluid={true} borderWidth='2px' borderColor={props.styleType === 'primary' ? 'white' : 'gray100'} />
        </Box>
        {children}
      </Box>
    ) : (
      children
    )}
  </Button>
);
