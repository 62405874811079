import { Button, Modal, Typography } from '@hippo/components';
import { closeModal } from '../actions/modals';
import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '../components/box/Box';
import styled from 'styled-components';

import { ModalProps } from '../types/modals';

const StyledUl = styled.ul`
  list-style: disc;
  padding-left: 40px;
`;

const SmartHomeConsentModal: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  const handlePrimaryClick = () => {
    if (props.onPrimaryButtonClick) {
      props.onPrimaryButtonClick();
    }
    dispatch(closeModal());
  };

  const handleSecondaryClick = () => {
    if (props.onSecondaryButtonClick) {
      props.onSecondaryButtonClick();
    }
    dispatch(closeModal());
  };

  return (
    <Modal
      {...props}
      title={'Congratulations on making your home smarter and safer!'}
      size={'large'}
      disableBodyPadding={false}
      icon={'SmartHome'}
    >
      <div>
        <Typography type={'body5'} marginBottom={15}>
          Please confirm these details in order to secure your Smart Home Discount.
        </Typography>
        <Typography type={'body5'} marginBottom={15}>
          <b>You must install and activate</b> the complimentary sensor kit (that Hippo will ship to you){' '}
          <b>within 55 days</b> of your policy effective date or your premium will increase, and you will have to repay
          any discount amount already earned.
        </Typography>
        <Typography type={'body5'} marginBottom={30}>
          To be eligible, you must meet the following requirements:
          <StyledUl>
            <li>Have wifi enabled in your home</li>
            <li>Have a smartphone with iOS 10 (or higher) or Android 6 (or higher)</li>
          </StyledUl>
        </Typography>
        <Box flexDirection={'row'}>
          <Box>
            <Button size={'small'} styleType='secondary' onClick={handleSecondaryClick}>
              Enroll without discount
            </Button>
          </Box>
          <Box marginLeft={20}>
            <Button size={'small'} styleType='primary' onClick={handlePrimaryClick}>
              I Confirm
            </Button>
          </Box>
        </Box>
        <Typography type={'body6'} marginTop={30}>
          Your privacy is important to us. Hippo only monitors activation and device status, not detailed information
          about your home. The device is truly complimentary — there are no hidden or monthly fees.
        </Typography>
      </div>
    </Modal>
  );
};
export default SmartHomeConsentModal;
